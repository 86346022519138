import { Controller, Form, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { memberModifier } from '@gonfalon/members';
import { getQueryClient } from '@gonfalon/react-query-client';
import { getTeamQuery, isRESTAPIError, JSONPatch, usePatchMember, usePatchTeam } from '@gonfalon/rest-api';
import { makeCustomRoleInstructions, makeRoleAttributeInstructions, teamModifier } from '@gonfalon/teams';
import {
  Button,
  ButtonGroup,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  ProgressBar,
  Select,
  SelectValue,
  SnackbarQueue,
  Tag,
  ToastQueue,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import { getRoleAttributesFromPolicy } from '../../getRoleAttributesFromPolicy';
import { CustomRole } from '../../internal/types';
import { RoleSelect } from '../../RoleSelect';
import { TagGroupInput } from '../../TagGroupInput';
import { AssignAccessModalProps } from '../AssignAccessModal';

import { AssignAccessFormValues } from './schema';

import styles from './AssignAccessForm.module.css';

export type AssignAccessFormProps = Omit<AssignAccessModalProps, 'externallyControlledModalState'> & {
  closeModal: () => void;
};

export const AssignAccessForm = ({
  role: selectedRole,
  forResource,
  member,
  team,
  createJsonPatch,
  closeModal,
}: AssignAccessFormProps) => {
  const isForMember = forResource === 'member';
  const isForTeam = forResource === 'team';
  const { mutate: updateMember, isPending: isUpdatingMember } = usePatchMember();
  const { mutate: updateTeam, isPending: isUpdatingTeam } = usePatchTeam();

  const isUpdating = isUpdatingMember || isUpdatingTeam;

  const getDefaultResources = (attributeKey: string) => {
    if (isForTeam && team) {
      return team.roleAttributes?.[attributeKey] || [];
    }

    if (member) {
      return member.roleAttributes?.[attributeKey] || [];
    }

    return [];
  };

  const getAttributes = (r: CustomRole) =>
    getRoleAttributesFromPolicy(r.policy).map(({ attribute, type }) => ({
      attribute,
      type,
      resources: getDefaultResources(attribute),
    }));

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm<AssignAccessFormValues>({
    defaultValues: {
      attributes: selectedRole ? getAttributes(selectedRole) : [],
      role: selectedRole,
      roleKind: selectedRole ? 'custom' : (member?.role as AssignAccessFormValues['roleKind']) || 'reader',
    },
  });

  const roleKind = watch('roleKind');
  const role = watch('role');
  const roleAttributes = watch('attributes');
  const canShowRoleKindSelection = isForMember;
  const canShowRoleSelection = (isForMember && roleKind === 'custom') || isForTeam;

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  const onSubmit: SubmitHandler<AssignAccessFormValues> = (data) => {
    const getRoleAttrs = () =>
      data.attributes.reduce(
        (acc, { attribute, resources }) => {
          // eslint-disable-next-line no-param-reassign
          acc[attribute] = resources;
          return acc;
        },
        {} as Record<string, string[]>,
      );

    const onError = (err: Error) => {
      SnackbarQueue.error({
        description: isRESTAPIError(err) ? err?.message : 'Failed to update access. Try again later.',
      });
    };

    if (isForTeam && team && team.key) {
      const teamKey = team.key;
      const updatedTeam = teamModifier(team)
        .addCustomRoles([{ key: role.key, name: role.name }])
        .replaceRoleAttributes(getRoleAttrs());

      const roleAttributesInstructions = makeRoleAttributeInstructions(team, updatedTeam);
      const customRolesInstructions = makeCustomRoleInstructions(team, updatedTeam);

      updateTeam(
        {
          body: {
            instructions: [...roleAttributesInstructions, ...customRolesInstructions],
          },
          teamKey,
        },
        {
          onSuccess: async () => {
            ToastQueue.success('Access updated');

            const queryClient = getQueryClient();
            await queryClient.invalidateQueries(getTeamQuery({ teamKey, query: { expand: ['roleAttributes'] } }));

            closeModal();
          },
          onError,
        },
      );
      return;
    }

    if (member) {
      const updatedMember =
        roleKind === 'custom'
          ? memberModifier(member).addCustomRoles([role.key]).replaceRoleAttributes(getRoleAttrs())
          : memberModifier(member).setRole(roleKind);

      const patch = createJsonPatch(member, updatedMember) as JSONPatch;
      updateMember(
        { body: patch, id: member._id },
        {
          onSuccess: () => {
            ToastQueue.success('Access updated');
            closeModal();
          },
          onError,
        },
      );
      return;
    }
  };

  // using a makeshift table for now, because the RAC table focus handling prevents
  // us from using input's within the table.
  // https://github.com/adobe/react-spectrum/issues/2328
  return (
    <Form
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        // Prevent focus change to the submit button when pressing Enter
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      {canShowRoleKindSelection && (
        <Controller
          control={control}
          name="roleKind"
          render={({ field: { onChange, value } }) => (
            <Select selectedKey={value} onSelectionChange={onChange} className={styles.roleKindSelect}>
              <Label>Select a role</Label>
              <Button>
                <SelectValue />
                <Icon name="chevron-down" size="small" />
              </Button>
              <Popover>
                <ListBox
                  aria-label="Role kind"
                  selectionMode="single"
                  items={[
                    { name: 'Reader', id: 'reader' },
                    { name: 'Writer', id: 'writer' },
                    { name: 'Admin', id: 'admin' },
                    { name: 'No access', id: 'no_access' },
                    { name: 'Custom', id: 'custom' },
                  ]}
                >
                  {(item) => <ListBoxItem>{item.name}</ListBoxItem>}
                </ListBox>
              </Popover>
            </Select>
          )}
        />
      )}

      {canShowRoleSelection && (
        <>
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value } }) => (
              <RoleSelect
                onSelectionChange={(r) => {
                  onChange(r);
                  if (r) {
                    setValue('attributes', getAttributes(r));
                  }
                }}
                role={value}
              />
            )}
          />
          {roleAttributes.length > 0 && (
            <>
              <p className={styles.formDescription}>
                Select the specific resource(s) that will apply in place of the defined role attributes:
              </p>

              <div className={styles.tableContainer} role="table" aria-label="Role attributes and resources">
                <div className={styles.tableHeader} role="rowgroup">
                  <div className={styles.tableRow} role="row">
                    <div className={styles.headerCell} role="columnheader">
                      Role attribute
                    </div>
                    <div className={styles.headerCell} role="columnheader">
                      Resources
                    </div>
                  </div>
                </div>
                <div className={styles.tableBody} role="rowgroup">
                  {attributeFields.map(({ attribute }, index) => (
                    <div key={index} className={styles.tableRow} role="row">
                      <div className={styles.cell} role="rowheader">
                        <span className={styles.attributeName}>{attribute}</span>
                      </div>
                      <div className={styles.cell} role="cell">
                        <Controller
                          control={control}
                          name={`attributes.${index}.resources`}
                          render={({ field: { onChange, value } }) => (
                            <TagGroupInput
                              ariaLabel={`Enter resources for ${attribute}`}
                              tag={(item) => <Tag textValue={item.textValue}>{item.textValue}</Tag>}
                              placeholder="Type a resource key, pressing 'Enter' after each key"
                              selectedItems={value.map((v) => ({ id: v, textValue: v }))}
                              onItemsChange={(v) => {
                                onChange(v.map((i) => i.id));
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}

      <ButtonGroup className={styles.buttonGroup}>
        <Button variant="default" onPress={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" isDisabled={!isDirty || isUpdating}>
          {isUpdating ? <ProgressBar isIndeterminate size="small" /> : 'Assign access'}
        </Button>
      </ButtonGroup>
    </Form>
  );
};
