import { getEventActivity, getMetricEventInstances, getMetricEventsByProject } from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getMetricEventInstancesQuery = createQueryOptions(getMetricEventInstances);

export const getMetricEventsQuery = createQueryOptions(getMetricEventsByProject);

export const getMetricEventsByEventKeyQuery = createQueryOptions(getEventActivity);

export const getEventActivityQuery = createQueryOptions(getEventActivity);
