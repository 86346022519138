import { lazy, MouseEvent, Suspense } from 'react';
import { Outlet } from 'react-router';
import { releaseGuardian } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import {
  toFlagCodeReferences,
  toFlagExperiments,
  toFlagHistory,
  toFlagLinks,
  toFlagMonitoring,
  toFlagSettings,
  toFlagTargeting,
  toFlagVariations,
  toFlagWorkflows,
  toHref,
} from '@gonfalon/navigator';
import { LinkIconButton, ProgressBar } from '@launchpad-ui/components';
import { LegacyTitle } from 'helmet';
import { Navigation, NavigationItem, NavigationState } from 'launchpad';

import AppHeader from 'components/AppHeader';
import { ArchiveBanner } from 'components/archive/ArchiveBanner';
import { FlagBreadcrumbs } from 'components/FlagBreadcrumbs';
import { PageNav } from 'components/PageNav';
import { LiveAvatars } from 'components/presence';
import { AlignValue, Cell, CellLayoutValue, Grid } from 'components/ui/grid';
import { Member as TMember } from 'utils/accountUtils';
import { EventLocation, trackExperimentEvent, trackNavigationEvent } from 'utils/analyticsUtils';
import { Flag } from 'utils/flagUtils';
import {
  ENABLE_TOGGLE_RUNNER_FLAG_KEY,
  IN_APP_DEMO_LINK,
  IN_APP_DEMO_PROJECT_KEY,
  TOGGLE_RUNNER_MODE_FLAG_KEY,
} from 'utils/inAppDemoUtils';

import { DeprecatedFlagBanner } from './appBanner/DeprecatedFlagBanner';
import { LegacyManageFlagMeasuredRolloutMetricsButton } from './LegacyManageFlagMeasuredRolloutMetricsButton';

import 'stylesheets/components/ManageFlag.css';
import styles from './ManageFlag.module.css';

const FlagOverview = lazy(
  async () => import(/* webpackChunkName: "FlagOverview" */ 'components/FlagOverview/FlagOverview'),
);

export type ManageFlagProps = {
  envId: string;
  envKey: string;
  flag: Flag;
  flagKey: string;
  isABTestingEnabled: boolean;
  isCodeReferencesEnabled: boolean;
  isAuditLogEnabled: boolean;
  isFlagLinksEnabled: boolean;
  isInAppDemoEnabled: boolean;
  isPresenceEnabled: boolean;
  isWorkflowsTabEnabled: boolean;
  profile: TMember;
  projKey: string;
};

type ManageFlagTabs =
  | 'Targeting'
  | 'Workflows'
  | 'Insights'
  | 'Experiments'
  | 'Variations'
  | 'History'
  | 'Code References'
  | 'Links'
  | 'Settings';

/* eslint-disable import/no-default-export */
export default function ManageFlag({
  envId,
  envKey,
  flag,
  flagKey,
  isABTestingEnabled,
  isCodeReferencesEnabled,
  isAuditLogEnabled: isAuditLogEnabled,
  isFlagLinksEnabled,
  isInAppDemoEnabled,
  isPresenceEnabled,
  isWorkflowsTabEnabled,
  profile,
  projKey,
}: ManageFlagProps) {
  const checkFlagAccess = flag?.checkAccess({ envKey, profile });
  const canProfileUpdateFlagMaintainer = checkFlagAccess('updateMaintainer').isAllowed;

  const handleExperimentsTabClick = (_e: MouseEvent, state: NavigationState) => {
    trackExperimentEvent('Experiments tab viewed', {
      location: EventLocation.FLAG_EXPERIMENTS,
      tab: 'Experiments',
      'parent page': 'feature flag',
      component: 'Tab',
      view: state?.collapsed ? 'collapsed' : 'expanded',
    });
  };

  const handleNavigationTabClick = (tab: ManageFlagTabs) => (_e: MouseEvent, state: NavigationState) => {
    trackNavigationEvent(`Manage Flag ${tab} Tab Clicked`, {
      tab,
      'parent page': 'feature flag',
      component: 'Tab',
      view: state?.collapsed ? 'collapsed' : 'expanded',
    });
  };

  const hideTabsIfMigrationFlag = (item: { name: string }) => {
    if (flag.isMigrationFlag() && ['Workflows', 'Experiments'].includes(item.name)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {flag.archived && (
        <ArchiveBanner
          archivedDate={
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            flag.archivedDate! /* eslint-enable @typescript-eslint/no-non-null-assertion */
          }
          flagLink={`${flag.siteLink(envKey)}/settings/archive`}
        />
      )}
      {flag?.deprecated && <DeprecatedFlagBanner flag={flag} checkAccess={checkFlagAccess} />}
      <LegacyTitle title={flag.name} />
      <AppHeader
        breadcrumbs={<FlagBreadcrumbs flagName={flag.name} />}
        presence={isPresenceEnabled ? <LiveAvatars roomId={flag.key} /> : undefined}
      >
        <Grid align={AlignValue.BASELINE}>
          <Cell>
            <div className="FlagTitle">
              <Suspense fallback={null}>
                <FlagOverview
                  envKey={envKey}
                  flag={flag}
                  canProfileUpdateFlagMaintainer={canProfileUpdateFlagMaintainer}
                />
              </Suspense>
            </div>
          </Cell>
          <Cell layout={CellLayoutValue.FIVE_OF_TWELVE} className={styles.flagHeaderControls}>
            {releaseGuardian() && (
              <LegacyManageFlagMeasuredRolloutMetricsButton projectKey={projKey} flagKey={flagKey} />
            )}
          </Cell>
        </Grid>
        {/* This is only for flags that we create to interact with the demo application. */}
        {isInAppDemoEnabled &&
          projKey === IN_APP_DEMO_PROJECT_KEY &&
          (flagKey === ENABLE_TOGGLE_RUNNER_FLAG_KEY || flagKey === TOGGLE_RUNNER_MODE_FLAG_KEY) && (
            <div className="ManageFlag-DemoFlag--alert">
              <LinkIconButton
                href={`${IN_APP_DEMO_LINK}?client_side_id=${envId}`}
                aria-label="Demo flag alert"
                icon="link-external"
                className="DemoFlagIcon"
              />
              <span className="u-ml-s">
                This is flag {flagKey === ENABLE_TOGGLE_RUNNER_FLAG_KEY && 1}
                {flagKey === TOGGLE_RUNNER_MODE_FLAG_KEY && '2'} used for our Toggle Runner demo application.{' '}
                <a href={`${IN_APP_DEMO_LINK}?client_side_id=${envId}`}>Open the demo to see changes in real-time.</a>
              </span>
            </div>
          )}
      </AppHeader>
      <PageNav>
        <Navigation
          title="Flag menu"
          items={[
            {
              key: 'targeting',
              name: 'Targeting',
              to: toFlagTargeting({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
              onClick: handleNavigationTabClick('Targeting'),
            },

            ...(isWorkflowsTabEnabled
              ? [
                  {
                    key: 'workflows',
                    name: 'Workflows',
                    to: toFlagWorkflows({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
                    onClick: handleNavigationTabClick('Workflows'),
                  },
                ]
              : []),

            {
              key: 'insights',
              name: 'Insights',
              to: toFlagMonitoring({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
              onClick: handleNavigationTabClick('Insights'),
            },

            isABTestingEnabled
              ? {
                  key: 'experiments',
                  name: 'Experiments',
                  to: toFlagExperiments({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
                  onClick: handleExperimentsTabClick,
                }
              : {
                  key: 'experiments',
                  name: 'Experiments',
                  to: toFlagExperiments({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
                  tooltip: true,
                },

            {
              key: 'variations',
              name: 'Variations',
              to: toFlagVariations({ projectKey: projKey, flagKey: flag.key }),
              onClick: handleNavigationTabClick('Variations'),
            },

            ...(isAuditLogEnabled
              ? [
                  {
                    key: 'history',
                    name: 'History',
                    to: toFlagHistory({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
                    onClick: handleNavigationTabClick('History'),
                  },
                ]
              : []),

            {
              key: 'coderefs',
              name: 'Code references',
              to: toFlagCodeReferences({ projectKey: projKey, flagKey: flag.key }),
              onClick: handleNavigationTabClick('Code References'),
              tooltip: isCodeReferencesEnabled ? undefined : true,
            },

            ...(isFlagLinksEnabled
              ? [
                  {
                    key: 'links',
                    name: 'Links',
                    to: toFlagLinks({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
                    onClick: handleNavigationTabClick('Links'),
                  },
                ]
              : []),

            {
              key: 'settings',
              name: 'Settings',
              to: toFlagSettings({ projectKey: projKey, flagKey: flag.key, environmentKey: envKey }),
              onClick: handleNavigationTabClick('Settings'),
            },
          ].filter(hideTabsIfMigrationFlag)}
        >
          {(item) => (
            <NavigationItem
              key={toHref(item.to)}
              name={item.name}
              to={toHref(item.to)}
              tooltip={item.tooltip}
              onClick={item.onClick}
            />
          )}
        </Navigation>
      </PageNav>

      <Suspense fallback={<ProgressBar aria-label="Loading…" isIndeterminate />}>
        <Outlet />
      </Suspense>
    </>
  );
}
