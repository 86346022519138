/* eslint-disable sort-exports/sort-exports */
import { createFlagFunction } from './createFlagFunction';

export const isHoldoutArchiveEnabled = createFlagFunction('experiment-holdouts-allow-archive', false);

export const acceptedRedirectHosts = createFlagFunction<string[]>('accepted-redirect-hosts', []);
export const aiFlagGenerationInExperiments = createFlagFunction('ai-flag-generation-in-experiments', false);
export const aiVariationGeneration = createFlagFunction('ai-variation-generation', false);
export const amberfloGraphStartDate = createFlagFunction('amberflo-graph-start-date', 1672560000000);
export const amberfloMeterDatePickerType = createFlagFunction<{ [key: string]: string }>(
  'amberflo-meter-date-picker-type',
  {},
);
export const amberfloServerConnectionMinutesMeterAPIName = createFlagFunction(
  'amberflo-server-connection-minutes-meter-api-name',
  'ServerConnectionMinutes',
);
export const approvalsNotificationListMaxLimit = createFlagFunction('approvals-notification-list-max-limit', 10);
export const areFollowFlagTargetingChangesEnabled = createFlagFunction('enable-follow-flag-targeting-changes', false);
export const areMetricAnalyticsTrendsEnabled = createFlagFunction('enable-metric-analytics-trends', false);
export const arePayloadFiltersAtFlagCreationEnabled = createFlagFunction(
  'show-payload-filters-at-flag-creation',
  false,
);
export const enableFlagEvaluationSandbox = createFlagFunction('flag-evaluation-sandbox', false);
export const enableFlagEvaluationUpdates = createFlagFunction('enable-flag-evaluation-ui-updates', false);
export const enableShortcutCounters = createFlagFunction('enable-shortcut-counters', false);
export const experimentValueThreshold = createFlagFunction('experiment-results-value-render-threshold', -1);
export const areProductAnalyticsEnabled = createFlagFunction('enable-product-analytics', false);
export const areRelayAutoConfigsEnabled = createFlagFunction('enable-relay-auto-configs', false);
export const areServiceTokensEnabled = createFlagFunction('enable-service-tokens', false);
export const areSignupsDisallowed = createFlagFunction('disallow-signups', false);
export const areWorkflowTemplatesEnabled = createFlagFunction('enable-workflow-templates', false);
export const auditLogAccountHistoryLimit = createFlagFunction('audit-log-account-history-limit', -1);
export const auditLogHistoryLimit = createFlagFunction('enable-audit-log-history-length', -1);
export const beastModeOptions = createFlagFunction<{ all: boolean; routes: string[] }>('beast-mode', {
  all: false,
  routes: [],
});
export const beastModeProfilePageRevamp = createFlagFunction('beast-mode-profile-page-revamp', false);
export const bigSegmentsImportMaxRecords = createFlagFunction('big-segments-import-max-records', 0);
export const chatIntegrationPromoCards = createFlagFunction('chat-integration-promo-cards', 'double');
export const checkAccessContextUsageError = createFlagFunction('check-access-context-usage-error', false);
export const clientMauUsageCutoverDate = createFlagFunction('client-mau-usage-cutover-date', 0);
export const contextAttributeNamesLimit = createFlagFunction('context-attribute-names-limit', 100);
export const contextAttributeValuesLimit = createFlagFunction('context-attribute-values-limit', 100);
export const commandBarReleasesEnvironment = createFlagFunction('command-bar-releases-environment', 'production');
export const configurableDestinations = createFlagFunction<string[]>('configurable-destinations', []);
export const isDataExportEnabled = () => configurableDestinations().length > 0;
export const configureBranchFilter = createFlagFunction('configure-coderefs-branch-ux-filter', 0);
export const contactUsSeatLimit = createFlagFunction('contact-us-seat-limit', -1);
export const contextKindValidation = createFlagFunction('context-kind-validation', {
  maxDescriptionLength: 1024,
  maxKeyLength: 256,
  maxNameLength: 256,
  maxPerProject: 100,
});
export const customerUsage = createFlagFunction('customer-usage', {
  enableCustomerUsage: false,
  enableServerMAUChart: false,
  enableClientTab: false,
  enableClientMAUChart: false,
  enableServerTab: false,
  enableEventsTab: false,
  enableUsageTabs: false,
  enableExperimentsTab: false,
  enableDataExportTab: false,
  enableServerConnectionsChart: false,
  enableClientConnectionsChart: false,
});
export const datadogRUMPremiumSampleRate = createFlagFunction(
  'web-app-data-dog-rum-application-premium-sample-rate',
  0,
);
export const datadogRUMSampleRate = createFlagFunction('web-app-data-dog-rum-application-sample-rate', 0);
export const debuggerFullFidelityEventDurationMinutes = createFlagFunction(
  'debugger-full-fidelity-events-duration-in-minutes',
  30,
);
export const debuggerHeartbeatIntervalMillis = createFlagFunction('debugger-heartbeat-interval-millis', 5000);
export const debuggerInactivityModalDurationMillis = createFlagFunction(
  'debugger-inactivity-modal-duration-in-milliseconds',
  900000,
);
export const disablecMAUChartAndDisplayMessage = createFlagFunction('disable-cmau-chart-and-display-message', '');
export const disableBuiltInContextNameAttributes = createFlagFunction(
  'disable-built-in-context-name-attributes',
  false,
);
export const disallowNewAttributeValues = createFlagFunction('disallow-new-attribute-values', false);
export const displaySupportGenerativeAiOptInUi = createFlagFunction('display-support-generative-ai-opt-in-ui', false);
export const enableAccountHasConnectedSdk = createFlagFunction('enable-account-has-connected-sdk', false);
export const enableAddTargetingFromSegmentOverview = createFlagFunction('add-targeting-from-segment-overview', false);
export const enableAdoptionChartForAppVersions = createFlagFunction('enable-adoption-chart-for-app-versions', false);
export const enableAmberfloOveragesUI = createFlagFunction('enable-amberflo-overages-ui', false);

export const enableAmberfloUI = createFlagFunction('enable-amberflo-ui', false);
export const enableAmberfloUiHostsMeterChart = createFlagFunction('enable-amberflo-ui-hosts-meter-chart', false);
export const enableAmberfloUiHostsProgressBar = createFlagFunction(
  'enable-amberflo-ui-hosts-meter-progress-bar',
  false,
);
export const enableAmplitudeSegmentSync = createFlagFunction('enable-amplitude-cohort-sync', false);
export const enableAppsAndAppVersions = createFlagFunction('enable-apps-and-versions', false);
export const enableArchivingFlagThatIsPrerequisite = createFlagFunction(
  'enable-archiving-flag-that-is-prerequisite',
  false,
);
export const enableContextKindsClientSideSearch = createFlagFunction('enable-context-kinds-client-side-search', false);
export const enableAutoSeatProvisioning = createFlagFunction('enable-auto-seat-provisioning', false);
export const enableBigSegmentContexts = createFlagFunction('enable-context-kind-support-for-big-segments', false);
export const enableBizibleScript = createFlagFunction('enable-bizible-script', false);
export const enableCanaryToken = createFlagFunction('enable-canary-token', false);
export const enableCcmGraphsUsingAmberflo = createFlagFunction('enable-ccm-graphs-using-amberflo', false);
export const enableChangeHistoryDetails = createFlagFunction('enable-change-history-details', false);
export const enableClientSideNavigationForSidenav = createFlagFunction(
  'enable-client-side-navigation-from-the-sidebar',
  false,
);
export const enableConfigurableExperimentationForProPlans = createFlagFunction(
  'enable-configurable-experimentation-for-pro-plans',
  false,
);
export const enableConfigureMeasuredRolloutOnRule = createFlagFunction('configure-measured-rollout-on-rule', false);
export const enableContextAttributesTypeahead = createFlagFunction('context-attributes-typeahead', false);
export const enableCraftExperimentResultsImprovements = createFlagFunction(
  'enable-craft-experiment-results-improvements',
  false,
);
export const enableCreatingAppsAndVersions = createFlagFunction('enable-create-apps-and-versions', false);
export const enableCreatingFlagInExperimentBuilder = createFlagFunction('experiment-builder-create-flag-modal', false);
export const enableCriticalAuditLogActionsFilter = createFlagFunction<string[]>('critical-audit-log-entries', []);
export const enableCustomRolesAsSamlDefaultRole = createFlagFunction<boolean>(
  'custom-roles-as-saml-default-role',
  false,
);
export const enableDataDogRumInteractions = createFlagFunction('enable-web-app-data-dog-rum-interactions', false);
export const enableDataDogRumResourceTracking = createFlagFunction(
  'enable-web-app-data-dog-rum-resource-tracking',
  false,
);
export const enableManuallyTrackedDdrumViews = createFlagFunction('enable-manually-tracked-ddrum-views', false);
export const enableDataExportEventsUsingAmberflo = createFlagFunction(
  'enable-data-export-events-using-amberflo',
  false,
);
export const enableDeveloperPlanCheckout = createFlagFunction('enable-developer-plan-checkout', false);
export const enabledMetricAnalysisConfigFields = createFlagFunction<
  Array<'unitOfAnalysis' | 'unitAggregationType' | 'analysisType' | 'eventDefault'>
>('enabled-metric-analysis-config-fields', []);
export const enableDomainMatching = createFlagFunction('enable-domain-matching', false);
export const enableDomainVerificationForSSOAccounts = createFlagFunction(
  'enable-domain-verification-for-sso-accounts',
  false,
);

export const enableAIConfigWaitlistPage = createFlagFunction('ai-config-waitlist', false);
export const isAIConfigBedrockImportEnabled = createFlagFunction('enable-ai-config-bedrock-import', false);
export const isAIModelConfigurationEnabled = createFlagFunction('enable-byomc-in-ai-configs', false);
export const isAIConfigVersioningEnabled = createFlagFunction('enable-ai-config-versioning', false);

export const enableEncryptedSAMLAssertions = createFlagFunction('enable-encrypted-saml-assertions', false);
export const enableSignedSAMLAuthnRequests = createFlagFunction('enable-signed-saml-authentication-requests', false);
export const enableEnterpriseSeatExpansions = createFlagFunction('enable-enterprise-seat-expansions', false);
export const enableExperimentationKeysLimit = createFlagFunction('enable-experimentation-keys-limit', false);
export const enableExperimentDashboardSearch = createFlagFunction('experiments-dashboard-search', false);
export const enableExperimentDashboardStatusFilter = createFlagFunction('experiment-dashboard-status-filter', false);
export const enableExperimentEventsTab = createFlagFunction('enable-experiment-events-tab', false);
export const enableExperimentsDashboardLoader = createFlagFunction('enableExperimentsDashboardLoader', false);
export const enableExperimentsGraphsWithAmberflo = createFlagFunction('enable-experiments-graphs-with-amberflo', false);
export const enableExportOnlyExperiments = createFlagFunction('enable-export-only-experiments', false);
export const enableFetchProfileContextFromAPI = createFlagFunction('enable-fetch-profile-context-from-api', false);
export const enableFilterFlagsByCreationDate = createFlagFunction(
  'enable-filter-flags-by-creation-date-via-dashboard',
  false,
);
export const enableFlagSessionReplay = createFlagFunction('enable-flag-session-replay', false);
export const enableFlagJsonEditor = createFlagFunction('flag-json-editor', false);
export const enableFollowFlagsByTeam = createFlagFunction('enable-follow-flags-by-team', false);
export const enableFullStoryLinksInDataDog = createFlagFunction('enable-fullstory-links-in-data-dog', false);
export const enableFunnelMetricRandomizationUnits = createFlagFunction(
  'query-funnel-metric-randomization-units',
  false,
);
export const enableGenAIFlagTemplates = createFlagFunction('enable-gen-ai-flag-templates', false);
export const enableGetSegmentsBatching = createFlagFunction('enable-get-segments-batching', false);
export const enableGoogleOauthRedirect = createFlagFunction('enable-google-oauth-redirect', false);
export const enableHistoricExperimentEvents = createFlagFunction('enable-historic-experiment-events', false);
export const enableHoldoutsUI = createFlagFunction('holdouts-ui', false);
export const enableIntercomChatAI = createFlagFunction('enable-intercom-chat-ai', false);
export const enableImprovedContextTargetingExperience = createFlagFunction(
  'enable-improved-context-targeting-experience',
  false,
);
export const enableImprovedContextTargetingExperienceFeaturePreview = createFlagFunction(
  'enable-improved-targeting-feature-preview',
  false,
);
export const enableLDidVerificationForSalesforce = createFlagFunction(
  'ld-account-id-verification-for-salesforce',
  false,
);
export const enableLiveEventsTab = createFlagFunction('enable-live-events-tab', false);
export const enableLiveExperimentEvents = createFlagFunction('enable-live-experiment-events', false);
export const enableLoginV2 = createFlagFunction('enable-login-v2', false);
export const enableLoginV2OAuth = createFlagFunction('enable-login-v2-oauth', false);
export const enableLoginFromDomainMatch = createFlagFunction('login-from-domain-match', false);
export const enableLogoutUrlForSamlConfigs = createFlagFunction('enable-logout-url-for-saml-configs', false);

export const enableMaintainerSelectionForFlagCloning = createFlagFunction(
  'enable-maintainer-selection-for-flag-cloning',
  false,
);

export const enableMAUSorting = createFlagFunction('enable-sorting-mau-chart-by-highest-ma-us', false);
export const enableMetricConnectionEffectSize = createFlagFunction('enable-metric-connection-effect-size', false);
export const enableMetricEventActivity = createFlagFunction('metric-event-activity', false);
export const enableMetricImpact = createFlagFunction('enable-metrics-impact', false);
export const enableMetricDetailsPageWithTabs = createFlagFunction('enable-metric-details-page-with-tabs', false);
export const enableMetricHoverTooltipExpResults = createFlagFunction(
  'enable-metric-hover-tooltip-in-experimentation-results',
  false,
);
export const enableMostRecentAuditLogHistory = createFlagFunction('enable-most-recent-flag-history', false);
export const enableMultipleAccountMembersEmail = createFlagFunction('enable-multiple-account-members-email', false);
export const enableMultipleAccountMembersPassword = createFlagFunction(
  'enable-multiple-account-members-password',
  false,
);
export const enableMultipleFlagTypeDefaults = createFlagFunction('enable-multiple-flag-type-defaults', false);
export const enableNewFormatters = createFlagFunction('use-new-formatters-in-experiment-results', false);
export const enableNewIAFeaturePreview = createFlagFunction('enable-new-ia-feature-preview', true);
export const enableNewInviteMembersPermissionsLogic = createFlagFunction(
  'enable-new-invite-members-permissions-logic',
  false,
);
export const enableNewPostExperimentationExperience = createFlagFunction(
  'enable-new-post-experimentation-experience',
  false,
);
export const enableNotificationSettingsChatColumnVariations = createFlagFunction(
  'enable-notification-settings-chat-column-variations',
  false,
);
export const enableOpenSeatsSnackbarNotification = createFlagFunction('enable-open-seats-snackbar-notification', false);
export const enableOptionalApprovalsForSegments = createFlagFunction('enable-optional-approvals-for-segments', false);
export const enablePaginationOnGetSegments = createFlagFunction('use-pagination-for-get-segments', false);
export const enablePrimaryContextsInUiOverride = createFlagFunction('enable-primary-contexts-in-ui-override', false);
export const enableClientMauReplaceContextInUI = createFlagFunction('enable-client-mau-replace-context-in-ui', false);
export const enableProgressiveRollout = createFlagFunction('enable-progressive-rollout', false);
export const enableProgressiveRolloutInRA = createFlagFunction('enable-progressive-rollout-in-ra', false);
export const enableProvisioningTeamsViaIdp = createFlagFunction('enable-provisioning-teams-via-idp', false);
export const enableReleaseAutomation = createFlagFunction('release-automation', false);
export const enableReleaseFlagAutomations = createFlagFunction('enable-release-flag-automations', false);
export const enableReleasesViewV2 = createFlagFunction('enable-releases-view-v2', false);
export const enableScimForAzure = createFlagFunction('enable-scim-for-azure', false);
export const enableScimForRippling = createFlagFunction('enable-scim-for-rippling', false);
export const enableSdkNotifications = createFlagFunction('enable-sdk-notifications', false);

export const enableSegmentApprovals = createFlagFunction('enable-segment-approvals', false);
export const enableServiceNowSegmentApprovals = createFlagFunction('enable-service-now-segment-approvals', false);
export const enableSelectEnvironmentForInstallSDK = createFlagFunction(
  'enable-select-environment-for-install-sdk',
  false,
);

export const enableNewSampleSizeCalculation = createFlagFunction('enable-new-sample-size-calculation', false);
export const enableSelfServeExperimentationKeysLimit = createFlagFunction(
  'enable-self-serve-experimentation-keys-limit',
  false,
);
export const enableSelfServeUBBWithAnnualCommits = createFlagFunction(
  'enable-self-serve-ubb-commit-overage-plans',
  false,
);
export const enableSelfServeUsageBasedBilling = createFlagFunction('enable-self-serve-usage-based-billing', false);
export const enableSelfServePaygFreeUnits = createFlagFunction('enable-self-serve-payg-free-units', false);
export const enableSelfServiceEnterpriseTrials = createFlagFunction('enable-self-service-enterprise-trials', false);

export const enableSemanticPatchForSegmentExpiringTargets = createFlagFunction(
  'enable-semantic-patch-for-segment-expiring-targets',
  false,
);
export const enableSemanticPatchInstructionsForSegments = createFlagFunction(
  'enable-semantic-patch-instructions-for-segments',
  false,
);
export const enableServerConnectionsGraphsUsingAmberflo = createFlagFunction(
  'enable-server-connections-graphs-using-amberflo',
  false,
);
export const enableSignupImprovements = createFlagFunction('enable-signup-improvements', false);
export const enableSnowflakeNativeFrontend = createFlagFunction('snowflake-exp-frontend', false);
export const enableSnowflakeExperimentResultsUIPOC = createFlagFunction(
  'enable-snowflake-experiment-results-ui-poc',
  false,
);
export const enableSubscriptionCTAV2 = createFlagFunction('enable-subscription-cta-v2', false);
export const enableSuggestedInvitesNotifications = createFlagFunction('enable-suggested-invites-notifications', false);
export const enableSuggestInvites = createFlagFunction('enable-suggest-invites', false);
export const enableTeamsAsFlagMaintainers = createFlagFunction('enable-teams-as-flag-maintainers', false);
export const enableTeamsCsvUpload = createFlagFunction('enable-teams-csv-upload', false);
export const enableTeamsOfTeams = createFlagFunction('enable-teams-of-teams', false);
export const enableTempExpKeysUsageAlert = createFlagFunction('enable-temp-experimentation-keys-usage-alert', false);
export const enableTrialCreationAfterJoinRequest = createFlagFunction(
  'enable-trial-account-creation-when-creating-join-request',
  false,
);
export const enableUC4ExperimentationMainControl = createFlagFunction('use-case-4-experimentation-main-control', false);
export const enableUnavailableCMAUDataAlert = createFlagFunction('enable-cmau-unavailable-data-alert', false);
export const enableUnverifiedMembersSignup = createFlagFunction('enable-unverified-members-signup', false);
export const enableUsage12MonthLookback = createFlagFunction('enable-usage-12-month-lookback', false);
export const enableVersionIdInUseMetricHook = createFlagFunction('enable-version-id-in-use-metric-hook', false);
export const enableWinningTreatmentSampleSizeCheck = createFlagFunction('winning-treatment-sample-size-check', false);
export const enforceEmailVerificationLandingPage = createFlagFunction('enforce-email-verification-landing-page', false);
export const enforceResourceNameLength = createFlagFunction('enforce-resource-name-length', false);
export const errorMonitoringIssuesUI = createFlagFunction('error-monitoring-issues-ui', false);
export const enableReleaseGuardianRefreshedUI = createFlagFunction('enable-rg-ui-refresh', false);
export const experimentAllocationForceOver = createFlagFunction<'warn' | 'block' | 'off'>(
  'experiment-allocation-force-over',
  'off',
);
export const experimentAttributeLimit = createFlagFunction('experiment-attribute-limit', 5);
export const experimentChartHeight = createFlagFunction('experiment-results-chart-height', 472);
export const experimentPaginationLimit = createFlagFunction('experiment-dashboard-page-size', 30);
export const experimentResultsUsesCUPED = createFlagFunction('experiment-results-api-uses-cuped', false);
export const experimentResultsUseTrafficField = createFlagFunction('experiment-results-use-traffic-field', false);
export const flagHomeKey = createFlagFunction('flag-entity-home-key', false);
export const flagListBreakpoint = createFlagFunction('flag-list-break-point', false);
export const flagListDisplayPreferences = createFlagFunction('flag-list-display-preferences', false);
export const flagListSummaryPopover = createFlagFunction('flag-list-summary-popover', false);
export const featurePreview = createFlagFunction('feature-preview', false);
export const filterGlobalFlagsByPurpose = createFlagFunction('filter-global-flags-by-purpose', false);
export const flagArchivalMessage = createFlagFunction(
  'flag-archival-message',
  'After you archive this flag, LaunchDarkly will serve the default value defined in your code',
);
export const flagCleanupSpikeGitar = createFlagFunction('flag-cleanup-spike-gitar', false);
export const flagCleanupGitarConfigFromStore = createFlagFunction('flag-cleanup-gitar-use-configuration-store', false);
export const flagCleanupGitarPollingInterval = createFlagFunction('flag-cleanup-gitar-polling-interval', 30);
export const flagDependencyWarningsEnabled = createFlagFunction('enable-flag-dependency-warnings', false);
export const flagEnvironmentApprovalProcesses = createFlagFunction('flag-environment-approval-processes', false);
export const flagEnvironmentApprovalProcessesSummaryTile = createFlagFunction(
  'flag-environment-approval-processes-summary-tile',
  false,
);
export const flagEnvironmentExperimentProcesses = createFlagFunction('flag-environment-experiment-processes', false);
export const flagInsightsChartType = createFlagFunction('flag-insights-chart-mode', 'area');
export const flagListLimitGuardrail = createFlagFunction('flag-list-limit-guardrail', false);
export const flagsQueryEnvironmentKeyLimit = createFlagFunction('flags-query-environment-key-limit', 10);
export const flagsQueryFlagKeyLimit = createFlagFunction('flags-query-flag-key-limit', 100);
export const flagStatusesEnvironmentKeyLimit = createFlagFunction('flag-statuses-environment-key-limit', 10);
export const flagStatusesFlagKeyLimit = createFlagFunction('flag-statuses-flag-key-limit', 100);
export const flexExperimentTimeSeriesRange = createFlagFunction('flex-stats-allow-time-series-date-selection', false);
export const forceReInitializationOfSegmentTargetingUI = createFlagFunction(
  'force-re-initialization-of-segment-targeting-ui',
  false,
);
export const forceTeamMaintainerAtFlagCreation = createFlagFunction('force-team-maintainer-at-flag-creation', false);
export const frontendDatadogFlagRumTracking = createFlagFunction('frontend-datadog-flag-rum-tracking', false);
export const frontendLogLevel = createFlagFunction('frontend-log-level', 'warn');
export const funnelGroupMetricLimit = createFlagFunction('funnel-group-metric-limit', 10);
export const getCodeReferenceListPageSize = createFlagFunction('code-reference-list-page-size', 10);
export const getExperimentListPageSize = createFlagFunction('experiment-list-page-size', 50);
export const getFlagListPageSize = createFlagFunction('flag-list-page-size', 250);
export const getMemberListPageSize = createFlagFunction('member-list-page-size', 25);
export const getNativeBigSegmentImportMaxFileSize = createFlagFunction('native-big-segment-import-max-file-size', 20);
export const getProjectEnvironmentsPageSize = createFlagFunction('project-environments-page-size', 5);
export const getProjectListPageSize = createFlagFunction('project-list-page-size', 1000);
export const getReactQueryStaleTime = createFlagFunction('react-query-stale-time', 0);
export const getStartedVideoId = createFlagFunction('get-started-video-id', 'hnhb3ptn2d');
export const guardedRolloutsOotbShortcut = createFlagFunction('guarded-rollouts-ootb-shortcut', false);
export const hideExperimentAlertBoxFromFlagTargeting = createFlagFunction(
  'hide-experiment-alert-box-from-flag-targeting',
  false,
);
export const holdoutMaximumAmount = createFlagFunction('maximum-holdout-amount', 99);
export const inviteAfterPurchaseOptions = createFlagFunction<
  'off' | 'on' | 'enable-invite-members-button' | 'enable-show-modal-after-purchase'
>('invite-after-purchase-options', 'off');
export const is2018ProTo2021PlanTransitionEnabled = createFlagFunction('enable-2018-pro-to-2021-plan-transition', true);
export const isABTestingEnabled = createFlagFunction('ab.testing', false);
export const isAccessTokensEnabled = createFlagFunction('enable-access-tokens', true);
export const isAccessWarningEnabled = createFlagFunction('enable-access-warning', false);
export const isAccountDeletionEnabled = createFlagFunction('enable-account-deletion', false);
export const isSelfServeFromEnterpriseTrialAllowed = createFlagFunction(
  'allow-self-serve-from-enterprise-trial',
  false,
);
export const isServerMAUUsageIssueBannerEnabled = createFlagFunction('enable-server-mau-usage-issue-banner', false);
export const isAllowSettableFlagSaltEnabled = createFlagFunction('allow-settable-flag-salt', false);
export const isAnalyticsTrackingEnabled = createFlagFunction('enable-segment-script', true);
export const isApiVersioningEnabled = createFlagFunction('enable-api-versioning', false);
export const isApprovalByTeamEnabled = createFlagFunction('enable-approval-by-team', false);
export const isApprovalsEnabled = createFlagFunction('enable-approvals', false);
export const isAuditLogEnabled = createFlagFunction('enable-audit-log', false);
export const isBeamerEnabled = createFlagFunction('enable-beamer-script', false);
export const isBeamerSegmentsEnabled = createFlagFunction('enable-beamer-segments', false);
export const isBigSegmentMergeEnabled = createFlagFunction('enable-big-segment-merge-functionality', false);
export const isBigSegmentsEnabled = createFlagFunction('enable-unbounded-segments', false);
export const isBillingMaintenanceModeEnabled = createFlagFunction('billing-maintenance-mode', false);
export const isBulkMemberEditEnabled = createFlagFunction('enable-bulk-member-edit', false);
export const isBulkUserManagementEnabled = createFlagFunction('enable-bulk-user-management', true);
export const isBypassApprovalsEnabled = createFlagFunction('enable-bypass-required-approval', false);
export const isCancelSubscriptionEnabled = createFlagFunction('enable-cancel-subscription', false);
export const isCanduEnabled = createFlagFunction('enable-candu', false);
export const isCaptchaEnabled = createFlagFunction('enable-captcha', true);
export const isCloneFlagEnabled = createFlagFunction('enable-clone-feature-flag', true);
/**
 * Use `!isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled()` when feature guarding is needed.
 */
export const isCodeReferencesEnabled = createFlagFunction('enable-flag-code-references', true);
export const isCommandBarEagerSearchEnabled = createFlagFunction('enable-command-bar-eager-search', false);
export const isCommandBarEnabled = createFlagFunction('enable-command-bar', false);
export const isCompareCopyEnabled = createFlagFunction('enable-compare-and-copy', true);
export const isConcurrentlyFetchManifestsAndSubsEnabled = createFlagFunction(
  'concurrently-fetch-goaltender-manifests-and-subscriptions',
  false,
);
export const isContextKindGeneric = createFlagFunction('context-kinds-for-aggregation', false);
export const isCourierInboxEnabled = createFlagFunction('enable-courier-inbox', false);
export const isCustomPropsOnFlagCreationFormEnabled = createFlagFunction(
  'enable-custom-properties-on-flag-creation-form',
  false,
);
export const isCustomRolesEnabled = createFlagFunction('custom.roles', false);
export const isDatadogRUMAppEnabled = createFlagFunction('enable-web-app-data-dog-rum-application-metrics', false);
export const isDebuggerHeartbeatEnabled = createFlagFunction('enable-debugger-heartbeat', false);
/**
 * DON'T USE DIRECTLY - Override switch to disable code references.
 *
 * Use `!isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled()` when feature guarding is needed.
 */
export const isDisableCodeReferencesWithOverrideEnabled = createFlagFunction('disable-code-references', false);

export const isDynamicDefaultTargetingContextKindEnabled = createFlagFunction(
  'enable-dynamic-default-targeting-context-kind',
  false,
);
export const isEmailVerificationRequired = createFlagFunction('require-email-verification', false);
export const isEnableStripePromoCodesEnabled = createFlagFunction('enable-stripe-promo-codes', false);
export const isEnvironmentCreationEnabled = createFlagFunction('create.environments', false);
export const isEventDebuggerEnabled = createFlagFunction('event-debugger', true);
export const isExpandMembersEndpointEnabled = createFlagFunction('enable-expand-members-endpoint', false);
export const isExperimentAllocationEnabled = createFlagFunction('enable-experiment-allocation', false);
export const isExperimentArchivingEnabled = createFlagFunction('enable-experiment-archiving', false);
export const isExperimentationApiEnabled = createFlagFunction('enable-experimentation-api', false);
export const isExperimentationSRMTestingEnabled = createFlagFunction('experimentation-srm-tests', false);
export const isExperimentTimeSeriesDisabled = createFlagFunction('disable-experiment-time-series', false);
export const isExperimentUseHllQueriesOnReadEnabled = createFlagFunction(
  'experimentation-queries-use-hll-on-read',
  false,
);
export const isExtendTrialInBannerEnabled = createFlagFunction('enable-extend-trial-in-banner', false);
export const isFeatureStoreIntegrationEnabled = createFlagFunction('enable-feature-store-integration', false);
export const isFetchIndividualTargetingLazyEnabled = createFlagFunction('fetch-individual-targets-lazy', false);
export const isFilterByApproversEnabled = createFlagFunction('enable-filter-by-approvers', false);
export const isFlagLinksEnabled = createFlagFunction('enable-flag-links', false);
export const isFlagPrerequisitesEnabled = createFlagFunction('Enable-flag-prerequisites', true);
export const isMetricEventActivityTabEnabled = createFlagFunction('EnableMetricEventActivityTab', false);
export const isFlagStatusEnabled = createFlagFunction('read.feature.status.redis', true);
export const isFollowFlagsEnabledOnDashboard = createFlagFunction('enable-follow-flags-on-dashboard', false);
export const isGetBigSegmentUserTargetEndpointEnabled = createFlagFunction(
  'enable-get-unbounded-segment-user-target',
  false,
);
export const isGithubOAuthSignUpEnabled = createFlagFunction('enable-github-oauth-sign-up', false);
export const isGoogleOAuthSignUpEnabled = createFlagFunction('enable-google-oauth-sign-up', false);
export const isGroupedOAuthApplicationsListEnabled = createFlagFunction(
  'enable-grouped-oauth-applications-list',
  false,
);
export const isInformationArchitecturePOCEnabled = createFlagFunction('ia-poc', false);
export const isInitialPrereqsEnabled = createFlagFunction('enable-initial-prereqs', false);
export const isLegacyExperimentResultsButtonEnabled = createFlagFunction(
  'enable-legacy-experiment-results-export-button',
  false,
);
export const isLinkToAccelerateEnabled = createFlagFunction('enable-link-to-accelerate', false);
export const isMemberAvatarUploadEnabled = createFlagFunction('enable-team-member-avatar-upload', false);
export const isMetricValuesOperatorEnabled = createFlagFunction('enable-metric-values-operation-toggle', false);
export const isMFARedirectEnabled = createFlagFunction('enable-mfa-redirect', false);
export const isMobileAppTargetingEnabled = createFlagFunction('enable-mobile-app-targeting', false);
export const isMultipleExperimentsOnSameFlagEnabled = createFlagFunction(
  'ExperimentationMultipleExperimentsWithSameFlag',
  false,
);
export const isNativeBigSegmentExportEnabled = createFlagFunction('enable-native-big-segment-export', false);
export const isNativeBigSegmentUIEnabled = createFlagFunction('enable-native-big-segment-ui', false);
export const isNewCreateExperimentFlowEnabled = createFlagFunction('enable-new-create-experiment-flow', false);
export const isNewCreateExperimentFlowGaFeaturesEnabled = createFlagFunction(
  'enable-new-create-experiment-flow-ga-features',
  false,
);
export const isNewExperimentDashboardEnabled = createFlagFunction('enable-new-experiment-dashboard', false);
export const isNewExperimentsOnFlagPageEnabled = createFlagFunction('get-new-experiments-on-flag', false);
export const isNewHiresExampleUserEnabled = createFlagFunction('new-hires-add-example-user', false);
export const isNewHiresHamedEnabled = createFlagFunction('new-hires-add-hamed', false);
export const isAndrewKlatzkeEnabled = createFlagFunction('new-hires-add-aklatzke', false);
export const isNHironakaEnabled = createFlagFunction('new-hires-add-nhironaka', false);
export const isNoeEnabled = createFlagFunction('new-hires-noe', false);
export const isGilJohnonEnabled = createFlagFunction('new-hires-add-gjohnson', false);
export const isNewIterationsWithArchivedFlagEnabled = createFlagFunction(
  'allow-new-iterations-with-archived-flag',
  false,
);
export const isNewMetricsSidePanelEnabled = createFlagFunction('use-new-metrics-side-panel-rg', false);
export const isNoAccessRoleEnabled = createFlagFunction('enable-no-access-role', false);
export const isNonNumericSuccessCriteriaEnabled = createFlagFunction('non-numeric-success-criteria-frontend', false);
export const isNumericMetricsIncidentMitigationEnabled = createFlagFunction(
  'numeric-metrics-incident-mitigation',
  true,
);
export const isPaginatedExperimentsDashboardEnabled = createFlagFunction('paginated-experiments-dashboard', false);
export const isPayloadFilteringEnabled = createFlagFunction('payload-filter-ui', false);
export const isPayloadFilteringTagSupportEnabled = createFlagFunction('payload-filter-ui-tag-support', false);
export const isPercentageRolloutForSegmentsEnabled = createFlagFunction(
  'enable-percentage-rollout-in-segment-rules',
  false,
);
export const isPlaceholderAvatarEnabled = createFlagFunction('placeholder-avatar', false);
export const isPlanChangeEnabled = createFlagFunction('plan.change', false);
export const isPresenceEnabled = createFlagFunction('presence', false);
export const isProductRefreshEnabled = createFlagFunction('product-refresh', false);
export const isProductSwitcherEnabled = createFlagFunction('enable-product-switcher', false);
export const isProjectCreationEnabled = createFlagFunction('create-projects', false);
export const isReactQueryDevtoolsEnabled = createFlagFunction('enable-react-query-devtools', false);
export const isReadOnlyExperimentRuleEnabled = createFlagFunction('read-only-experiment-rule', false);
export const isRebrandDiamondVariantEnabled = createFlagFunction('rebrand-diamond-variant', false);
export const isRelativeSchedulingEnabled = createFlagFunction('enable-relative-scheduling', false);
export const isReleaseGuardianStatusOnFlagDashboardEnabled = createFlagFunction(
  'release-guardian-status-on-flag-dashboard',
  false,
);
export const isRequireChangeConfirmationEnabled = createFlagFunction('enable-require-change-confirmation', true);
export const isRequireCommentsEnabled = createFlagFunction('enable-require-comments', true);
export const isRequiredApprovalsEnabled = createFlagFunction('require-approvals-for-an-environment', false);
export const isAutoApplyApprovalsEnabled = createFlagFunction('enable-approval-auto-apply', false);
export const isRequiredApprovalsForSpecificResourcesEnabled = createFlagFunction(
  'enable-required-approvals-for-specific-resources',
  false,
);
export const isRichWebhookStatusEnabled = createFlagFunction('enable-rich-webhook-status', false);
export const isRoleBuilderEnabled = createFlagFunction('enable-role-builder', false);
export const isRuleExclusionEnabled = createFlagFunction('enable-rule-exclusion', false);
export const isScheduledFlagChangesEnabled = createFlagFunction('enable-scheduled-flag-changes', false);
export const isScimEnabled = createFlagFunction('enable-scim-endpoints', false);
export const isSDKPromptEnabled = createFlagFunction('enable-sdk-prompt', false);
export const isSeatLimitEnforced = createFlagFunction('enforce-seat-limits', false);
export const isSkipToContentEnabled = createFlagFunction('skip-to-content', false);
export const isSlackIncomingHooksSupported = createFlagFunction('enable.slack.hooks', false);
export const isSlackTeamsBannerEnabled = createFlagFunction('enable-slack-teams-banner-on-integrations-page', false);
export const isSSOEnabled = createFlagFunction('sso-saml', false);
export const isStaticRelayProxyQueryingEnabled = createFlagFunction('usage-query-static-times', false);
export const isSuggestFlagMaintainerAsReviewerEnabled = createFlagFunction(
  'suggest-flag-maintainer-as-reviewer',
  false,
);
export const isSyncedSegmentRelayUIEnabled = createFlagFunction('synced-segment-relay-ui', false);
export const isTargetingToSegmentEnabled = createFlagFunction('targeting-to-segment', false);
export const isTeamsEnabled = createFlagFunction('account.enable.teams', false);
export const isThirdPartyScriptsEnabled = createFlagFunction('enable-third-party-scripts', true);
export const isTopLevelMetricsEnabled = createFlagFunction('top-level-metrics', false);
export const isTrackEventsEnabled = createFlagFunction('enable-track-events', true);
export const isTriggerWorkflowsEnabled = createFlagFunction('enable-trigger-workflows', false);
export const isTypographyRefreshEnabled = createFlagFunction('typography-refresh', false);
export const isUpdateBigSegmentTargetsUIEnabled = createFlagFunction('enable-update-big-segment-targets-ui', false);
export const isUseHTTPMethodOverrideEnabled = createFlagFunction('use-http-method-override', false);
export const isVideoInAppEnabled = createFlagFunction('enable-video-in-app', false);
export const isWelcomeFormEnabled = createFlagFunction('enable-welcome-form', false);
export const isDayOneTrialSuccessEnabled = createFlagFunction('enable-day-one-trial-success', false);
export const isGuardianEditionReverseTrialEnabled = createFlagFunction('enable-guardian-edition-reverse-trial', false);
export const isShowOnboardingInNavigationEnabled = createFlagFunction('show-onboarding-in-navigation', false);
export const isWorkflowBuilderEnabled = createFlagFunction('enable-workflow-builder', false);
export const isWorkflowBuilderUpsellEnabled = createFlagFunction('enable-workflow-builder-upsell', false);
/**
 * Temporary number flag that controls the "version" of the Launch Insights dashboard that is visible.
 *
 * Variations:
 * - `0`    - Unavailable (served when prerequisite `launch-insights` flag is serving false)
 * - `1.1`  - Version 1.1 (fast-follow updates to add functionality missing from the Launch Week 2024 demo)
 * - `2`    - Version 2 (for release by EOY FY24)
 */
export const launchInsightsVersion = createFlagFunction<0 | 1.1 | 2>('launch-insights-version', 0);
export const maximumAllowedMonitoredRolloutDurationMs = createFlagFunction(
  'maximum-allowed-monitored-rollout-duration-ms',
  -1,
);
export const maximumItemsInRoleFilter = createFlagFunction('maximum-number-of-roles-in-the-member-role-filter', -1);
export const measuredRolloutCustomDurationInMinutes = createFlagFunction(
  'release-guardian-custom-duration-in-minutes',
  0,
);
export const measuredRolloutStatusFlagKeyLimit = createFlagFunction('measured-rollout-status-batch-flag-key-limit', 50);
export const memberFilterPageSize = createFlagFunction('member-filter-page-size', 20);
export const metricDiscovery = createFlagFunction('metric-discovery', false);
export const metricsFuzzySearchUi = createFlagFunction('metrics-fuzzy-search-ui', false);
export const noiseyFeaturePreview = createFlagFunction('noisey-feature-preview', false);
export const numberFieldAsRolloutWeightInput = createFlagFunction('use-number-field-as-rollout-weight-input', false);
export const openSeatsNotificationLimit = createFlagFunction('open-seats-notification-limit', 0);
export const overageNotificationsLocation = createFlagFunction('overage-notifications-location', 'navbar');
export const percentileConfigurationOptions = createFlagFunction<{
  enabled: boolean;
  defaultPercentile: number;
  percentileOptions: number[];
  defaultConfidence: number;
  confidenceOptions: number[];
}>('percentile-configuration-options', {
  enabled: false,
  defaultPercentile: 90,
  percentileOptions: [],
  defaultConfidence: 90,
  confidenceOptions: [],
});
export const productAnalyticsResultsApiRefreshInterval = createFlagFunction(
  'product-analytics-results-api-refresh-interval',
  1000,
);
export const releaseGuardian = createFlagFunction('release-guardian', false);
export const releaseListAPIMaxLimit = createFlagFunction('release-list-api-max-limit', 20);
export const resourceCountReleasePipelines = createFlagFunction('resource-count-release-pipelines', 1);
export const reviveFlagOverviewAcrossEnvironments = createFlagFunction(
  'revive-flag-overview-across-environments',
  false,
);
export const rolloutBucketInWorkflowBuilder = createFlagFunction('rollout-bucket-in-workflow-builder', false);
export const savedDashboardLimit = createFlagFunction('saved-dashboard-limit', 20);
export const selectAllPersistence = createFlagFunction('select-all-persistence', false);
export const segmentKeyLimit = createFlagFunction('segment-key-limit', 20);
export const segmentListApprovalTiles = createFlagFunction('segment-list-approval-tiles', false);
export const segmentTargetBatchLimit = createFlagFunction('target-batch-limit', -1);
export const selfServeEnterpriseTrialDuration = createFlagFunction('self-service-enterprise-trial-duration', 30);
export const sentryIntegration = createFlagFunction('sentry-integration', false);
export const separateFlagStatusFromConfig = createFlagFunction('separate-flag-status-from-flag-configuration', false);
export const shouldCleanupAfterFlagDeletion = createFlagFunction(
  'cleanup-flag-related-resources-after-flag-deletion',
  false,
);
export const shouldClearLocalStorageOnLogout = createFlagFunction('clear-localstorage-on-logout', false);
export const shouldSendFrontEndTrackEventsToCatfood = createFlagFunction(
  'send-front-end-track-events-to-catfood',
  false,
);
export const shouldTrackWebVitals = createFlagFunction<string[]>('track-web-vitals', []);

export const showAmberfloCostAndUsageGraph = createFlagFunction('show-amberflo-cost-and-usage-graph', false);
export const showImportantAuditLogEntries = createFlagFunction('show-important-audit-log-entries', false);
export const showLastYearClientMauUsage = createFlagFunction('show-last-year-client-mau-usage', false);
export const simpleTrafficAllocationImprovements = createFlagFunction('simple-traffic-allocation-improvements', false);
export const simplifiedToggleUX = createFlagFunction<'disabled' | 'active-only' | 'emphasize-active'>(
  'simplified-toggle-ux-multi',
  'disabled',
);
export const spaPageViewsForSegmentCom = createFlagFunction('spa-page-views-for-segment-com', false);
export const specificOverageAlerts = createFlagFunction<string[]>('enable-specific-overage-alerts', []);
export const standardMetricGroupMetricLimit = createFlagFunction('standard-metric-group-metric-limit', 20);
export const stripeForbiddenCountries = createFlagFunction<string[]>('stripe-forbidden-countries', []);
export const switchBillingYearlyToMonthlyGracePeriod = createFlagFunction(
  'switch-billing-yearly-to-monthly-grace-period',
  0,
);
export const syncedSegmentRelayVersion = createFlagFunction('synced-segment-relay-version', '6.99.0');
export const teamsListPageSize = createFlagFunction('teams-list-page-size', -1);
export const throwErrorsInRouterHooks = createFlagFunction('throw-errors-in-router-hooks', false);
export const trackRecentProjects = createFlagFunction('track-recent-projects', false);
export const unauthenticatedPagePromo = createFlagFunction<
  'off' | 'default' | 'post-galaxy' | 'ten-years' | 'winter-release' | 'aws-reinvent'
>('unauthenticated-page-promo', 'off');
export const unauthenticatedPagePromoEffect = createFlagFunction<'off' | 'stars'>(
  'unauthenticated-page-promo-effect',
  'off',
);
export const useSelfServeFoundation2023PricingV1 = createFlagFunction(
  'use-self-serve-foundation-2023-pricing-v1',
  false,
);

export const userTargetAlertThreshold = createFlagFunction('user-target-alert-threshold', -1);
export const userTargetWarningThreshold = createFlagFunction('user-target-warning-threshold', -1);
export const v1ReleasePipelines = createFlagFunction<'Available' | 'Deprecated' | 'Unavailable'>(
  'release-pipelines-v1',
  'Available',
);
export const validateNoSummedMetricsInFunnels = createFlagFunction('validate-no-summed-metrics-in-funnels', false);

export const variationValueNumberField = createFlagFunction('variation-value-number-field', false);

export const webAppPerformanceMetricsThresholds = createFlagFunction<{ [key: string]: number }>(
  'web-app-performance-metrics-thresholds',
  {},
);
export const enableSegmentTestRun = createFlagFunction('enable-segment-test-run', false);
export const enableCheckYourEmailEndpoint = createFlagFunction('enable-check-your-email-endpoint', false);

export const isFlagImportIntegrationEnabled = createFlagFunction('enable-flag-import-integration', false);
export const isFlagNamingConventionEnabled = createFlagFunction('enable-flag-naming-conventions', false);

export const cacheFlagFiltersAndSortOrdering = createFlagFunction('cache-flag-filters-and-sort-ordering', false);
export const enableCancelReason = createFlagFunction('enable-cancel-reason', false);

export const isIntegrationConfigurationsEnabled = createFlagFunction('enable-integration-configurations', false);

export const enableRemoveOrganizationFromSignup = createFlagFunction('remove-organization-from-signup-form', false);

export const enableFlagCleanupFeature = createFlagFunction('enable-flag-cleanup-feature', false);
export const enableFlagCleanupStatesFilter = createFlagFunction('enable-flag-cleanup-states-filter', false);
export const enableAIBundle = createFlagFunction('enable-ai-bundle', false);
export const promoteCreateTrial = createFlagFunction('promote-create-trial', false);

export const enableRoleAttributesUI = createFlagFunction('role-attributes-ui', false);

export const isExperimentationForAiConfigsEnabled = createFlagFunction('enable-experimentation-for-ai-configs', false);

export const isQuickActionTilesEnabled = createFlagFunction('enable-targeting-quick-action-tiles', false);
export const enableAnnouncementBanner = createFlagFunction('enable-announcement-banner', false);
export const announcementPollingIntervalSeconds = createFlagFunction('announcement-polling-interval-seconds', 0);
export const enableOnboardingApiPhase1 = createFlagFunction('enable-onboarding-api-phase-1', false);
export const enableOnboardingApiPhase2 = createFlagFunction('enable-onboarding-api-phase-2', false);
export const enableOutOfTheBoxRoles = createFlagFunction('enable-out-of-the-box-roles-fe', false);
export const enableReleaseGuardianRefreshedUIDropdown = createFlagFunction('enable-rg-ui-refresh-dropdown', false);
export const isRolloutProgressTableRefactorEnabled = createFlagFunction('rollout-progress-tables-refactor', false);
export const enableGDPRComplianceCheckboxes = createFlagFunction('gdpr-compliance-checkboxes', false);
export const isEventExplorerUIEnabled = createFlagFunction('event-explorer-ui-toggle', false);
export const isEventExplorerExpandEnabled = createFlagFunction('enable-event-explorer-expand', false);
export const enableSandboxFE = createFlagFunction('enable-sandbox-fe', false);
export const isReleaseGuardianLeftNavMasterEnabled = createFlagFunction('rg-left-nav-master', false);
export const rel5067ProjectSwitch = createFlagFunction('REL-5067-project-switch', false);
export const aiConfigJsonVariationPayload = createFlagFunction('enable-ai-config-json-variation-payload', 128);
export const isNewSidebarForExperimentPageReleased = createFlagFunction(
  'release-new-sidebar-for-experiment-page',
  false,
);
export const isNewActionAreaForExperimentPageReleased = createFlagFunction(
  'release-new-action-area-for-experiment-page',
  false,
);

/*
 Users access the sandbox from the primary app through the SandboxCallout component.
 Use enableSandbox to control access to the sandbox by showing/hiding SandboxCallout.

 If you want to customize UI behavior in the sandbox, like hiding sdk keys or showing
 the sandbox banner, use the isSandbox constant instead. There is no need to use both
 enableSandbox and isSandbox simultaneously because each has a distinct purpose.
 */
export const enableSandbox = createFlagFunction('enable-sandbox', false);

export const isFlagsPageGuardedReleasesFilterEnabled = createFlagFunction('flags-page-guarded-releases-filter', false);
export const shouldSeeGuardedRolloutsUpsell = createFlagFunction('guarded-releases-upsell', false);
export const enableFernAlgoliaInstance = createFlagFunction('enable-fern-algolia-instance', false);
export const enableExperimentationOnboarding = createFlagFunction('enable-experimentation-onboarding', false);
