import { type JSX, cloneElement } from 'react';
import { Placement, useId, VisuallyHidden } from 'react-aria';
import { Pressable, Tooltip, TooltipTrigger } from '@launchpad-ui/components';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';

import styles from 'stylesheets/components/HelpTooltip.module.css';

export type HelpTooltipProps = {
  placement?: Placement;
  className?: string;
  size?: IconProps['size'];
  children?: string | JSX.Element;
  labelText?: string;
  iconClassName?: string;
  tooltipClassName?: string;
};

function HelpTooltipComponent({
  children,
  placement = 'bottom',
  className,
  size = 'small',
  labelText,
  iconClassName,
  tooltipClassName,
  ...props
}: HelpTooltipProps) {
  const ariaButtonId = useId();

  const parseContent = (toolTipContent: JSX.Element | string | undefined, ariaId: string) => {
    if (!toolTipContent) {
      return toolTipContent;
    }
    if (typeof toolTipContent === 'string') {
      return (
        <div role="tooltip" id={ariaId}>
          {toolTipContent}
        </div>
      );
    }
    return cloneElement(toolTipContent, { role: 'tooltip', id: ariaId });
  };

  return (
    <TooltipTrigger>
      <Pressable {...props} className={classNames(styles.helpTooltip, className)} data-test-id="help-tooltip-trigger">
        <VisuallyHidden elementType="span">{labelText ?? 'More information'}</VisuallyHidden>
        <Icon name="info-circle" className={classNames(styles.helpIcon, iconClassName)} size={size} />
      </Pressable>
      <Tooltip className={tooltipClassName} placement={placement}>
        {parseContent(children, ariaButtonId)}
      </Tooltip>
    </TooltipTrigger>
  );
}

/* eslint-disable import/no-default-export */
export default function HelpTooltip(props: HelpTooltipProps) {
  return <HelpTooltipComponent {...props} />;
}
