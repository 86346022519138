import { type JSX, cloneElement, forwardRef, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { ApplicationCollectionRep, ApplicationRep } from '@gonfalon/applications';
import { scrollIntoViewIfNeeded } from '@gonfalon/dom';
import { ReleasePipeline } from '@gonfalon/release-pipelines';
import classNames from 'clsx';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record } from 'immutable';
import { Button } from 'launchpad';

import { getAllExperiments } from 'components/experimentation/common/api/experiment';
import { ExperimentV2 } from 'components/experimentation/common/types';
import { FlagStatus } from 'components/FlagStatus';
import { DataList, DataListItem, DataListToolbar } from 'components/ui/datalist';
import { SearchField } from 'components/ui/forms';
import { AlignValue, Cell, Grid, GridLayoutValue } from 'components/ui/grid';
import { Marker } from 'components/ui/icons/Marker';
import { Module, ModuleContent } from 'components/ui/module';
import UserAvatar from 'components/UserAvatar';
import styles from 'stylesheets/components/ResourceFinder.module.css';
import { AriaLabel } from 'types/accessibilityLabels';
import { Member } from 'utils/accountUtils';
import { Environment, getProjectForEnv } from 'utils/environmentUtils';
import { FlagStatus as FlagStatusRecord } from 'utils/flagStatusUtils';
import { Flag } from 'utils/flagUtils';
import { GoaltenderSubscription } from 'utils/goaltenderUtils';
import { Goal } from 'utils/goalUtils';
import { PayloadFilter } from 'utils/payloadFilterUtils';
import { Project } from 'utils/projectUtils';
import { Role } from 'utils/roleUtils';
import { Segment } from 'utils/segmentUtils';
import { makeFilter } from 'utils/stringUtils';
import { Team } from 'utils/teamsUtils';
import { WorkflowTemplateSummary } from 'utils/workflowTemplateUtils';

const filterFor = (q?: string, ...props: string[]) => (q ? makeFilter(q, ...props) : () => true);

type ResourceProps = {
  className?: string;
  id: string;
  isActive?: boolean;
  children?: React.ReactNode;
  type: string;
  projKey?: string;
};
type Ref = HTMLDivElement;

const Resource = forwardRef<Ref, ResourceProps>((props, ref) => {
  const { isActive, children, className, type, projKey, ...finalProps } = props;

  return (
    <DataListItem
      {...finalProps}
      className={classNames(styles.resource, styles.dataListItem, className)}
      isActive={isActive}
      ref={ref}
    >
      {children}
    </DataListItem>
  );
});

export type ResourceFinderProps = {
  projects: Map<string, Project>;
  environments: Map<string, Environment>;
  members: Map<string, Member>;
  roles: Map<string, Role>;
  flags: List<Flag>;
  segments: List<Segment>;
  statuses: Map<string, FlagStatusRecord>;
  metrics: List<Goal>;
  integrations: List<GoaltenderSubscription>;
  applications?: ApplicationCollectionRep;
  teams: List<Team>;
  payloadFilters: List<PayloadFilter>;
  templates: WorkflowTemplateSummary[];
  releasePipelines: ReleasePipeline[];
  onClearResourceType: () => void;
  onValue: (id: string, type: string, projKey?: string) => void;
  resourceType: string;
  enableTeams: boolean;
  enableAppsAndAppVersions: boolean;
  enableFlagStatus: boolean;
  enableABTesting: boolean;
  enableCustomRoles: boolean;
  enablePayloadFiltering: boolean;
  enableTeamsOfTeams: boolean;
  enableNewExperiments: boolean;
};

/* eslint-disable import/no-default-export */
export default function ResourceFinder(props: ResourceFinderProps) {
  const {
    resourceType,
    enableTeams,
    enableAppsAndAppVersions,
    enableABTesting,
    enablePayloadFiltering,
    enableTeamsOfTeams,
    enableFlagStatus,
    enableNewExperiments,
    statuses,
    enableCustomRoles,
    onValue,
    projects,
    environments,
    members,
    roles,
    flags,
    segments,
    metrics,
    integrations,
    applications,
    teams,
    payloadFilters,
    templates,
    releasePipelines,
    onClearResourceType,
  } = props;
  const [q, setQ] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [experiments, setExperiments] =
    useState<List<Record<Pick<ExperimentV2, '_creationDate' | '_maintainerId' | '_id' | 'key' | 'name'>>>>(List());
  const [activeResource, setActiveResource] = useState<{ id: string; type: string; projKey?: string }>();
  const [resourceEls] = useState<{
    [key: string]: { Resource: JSX.Element; id: string; type: string; projKey?: string };
  }>({});
  const searchRef = useRef(null);
  const resourceListRef = useRef(null);

  useEffect(() => {
    if (enableNewExperiments) {
      async function loadExperiments() {
        setExperiments(
          fromJS(await getAllExperiments())
            .get('items')
            .toList(),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadExperiments();
    }
  }, []);

  const renderMembers = (query: string) =>
    getMembers(query)
      .map((member) => (
        <Resource key={member._id} id={member._id} type="member" className="fs-exclude">
          <div className={styles.resourceMark}>
            <UserAvatar member={member} size="small" />
          </div>
          {member.getDisplayName()}
        </Resource>
      ))
      .toArray();

  const renderTeams = (query: string) =>
    getTeams(query)
      .map((team) => (
        <Resource key={team.key} id={team.key} type="team">
          {team.name}
        </Resource>
      ))
      .toArray();

  const renderProjects = (query: string) =>
    getProjects(query)
      .map((project) => (
        <Resource key={project.key} id={project.key} type="project">
          {project.name}
        </Resource>
      ))
      .toArray();

  const renderEnvironments = (query: string) =>
    getEnvironments(query)
      .map((env) => {
        const project = getProjectForEnv(env, projects);
        return (
          <Resource key={env._id} id={env.key} type="environment" projKey={project?.key}>
            <div className={styles.resourceMark}>
              <Marker size="medium" fill={env.color.css()} />
            </div>
            {project && `${project.name} / `}
            {env.name}
          </Resource>
        );
      })
      .toArray();

  const renderFlags = (query: string) =>
    getFlags(query)
      .map((flag) => {
        const status = statuses.getIn(['entities', flag.key, 'entity']);
        const showStatus = enableFlagStatus && status;

        return (
          <Resource key={`flag-${flag.key}`} id={flag.key} type="flag">
            <div className={styles.resourceMark}>
              {showStatus && <FlagStatus status={status} temporary={flag.temporary} />}
            </div>
            {flag.name}
          </Resource>
        );
      })
      .toArray();

  const renderMetrics = (query: string) =>
    getMetrics(query)
      .map((metric) => (
        <Resource key={metric.get('_id')} id={metric.get('_id')} type="metric">
          {metric.get('name')}
        </Resource>
      ))
      .toArray();

  const renderExperiments = (query: string) =>
    getExperiments(query)
      .map((experiment) => (
        <Resource key={experiment.get('key')} id={experiment.get('key')} type="experiment">
          {experiment.get('name')}
        </Resource>
      ))
      .toArray();

  const renderPayloadFilters = (query: string) =>
    getPayloadFilters(query)
      .map((payloadFilter) => (
        <Resource key={`payload-filter-${payloadFilter.key}`} id={payloadFilter.key} type="payload-filter">
          {payloadFilter.name}
        </Resource>
      ))
      .toArray();

  const renderRoles = (query: string) =>
    getRoles(query)
      .map((role) => (
        <Resource key={`role-${role.key}`} id={role.key} type="role">
          {role.name}
        </Resource>
      ))
      .toArray();

  const renderSegments = (query: string) =>
    getSegments(query)
      .map((segment) => (
        <Resource key={`segment-${segment.key}`} id={segment.key} type="segment">
          {segment.name}
        </Resource>
      ))
      .toArray();

  const renderIntegrations = (query: string) =>
    getIntegrations(query)
      .map((integration) => (
        <Resource key={integration.get('_id')} id={integration.get('_id')} type="integration">
          <div className={styles.resourceMark}>
            <img src={integration.getIn(['_manifest', 'icons', 'square'])} alt="logo" />
          </div>
          {integration.get('name')}
        </Resource>
      ))
      .toArray();

  const renderTemplates = (query: string) =>
    getTemplates(query).map((template) => (
      <Resource key={template._key} id={template._key} type="template">
        {template.name}
      </Resource>
    ));

  const renderApplication = (query: string) =>
    getApplications(query).map((app: ApplicationRep) => (
      <Resource key={app.key} id={app.key} type="application">
        {app.name}
      </Resource>
    ));

  const renderReleasePipelines = (query: string) =>
    getReleasePipelines(query).map((releasePipeline: ReleasePipeline) => (
      <Resource key={releasePipeline.key} id={releasePipeline.key} type="release-pipeline">
        {releasePipeline.name}
      </Resource>
    ));

  const renderItemsForResourceType = (type: string, query: string): JSX.Element[] => {
    const renderFn = {
      environment: renderEnvironments,
      feature: renderFlags,
      flag: renderFlags,
      metric: renderMetrics,
      experiment: renderExperiments,
      member: renderMembers,
      'payload-filter': renderPayloadFilters,
      project: renderProjects,
      role: renderRoles,
      segment: renderSegments,
      integration: renderIntegrations,
      application: renderApplication,
      team: renderTeams,
      template: renderTemplates,
      'release-pipeline': renderReleasePipelines,
    }[type];
    if (!renderFn) {
      return [];
    }
    return renderFn(query) as JSX.Element[];
  };

  const renderHeadingForResourceType = (type: string): JSX.Element[] => {
    const label = {
      environment: 'Environments',
      feature: 'Flags',
      flag: 'Flags',
      metric: 'Metrics',
      experiment: 'Experiments',
      member: 'Members',
      'payload-filter': 'Payload filters',
      project: 'Projects',
      role: 'Roles',
      segment: 'Segments',
      integration: 'Integrations',
      application: 'Applications',
      team: 'Teams',
      template: 'Templates',
      'release-pipeline': 'Release pipelines',
    }[type];
    if (!label) {
      return [];
    }
    return [
      <DataListItem heading key={label} className={classNames(styles.dataListItem, 'u-flex u-flex-between')}>
        {label}
      </DataListItem>,
    ];
  };

  const getTypeForResource = (index: number) => {
    const activeItem = resourceEls[index];
    return activeItem && activeItem.type;
  };

  const handleSearchChange = (query: string) => {
    const id = activeResource?.id || '';
    const resources = getResourceIds(resourceType, query);

    if (resources.length && !resources.includes(id)) {
      setQ(query);
      setActiveIndex(0);
      setActiveResource({ id: resources[0], type: getTypeForResource(0) });
    } else if (resources.length && resources.indexOf(id) !== activeIndex) {
      setQ(query);
      setActiveIndex(resources.indexOf(id));
    } else {
      setQ(query);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const ops: { [key: number]: () => void } = {
      13: handleEnter,
      38: handleUpArrow,
      40: handleDownArrow,
    };

    if (event.keyCode in ops) {
      event.preventDefault();
      ops[event.keyCode].call(resourceListRef.current);
    }
  };

  const handleEnter = () => {
    const resources = getResourceIds(resourceType);
    const callback = (id: string, type: string, projKey?: string) => onValue(id, type, projKey);
    if (resources.length === 0) {
      return;
    }
    if (!activeResource) {
      const id = resources[0];

      setActiveIndex(0);
      setActiveResource({ id, type: resourceType });
      callback(id, getTypeForResource(0));
    } else {
      callback(activeResource.id, activeResource.type, activeResource.projKey);
    }
  };

  const handleUpArrow = () => {
    focus(activeIndex !== undefined ? activeIndex - 1 : 0);
  };

  const handleDownArrow = () => {
    focus(activeIndex !== undefined ? activeIndex + 1 : 0);
  };

  const focus = (index: number) => {
    const count = getResourceIds(resourceType, q).length;

    if (count === 0) {
      return;
    }

    const activeItem = resourceEls[index];

    setActiveIndex(index);
    setActiveResource({ id: activeItem.id, type: activeItem.type, projKey: activeItem.projKey });
    const el = document.getElementById(activeItem.id);
    if (!el) {
      return;
    }
    scrollIntoViewIfNeeded(el);
  };

  const getResourceIds = (resource?: string, query?: string): string[] => {
    const idsForResource = resource
      ? {
          environment: getEnvironments(query).map((e) => e.key),
          feature: getFlags(query).map((f) => f.key),
          flag: getFlags(query).map((f) => f.key),
          metric: getMetrics(query).map((g) => g.get('_id')),
          experiment: getExperiments(query).map((e) => e.get('_id')),
          member: getMembers(query).map((m) => m._id),
          project: getProjects(query).map((m) => m.key),
          role: getRoles(query).map((r) => r.key),
          'payload-filter': getPayloadFilters(query).map((f) => f.key),
          segment: getSegments(query).map((s) => s.key),
          integration: getIntegrations(query).map((s) => s._id || ''),
          application: getApplications(query).map((a) => a.key || ''),
          team: getTeams(query).map((t) => t.key),
          template: getTemplates(query).map((t) => t._key),
        }[resource]
      : undefined;

    if (resource) {
      return idsForResource ? [...idsForResource] : [];
    }
    return [
      ...(enableTeams ? getResourceIds('member', q) : []),
      ...(enableAppsAndAppVersions ? getResourceIds('application', q) : []),
      ...(enableTeamsOfTeams ? getResourceIds('team', q) : []),
      ...getResourceIds('project', q),
      ...getResourceIds('environment', q),
      ...getResourceIds('flag', q),
      ...(enableABTesting ? getResourceIds('metric', q) : []),
      ...(enableNewExperiments ? getResourceIds('experiment', q) : []),
      ...(enableTeams && enableCustomRoles ? getResourceIds('role', q) : []),
      ...(enablePayloadFiltering ? getResourceIds('payload-filter', q) : []),
      ...getResourceIds('integration', q),
      ...getResourceIds('template', q),
    ];
  };

  const getMembers = (query?: string) => members.filter(filterFor(query, 'firstName', 'lastName', 'email')).toList();

  const getTeams = (query?: string) => teams.filter(filterFor(query, 'name', 'key')).toList();

  const getProjects = (query?: string) => projects.filter(filterFor(query, 'name')).toList();

  const getEnvironments = (query?: string) => environments.filter(filterFor(query, 'name')).toList();

  const getFlags = (query?: string) => flags.filter(filterFor(query, 'name', 'description')).toList();

  const getSegments = (query?: string) => segments.filter(filterFor(query, 'name', 'description')).toList();

  const getMetrics = (query?: string) => metrics.filter(filterFor(query, 'name')).toList();

  const getExperiments = (query?: string) => experiments.filter(filterFor(query, 'name')).toList();

  const getRoles = (query?: string) => roles.filter(filterFor(query, 'name')).toList();

  const getIntegrations = (query?: string) => integrations.filter(filterFor(query, 'name'));

  const getApplications = (query?: string): List<ApplicationRep> =>
    applications?.items ? List(applications.items).filter(filterFor(query, 'name')) : List();

  const getTemplates = (query?: string) => templates.filter(filterFor(query, 'name'));

  const getPayloadFilters = (query?: string) => payloadFilters.filter(filterFor(query, 'name', 'key')).toList();

  const getReleasePipelines = (query?: string) => releasePipelines.filter(filterFor(query, 'name', 'key'));

  let resourceTypes = [];

  if (resourceType) {
    resourceTypes = [resourceType];
  } else {
    if (enableTeams) {
      resourceTypes.push('member');
    }
    resourceTypes = resourceTypes.concat(['project', 'environment', 'flag', 'integration']);
    if (enableAppsAndAppVersions) {
      resourceTypes.push('application');
    }
    if (enableABTesting) {
      resourceTypes.push('metric');
    }
    if (enableNewExperiments) {
      resourceTypes.push('experiment');
    }
    if (enableTeams) {
      resourceTypes.push('role');
    }
    if (enableTeamsOfTeams) {
      resourceTypes.push('team');
    }
    if (enablePayloadFiltering) {
      resourceTypes.push('payload-filter');
    }
    resourceTypes.push('template');
    resourceTypes.push('release-pipeline');
    resourceTypes.push('segment');
  }

  const displayShowAllButton = resourceTypes.length === 1;

  const rows = resourceTypes.reduce(
    (currentRows: JSX.Element[], type: string) =>
      currentRows.concat([...renderHeadingForResourceType(type), ...renderItemsForResourceType(type, q)]),
    [],
  );

  const handleFocus = (index: number) => {
    focus(index);
  };

  return (
    <div role="menu" className={styles.resourceFinder} onKeyDown={handleKeyDown} tabIndex={0} ref={resourceListRef}>
      <p className="u-mb-l">
        Find a resource ID to insert into your policy. Resources include projects, environments, feature flags,
        segments, members, roles, integrations, and templates.
      </p>

      <Module>
        <ModuleContent isSnug>
          <DataListToolbar className={styles.dataListToolbar}>
            <Grid layout={GridLayoutValue.FULL} align={AlignValue.MIDDLE}>
              <Cell>
                <SearchField
                  value={q}
                  onChange={handleSearchChange}
                  placeholder="Find a resource"
                  aria-label={AriaLabel.SEARCH_RESOURCES}
                  ref={searchRef}
                  autoFocus
                  isFullWidth
                />
              </Cell>
            </Grid>
          </DataListToolbar>

          <DataList
            compact
            className={classNames(styles.dataList, {
              [styles.dataListWithMore]: displayShowAllButton,
            })}
          >
            {rows.map((row, i) => {
              const { id, type, projKey } = row.props;
              let component;
              if (id) {
                const rowKey = row?.key ? `${row?.key} + ${i}` : undefined;
                component = cloneElement(row, {
                  key: rowKey,
                  ref: ((idx) => (el: JSX.Element) => {
                    resourceEls[idx] = {
                      Resource: el,
                      id,
                      type,
                      projKey,
                    };
                  })(i),
                  isActive: i === activeIndex,
                  onClick: () => onValue(id, type, projKey),
                  onFocus: () => handleFocus(i),
                  onMouseEnter: () => handleFocus(i),
                });
              } else {
                component = cloneElement(row, {
                  key: `heading-${i}`,
                });
              }

              return component;
            })}
          </DataList>
          {displayShowAllButton && (
            <DataListToolbar className="u-flex u-flex-center">
              <Button className="ResourceFinder-showAllButton" kind="link" onClick={onClearResourceType}>
                Show all resources
              </Button>
            </DataListToolbar>
          )}
        </ModuleContent>
      </Module>

      <p className="u-fs-sm u-mt-m">
        Press <kbd>Enter</kbd> to insert the ID into the policy field. You can also use <kbd>↑</kbd> and <kbd>↓</kbd> to
        navigate.
      </p>
    </div>
  );
}
