import { type JSX } from 'react';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

import './styles.css';

export type AbbrProps = {
  /**
   * Tooltip contents displayed on hover
   */
  title: string;
  content?: JSX.Element | string;
  placement?: PopoverPlacement;
  children: React.ReactNode;
  popoverContentClassName?: string;
  role?: string | undefined;
  tabIndex?: number | undefined;
};

/* eslint-disable import/no-default-export */
export default function Abbr({
  title,
  placement,
  children,
  content,
  popoverContentClassName,
  role = undefined,
  tabIndex = undefined,
}: AbbrProps) {
  return (
    <Tooltip placement={placement} content={content || title} popoverContentClassName={popoverContentClassName}>
      <abbr role={role} tabIndex={tabIndex} aria-label={title}>
        {children}
      </abbr>
    </Tooltip>
  );
}
