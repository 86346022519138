import { Err, Ok, Result } from 'ts-results';

import {
  account,
  aiConfig,
  aiModelConfig,
  application,
  contextKind,
  destination,
  domainVerification,
  environment,
  EnvironmentResource,
  experiment,
  flag,
  goal,
  holdout,
  integration,
  isResourceType,
  layer,
  member,
  MemberResource,
  metric,
  metricGroup,
  payloadFilter,
  pendingRequest,
  project,
  ProjectResource,
  PropertySelectors,
  relayProxyConfig,
  releasePipeline,
  repository,
  ResourceSpecifier,
  ResourceType,
  role,
  segment,
  serviceToken,
  team,
  template,
  token,
  user,
  webhook,
} from './ast';
import { Lexer } from './lexer';
import { Token } from './token';

// Regex to match role attribute syntax
// e.g. ${roleAttribute/devProjectKey}
const ROLE_ATTRIBUTE_REGEX = /\$\{roleAttribute\/([^}]+)\}/;

export class ParserError extends Error {
  name = 'ParserError';
  token?: Token;

  constructor(message: string, _token?: Token) {
    super(message);
    this.message = message;
    this.token = _token;
  }
}

export class SyntaxError extends ParserError {
  name = 'SyntaxError';
}

export class Parser {
  private readonly lexer: Lexer;

  // @ts-expect-error initialized via this.nextToken in constructor
  private currentToken: Token;
  // @ts-expect-error initialized via this.nextToken in constructor
  private peekToken: Token;

  constructor(lexer: Lexer) {
    this.lexer = lexer;

    // Initialize the current and peek tokens
    this.nextToken();
    this.nextToken();
  }

  public parse(): Result<ResourceSpecifier, ParserError> {
    if (this.currentToken.type === 'eof') {
      return Err(new ParserError('Empty input', copy(this.currentToken)));
    }

    if (this.currentToken.type !== 'resource-type') {
      return Err(new ParserError('Expected resource type'));
    }

    const result = this.parseResourceIdentifier();
    if (result.err) {
      return result;
    }

    return Ok(result.val);
  }

  private parseResourceIdentifier(): Result<ResourceSpecifier, ParserError> {
    const parts = this.readResourceIdentifierParts();
    if (parts.err) {
      switch (this.currentToken.text) {
        case 'acct':
          return Ok(account());
        default:
          return parts;
      }
    }

    const { type, name, tags, roleAttribute } = parts.val;

    let specifier: ResourceSpecifier;

    switch (type) {
      case 'integration':
        specifier = integration(name, tags, roleAttribute);
        break;
      case 'application':
        specifier = application(name, tags, roleAttribute);
        break;
      case 'domain-verification':
        specifier = domainVerification(name, tags, roleAttribute);
        break;
      case 'member':
        specifier = member(name, tags, roleAttribute);
        if (this.isScopedResource()) {
          return this.parseMemberScopedResourceSpecifier(specifier);
        }

        break;
      case 'proj':
        specifier = project(name, tags, roleAttribute);
        if (this.isScopedResource()) {
          return this.parseProjectScopedResourceSpecifier(specifier);
        }

        break;
      case 'relay-proxy-config':
        specifier = relayProxyConfig(name, tags, roleAttribute);
        break;
      case 'role':
        specifier = role(name, tags, roleAttribute);
        break;
      case 'service-token':
        specifier = serviceToken(name, tags, roleAttribute);
        break;
      case 'team':
        specifier = team(name, tags, roleAttribute);
        break;
      case 'template':
        specifier = template(name, tags, roleAttribute);
        break;
      case 'webhook':
        specifier = webhook(name, tags, roleAttribute);
        break;
      case 'code-reference-repository':
        specifier = repository(name, tags, roleAttribute);
        break;
      case 'pending-request':
        specifier = pendingRequest(name, tags, roleAttribute);
        break;
      default:
        return Err(new ParserError('oops'));
    }

    return Ok(specifier);
  }

  private parseProjectScopedResourceSpecifier(_project: ProjectResource): Result<ResourceSpecifier, ParserError> {
    const parts = this.readResourceIdentifierParts();
    if (parts.err) {
      return parts;
    }

    const { type, name, tags, propertySelectors, roleAttribute } = parts.val;

    switch (type) {
      case 'env':
        const env = environment(_project, name, tags, propertySelectors.critical, roleAttribute);
        if (this.isScopedResource()) {
          return this.parseEnvironmentScopedResourceSpecifier(env);
        }

        return Ok(env);
      case 'aiconfig':
        return Ok(aiConfig(_project, name, tags, roleAttribute));
      case 'ai-model-config':
        return Ok(aiModelConfig(_project, name, tags, roleAttribute));
      case 'payload-filter':
        return Ok(payloadFilter(_project, name, tags, roleAttribute));
      case 'metric':
        return Ok(metric(_project, name, tags, roleAttribute));
      case 'metric-group':
        return Ok(metricGroup(_project, name, tags, roleAttribute));
      case 'goal':
        return Ok(goal(_project, name, tags, roleAttribute));
      case 'release-pipeline':
        return Ok(releasePipeline(_project, name, tags, roleAttribute));
      case 'context-kind':
        return Ok(contextKind(_project, name, tags, roleAttribute));
      case 'layer':
        return Ok(layer(_project, name, tags, roleAttribute));
    }

    return Err(new ParserError(`Unknown project-scoped resource ${type}`));
  }

  private parseEnvironmentScopedResourceSpecifier(
    _environment: EnvironmentResource,
  ): Result<ResourceSpecifier, ParserError> {
    const parts = this.readResourceIdentifierParts();
    if (parts.err) {
      return parts;
    }

    const { type, name, tags, roleAttribute } = parts.val;

    switch (type) {
      case 'flag':
      case 'feature':
        return Ok(flag(_environment, name, tags, roleAttribute));
      case 'segment':
        return Ok(segment(_environment, name, tags, roleAttribute));
      case 'user':
        return Ok(user(_environment, name, tags, roleAttribute));
      case 'holdout':
        return Ok(holdout(_environment, name, tags, roleAttribute));
      case 'experiment':
        return Ok(experiment(_environment, name, tags, roleAttribute));
      case 'destination':
        return Ok(destination(_environment, name, tags, roleAttribute));
    }

    return Err(new ParserError(`Unknown project and environment-scoped resource ${type}`));
  }

  private parseMemberScopedResourceSpecifier(_member: MemberResource): Result<ResourceSpecifier, ParserError> {
    const parts = this.readResourceIdentifierParts();
    if (parts.err) {
      return parts;
    }

    const { type, name, tags, roleAttribute } = parts.val;

    switch (type) {
      case 'token':
        return Ok(token(_member, name, tags, roleAttribute));
    }

    return Err(new ParserError(`Unknown member-scoped resource ${type}`));
  }

  private parseTags(): Result<{ tags: string[]; propertySelectors: PropertySelectors }, ParserError> {
    const tags: string[] = [];
    let propertySelectors: PropertySelectors = {};

    if (this.currentToken.type !== 'tag' && this.currentToken.type !== 'property-selector-start') {
      return Err(new ParserError('token is not of type tag or property-selector-start'));
    }

    while (this.currentToken.type === 'tag' || this.currentToken.type === 'property-selector-start') {
      if (this.currentToken.type === 'tag') {
        tags.push(this.currentToken.text);
      } else {
        const parts = this.parsePropertySelector(structuredClone(propertySelectors));
        if (parts.err) {
          return Err(parts.val);
        }

        propertySelectors = Object.assign(propertySelectors, parts.val.propertySelectors);
      }

      this.nextToken();
    }

    return Ok({ tags, propertySelectors });
  }

  private parsePropertySelector(
    propSelectors: PropertySelectors,
  ): Result<{ propertySelectors: PropertySelectors }, ParserError> {
    if (this.currentToken.type !== 'property-selector-start') {
      return Err(new ParserError('token is not of type property-selector-start'));
    }

    const validPropertyValue: () => boolean = () =>
      this.currentToken.type === 'property-selector-value' &&
      (this.currentToken.text === 'true' || this.currentToken.text === 'false');

    const setPropertyValue: () => void = () => {
      if (propSelectors.critical !== undefined) {
        const c = (propSelectors.critical = Boolean(this.currentToken.text === 'true'));
        propSelectors.critical = propSelectors.critical && c;
      } else {
        propSelectors.critical = Boolean(this.currentToken.text === 'true');
      }
    };

    this.nextToken(); // go to property-selector-key
    switch (this.currentToken.text) {
      case 'critical':
        this.nextToken(); // go to property-selector-value
        if (validPropertyValue()) {
          setPropertyValue();
        } else {
          return Err(new ParserError("property-selector-value for 'critical' should be true or false"));
        }
    }
    this.nextToken(); // go to property-selector-end
    return Ok({ propertySelectors: propSelectors });
  }

  private readResourceIdentifierParts(): Result<
    { type: ResourceType; name: string; tags: string[]; propertySelectors: PropertySelectors; roleAttribute?: string },
    ParserError
  > {
    const type = this.currentToken.text;

    if (!isResourceType(type)) {
      return Err(new ParserError(`Expected valid resource type but got "${type}"`, copy(this.peekToken)));
    }

    if (!this.expectPeek('resource-identifier')) {
      return Err(
        new ParserError(
          `Expected next token to be "resource-identifier" but got "${this.peekToken.type}"`,
          copy(this.peekToken),
        ),
      );
    }

    const name = this.currentToken.text;

    this.nextToken();

    let tags: string[] = [];
    let propertySelectors: PropertySelectors = {};
    const parts = this.parseTags();
    if (parts.err) {
      switch (type) {
        case 'env':
          if (this.currentToken.type === 'tag' || this.currentToken.type === 'property-selector-start') {
            return Err(parts.val);
          }
      }
    } else {
      tags = parts.val.tags;
      propertySelectors = parts.val.propertySelectors;
    }

    const roleAttribute = this.parseRoleAttributeFromName(name);

    return Ok({ type, name, tags, propertySelectors, roleAttribute });
  }

  private parseRoleAttributeFromName(name: string): string | undefined {
    const match = name.match(ROLE_ATTRIBUTE_REGEX);
    if (match) {
      return match[1];
    }

    return;
  }

  private expectPeek(tokenType: Token['type']): boolean {
    if (this.peekToken.type === tokenType) {
      this.nextToken();
      return true;
    }

    return false;
  }

  private isScopedResource() {
    if (this.currentToken.type !== 'scope') {
      return false;
    }

    this.nextToken();

    return true;
  }

  private nextToken() {
    this.currentToken = this.peekToken;
    this.peekToken = this.lexer.nextToken();
  }
}

export function parse(input: string): Result<ResourceSpecifier, ParserError> {
  const lexer = new Lexer(input);
  const parser = new Parser(lexer);
  const ast = parser.parse();
  return ast;
}

/**
 * We make a copy of the current token when saving errors
 * so that we save the token's value from that point in time.
 * (Otherwise, error tokens would all end up point to whatever
 * the current token was.)
 * @param obj
 * @returns a copy of the object
 */
function copy(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
