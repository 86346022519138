import { useSuspenseFlag } from '@gonfalon/rest-api';
import { useFlagKey } from '@gonfalon/router';

import { useProjectContext } from './useProjectContext';

export function useFlagContext() {
  const { context } = useProjectContext();
  const flagKey = useFlagKey();

  const flag = useSuspenseFlag({
    projectKey: context.projectKey,
    flagKey,
    params: {
      env: Array.from(context.environmentKeys),
    },
  });

  return { flag: flag.data };
}
