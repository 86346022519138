import { type JSX } from 'react';
import { Time } from '@gonfalon/datetime';
import type { IconProps } from '@launchpad-ui/icons';
import classNames from 'clsx';
import type { PopoverPlacement } from 'launchpad';
import { Tooltip } from 'launchpad';

import { FlagStatusType, getFlagStatusKind } from 'utils/flagStatusUtils';

import FlagStatusIcon from './FlagStatusIcon';

import styles from './FlagStatus.module.css';

export type FlagStatusProps = {
  status: FlagStatusType;
  temporary: boolean;
  placement?: PopoverPlacement;
  className?: string;
  ariaLabel?: string;
  size?: IconProps['size'];
};

type StatusTooltipsInterface = {
  [statusName: string]: (temporary: boolean) => JSX.Element;
};

type StatusSummaryInterface = {
  [statusName: string]: (lastRequested: string) => JSX.Element;
};

export const statusTooltips: StatusTooltipsInterface = {
  active: () => <span>Contexts are receiving variations of this flag</span>,
  inactive: (temporary: boolean) => (
    <span>
      This flag hasn’t been evaluated in the past 7 days.
      {temporary && <div className="u-mt-m">If you have removed it from your code, you may archive it.</div>}
    </span>
  ),
  launched: (temporary: boolean) => (
    <span>
      All contexts are receiving one variation of this flag.
      {temporary && <div className="u-mt-m">You may be able to remove it from your code.</div>}
    </span>
  ),
  new: () => <span>This flag is new, and hasn’t been evaluated yet</span>,
};

export const statusSummary: StatusSummaryInterface = {
  active: (lastRequested: string) => (
    <Time className={styles.flagStatusSummaryText} fromNow datetime={lastRequested}>
      {(formatted) => `Evaluated ${formatted}`}
    </Time>
  ),
  inactive: () => <span className={styles.flagStatusSummaryText}>Evaluated over 7 days ago</span>,
  launched: (lastRequested: string) => (
    <Time className={styles.flagStatusSummaryText} fromNow datetime={lastRequested}>
      {(formatted) => `Evaluated ${formatted}`}
    </Time>
  ),
  new: () => <span className={styles.flagStatusSummaryText}>Yet to be evaluated</span>,
};

/* eslint-disable import/no-default-export */
export default function FlagStatus({
  status,
  temporary,
  className,
  placement = 'bottom-start',
  ariaLabel,
  size,
}: FlagStatusProps) {
  const classes = classNames(styles.flagStatus, className);
  const validFlagStatus = getFlagStatusKind(status.name);

  return validFlagStatus !== undefined ? (
    <Tooltip placement={placement} content={statusTooltips[status.name](temporary)}>
      <span className={classes} role="button" tabIndex={0} aria-label={ariaLabel ?? 'Flag status'}>
        <FlagStatusIcon size={size} status={validFlagStatus} />
      </span>
    </Tooltip>
  ) : null;
}
