import { useState } from 'react';
import { JSONPatch } from '@gonfalon/rest-api';
import {
  Alert,
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
} from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';

import { CustomRole, Member, Team } from '../internal/types';

import { AssignAccessForm } from './AssignAccessForm';

type BaseProps = {
  createJsonPatch: <T>(
    prev: T,
    next: T,
  ) =>
    | JSONPatch
    | {
        comment: string;
        patch: JSONPatch;
      };
  trackOpen?: () => void;
  forResource: 'member' | 'team';
  member?: Member;
  team?: Team;
  role?: CustomRole;
  edit?: boolean;
  externallyControlledModalState?: {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
  };
};

type AssignAccessToMemberProps = BaseProps & {
  forResource: 'member';
  member: Member;
};

type EditAccessToMemberProps = BaseProps & {
  forResource: 'member';
  member: Member;
  edit: true;
  role: CustomRole;
};

type AssignAccessToTeamProps = BaseProps & {
  forResource: 'team';
  team: Team;
};

type EditAccessToTeamProps = BaseProps & {
  forResource: 'team';
  team: Team;
  edit: true;
  role: CustomRole;
};

export type AssignAccessModalProps =
  | AssignAccessToMemberProps
  | AssignAccessToTeamProps
  | EditAccessToMemberProps
  | EditAccessToTeamProps;

export const AssignAccessModal = ({
  forResource,
  member,
  team,
  role,
  createJsonPatch,
  trackOpen,
  externallyControlledModalState,
}: AssignAccessModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isForMember = forResource === 'member';

  const handleOpenChange = (nextIsOpen: boolean) => {
    if (externallyControlledModalState) {
      // externally controlled open state
      if (nextIsOpen) {
        externallyControlledModalState.setIsModalOpen(true);
        trackOpen?.();
        return;
      }
      externallyControlledModalState.setIsModalOpen(false);
      return;
    }

    // internally controlled open state
    if (nextIsOpen) {
      setIsOpen(true);
      trackOpen?.();
      return;
    }
    setIsOpen(false);
  };

  const modalContent = (
    <Dialog>
      <>
        <div slot="header">
          <Heading slot="title">Assign access</Heading>
          <IconButton
            aria-label="close modal"
            icon="cancel"
            variant="minimal"
            onPress={() => handleOpenChange(false)}
          />
        </div>
        <Box slot="body" display="grid" gap="$500">
          <p>
            Select a project role and corresponding resource(s) where appropriate, that the selected role will provide
            access to:
          </p>

          {isForMember && (
            <Alert status="info" isDismissable>
              To give more than 1 member access to the same set of resources, we recommend assigning access from the
              Teams page.
            </Alert>
          )}

          <>
            {isForMember ? (
              <AssignAccessForm
                forResource="member"
                createJsonPatch={createJsonPatch}
                closeModal={() => handleOpenChange(false)}
                member={member}
                role={role}
              />
            ) : (
              <AssignAccessForm
                forResource="team"
                createJsonPatch={createJsonPatch}
                closeModal={() => handleOpenChange(false)}
                team={team}
                role={role}
              />
            )}
          </>
        </Box>
      </>
    </Dialog>
  );

  if (externallyControlledModalState) {
    // renders without dialog trigger
    return (
      <ModalOverlay isOpen={externallyControlledModalState.isModalOpen} onOpenChange={handleOpenChange}>
        <Modal>{modalContent}</Modal>
      </ModalOverlay>
    );
  }

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Button variant="primary">Assign access</Button>
      <ModalOverlay>
        <Modal>{modalContent}</Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
