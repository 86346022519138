import { Path } from 'react-router';

import { matchAppRoute } from './internal';

export function matchApprovalDashboard(path: Path) {
  return matchAppRoute('/projects/:projectKey/approvals', path);
}

export function matchApprovalDetails(path: Path) {
  return matchAppRoute('/projects/:projectKey/approvals/:approvalRequestId', path);
}

export function matchFlagApproval(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId', path);
}

export function matchFlagTargetingScheduledChanges(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes', path);
}

export function matchFlagTargetingScheduledChange(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId', path);
}

export function matchFlagVariationsScheduledChanges(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/variations/pending-changes', path);
}

export function matchFlagVariationScheduledChange(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId', path);
}

export function matchFlagTargetingConflict(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex', path);
}

export function matchFlagVariationConflict(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex', path);
}

export function matchExperimentDesign(path: Path) {
  return matchAppRoute('/projects/:projectKey/experiments/:experimentKey/design', path);
}

export function matchWorkflows(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/workflows', path);
}

export function matchWorkflow(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/workflows/:workflowId', path);
}

export function matchFlags(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags', path);
}

export function matchFlag(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey', path);
}

export function matchFlagTargeting(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/targeting', path);
}

export function matchContexts(path: Path) {
  return matchAppRoute('/projects/:projectKey/contexts', path);
}

export function matchContext(path: Path) {
  return matchAppRoute('/projects/:projectKey/contexts', path);
}

export function matchContextDetails(path: Path) {
  return matchAppRoute('/projects/:projectKey/contexts/kinds/:kind/instances/:key', path);
}

export function matchSegments(path: Path) {
  return matchAppRoute('/projects/:projectKey/segments', path);
}

export function matchExperiments(path: Path) {
  return matchAppRoute('/projects/:projectKey/experiments', path);
}

export function matchLive(path: Path) {
  return matchAppRoute('/projects/:projectKey/live', path);
}

export function matchMetrics(path: Path) {
  return matchAppRoute('/projects/:projectKey/metrics/:metricKey/details', path);
}

export function matchAccountHistory(path: Path) {
  return matchAppRoute('/settings/history/*', path);
}

export function matchNewHires(path: Path) {
  return matchAppRoute('/internal/new-hires', path);
}

export function matchFlagCreation(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/new', path);
}

export function matchGetStarted(path: Path) {
  return matchAppRoute('/projects/:projectKey/get-started', path);
}

export function matchQuickstart(path: Path) {
  return matchAppRoute('/projects/:projectKey/get-started/quickstart', path);
}

export function matchSetupCLI(path: Path) {
  return matchAppRoute('/projects/:projectKey/get-started/setup-cli', path);
}

export function matchInviteMembers(path: Path) {
  return matchAppRoute('/projects/:projectKey/get-started/invite-members', path);
}

export function matchCreateReleasePipeline(path: Path) {
  return matchAppRoute('/projects/:projectKey/settings/release-pipelines/new', path);
}

export function matchEditReleasePipeline(path: Path) {
  return matchAppRoute('/projects/:projectKey/settings/release-pipelines/:releasePipelineKey', path);
}

export function matchPayloadFilterEdit(path: Path) {
  return matchAppRoute('/projects/:projectKey/settings/payload-filters/:filterKey/edit', path);
}

export function matchFlagTargetingEventConfiguration(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/environments/exclude-rules', path);
}

export function matchFlagEnvironmentSettingsEventConfiguration(path: Path) {
  return matchAppRoute('/projects/:projectKey/flags/:flagKey/targeting/exclude-rules', path);
}

export function matchAIConfigVersions(path: Path) {
  return matchAppRoute('/projects/:projectKey/ai-configs/:configKey/variations', path);
}

export function matchAIConfigPlayground(path: Path) {
  return matchAppRoute('/projects/:projectKey/ai-configs/:configKey/playground', path);
}

export function matchAIConfigMonitoring(path: Path) {
  return matchAppRoute('/projects/:projectKey/ai-configs/:configKey/monitoring', path);
}

export function matchAITargeting(path: Path) {
  return matchAppRoute('/projects/:projectKey/ai-configs/:configKey/targeting', path);
}

export function matchEventExplorerExpand(path: Path) {
  return matchAppRoute('/projects/:projectKey/observe/expand/:eventKind/:eventKey', path);
}
