import { experimentValueThreshold } from '@gonfalon/dogfood-flags';
import { formatNumber } from '@gonfalon/format';

export function formatExperimentValue(
  value: number | undefined,
  options: Intl.NumberFormatOptions & { digits?: number } = {},
): string {
  if (value === undefined) {
    return 'N/A';
  }

  // handle very small numbers
  const threshold = experimentValueThreshold();
  if (value !== 0 && value < threshold) {
    return options?.style === 'percent' ? `<${threshold * 100}%` : `<${threshold}`;
  }

  // handle very big numbers
  if (value > 999999999) {
    return value.toExponential(options?.maximumFractionDigits ?? 4);
  }

  const digits = options?.digits ?? (options?.style === 'percent' ? 2 : 4);

  return formatNumber(value, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    ...options,
  });
}
