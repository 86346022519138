/* eslint-disable */
/**
 * This file was automatically generated by scripts/routes-gen.ts.
 * DO NOT MODIFY IT BY HAND. Instead, modify the routes under static/ld/routes,
 * and run yarn routes:gen to regenerate this file.
 *
 * If you have any questions, reach out to #ask-frontend.
 *
 */

import { generatePath, Params } from 'react-router';

/**
 * This type defines the standard squad names used to assign ownership.
 *
 */
export type Squad = 'decision-science' | 'release-fe';

/**
 * Declares a union of all the routes defined in ../../../static/ld/routes/unauthenticatedRoutes.tsx.
 *
 */
export type UnauthenticatedRoute =
  | '/*'
  | '/check-your-email'
  | '/choose-organization/:email'
  | '/choose-organization/:email/:selectedOrganization/enter-password'
  | '/choose-organization/:selectedOrganization/forgot'
  | '/choose-organization/check-your-email'
  | '/choose-organization/verify-email/:email'
  | '/choose-organization/verify/:token'
  | '/forgot'
  | '/invite/:invitationToken'
  | '/join'
  | '/join-your-organization/:token'
  | '/login'
  | '/oauth/github'
  | '/oauth/google'
  | '/reset/:passwordResetToken'
  | '/signup'
  | '/signup/:token'
  | '/trial-limit'
  | '/verify-email/:email'
  | '/welcome/:token';

/**
 * Declares a mapping of routes to path params for all the routes defined in ../../../static/ld/routes/unauthenticatedRoutes.tsx.
 *
 */
export type UnauthenticatedPathParams = {
  '/*': { '*'?: string };
  '/check-your-email': {};
  '/choose-organization/:email': { email: string };
  '/choose-organization/:email/:selectedOrganization/enter-password': { email: string; selectedOrganization: string };
  '/choose-organization/:selectedOrganization/forgot': { selectedOrganization: string };
  '/choose-organization/check-your-email': {};
  '/choose-organization/verify-email/:email': { email: string };
  '/choose-organization/verify/:token': { token: string };
  '/forgot': {};
  '/invite/:invitationToken': { invitationToken: string };
  '/join': {};
  '/join-your-organization/:token': { token: string };
  '/login': {};
  '/oauth/github': {};
  '/oauth/google': {};
  '/reset/:passwordResetToken': { passwordResetToken: string };
  '/signup': {};
  '/signup/:token': { token: string };
  '/trial-limit': {};
  '/verify-email/:email': { email: string };
  '/welcome/:token': { token: string };
};

/**
 * Declares an overloaded function for each client-side route.
 *
 */
export function unauthenticatedRoute(path: '/*', params: { '*'?: string }): string;
export function unauthenticatedRoute(path: '/check-your-email'): string;
export function unauthenticatedRoute(path: '/choose-organization/:email', params: { email: string }): string;
export function unauthenticatedRoute(
  path: '/choose-organization/:email/:selectedOrganization/enter-password',
  params: { email: string; selectedOrganization: string },
): string;
export function unauthenticatedRoute(
  path: '/choose-organization/:selectedOrganization/forgot',
  params: { selectedOrganization: string },
): string;
export function unauthenticatedRoute(path: '/choose-organization/check-your-email'): string;
export function unauthenticatedRoute(
  path: '/choose-organization/verify-email/:email',
  params: { email: string },
): string;
export function unauthenticatedRoute(path: '/choose-organization/verify/:token', params: { token: string }): string;
export function unauthenticatedRoute(path: '/forgot'): string;
export function unauthenticatedRoute(path: '/invite/:invitationToken', params: { invitationToken: string }): string;
export function unauthenticatedRoute(path: '/join'): string;
export function unauthenticatedRoute(path: '/join-your-organization/:token', params: { token: string }): string;
export function unauthenticatedRoute(path: '/login'): string;
export function unauthenticatedRoute(path: '/oauth/github'): string;
export function unauthenticatedRoute(path: '/oauth/google'): string;
export function unauthenticatedRoute(
  path: '/reset/:passwordResetToken',
  params: { passwordResetToken: string },
): string;
export function unauthenticatedRoute(path: '/signup'): string;
export function unauthenticatedRoute(path: '/signup/:token', params: { token: string }): string;
export function unauthenticatedRoute(path: '/trial-limit'): string;
export function unauthenticatedRoute(path: '/verify-email/:email', params: { email: string }): string;
export function unauthenticatedRoute(path: '/welcome/:token', params: { token: string }): string;
export function unauthenticatedRoute(path: string, params?: Params<string>) {
  return generatePath(path, params);
}

/**
 * Defines the ownership for all the routes defined in ../../../static/ld/routes/unauthenticatedRoutes.tsx.
 *
 */
export type UnauthenticatedRouteOwnership = {
  '/*': Squad;
  '/check-your-email': Squad;
  '/choose-organization/:email': Squad;
  '/choose-organization/:email/:selectedOrganization/enter-password': Squad;
  '/choose-organization/:selectedOrganization/forgot': Squad;
  '/choose-organization/check-your-email': Squad;
  '/choose-organization/verify-email/:email': Squad;
  '/choose-organization/verify/:token': Squad;
  '/forgot': Squad;
  '/invite/:invitationToken': Squad;
  '/join': Squad;
  '/join-your-organization/:token': Squad;
  '/login': Squad;
  '/oauth/github': Squad;
  '/oauth/google': Squad;
  '/reset/:passwordResetToken': Squad;
  '/signup': Squad;
  '/signup/:token': Squad;
  '/trial-limit': Squad;
  '/verify-email/:email': Squad;
  '/welcome/:token': Squad;
};

/**
 * Declares a union of all the routes defined in ../../../static/ld/routes/appRoutes.tsx.
 *
 */
export type AppRoute =
  | '/'
  | '/*'
  | '/confirm-auth/:deviceCode'
  | '/insights'
  | '/internal/new-hires'
  | '/profile'
  | '/projects/:projectKey'
  | '/projects/:projectKey/ai-configs'
  | '/projects/:projectKey/ai-configs/:configKey'
  | '/projects/:projectKey/ai-configs/:configKey/monitoring'
  | '/projects/:projectKey/ai-configs/:configKey/playground'
  | '/projects/:projectKey/ai-configs/:configKey/targeting'
  | '/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes'
  | '/projects/:projectKey/ai-configs/:configKey/variations'
  | '/projects/:projectKey/ai-configs/:configKey/variations/:variationKey'
  | '/projects/:projectKey/ai-configs/new'
  | '/projects/:projectKey/approvals'
  | '/projects/:projectKey/approvals/:approvalRequestId'
  | '/projects/:projectKey/audit'
  | '/projects/:projectKey/audit/:auditId/*'
  | '/projects/:projectKey/compare'
  | '/projects/:projectKey/compare/:environmentPair'
  | '/projects/:projectKey/compare/:environmentPair/flags/:flagKey'
  | '/projects/:projectKey/contexts'
  | '/projects/:projectKey/contexts/instances/:instanceId'
  | '/projects/:projectKey/contexts/kinds'
  | '/projects/:projectKey/contexts/kinds/:kind/instances/:key'
  | '/projects/:projectKey/errors'
  | '/projects/:projectKey/errors/:issueId'
  | '/projects/:projectKey/errors/:issueId/json'
  | '/projects/:projectKey/experiments'
  | '/projects/:projectKey/experiments/:experimentId/details'
  | '/projects/:projectKey/experiments/:experimentKey'
  | '/projects/:projectKey/experiments/:experimentKey/design'
  | '/projects/:projectKey/experiments/:experimentKey/events'
  | '/projects/:projectKey/experiments/:experimentKey/iterations'
  | '/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId'
  | '/projects/:projectKey/experiments/:experimentKey/results'
  | '/projects/:projectKey/experiments/:experimentKey/traffic'
  | '/projects/:projectKey/experiments/create'
  | '/projects/:projectKey/flags'
  | '/projects/:projectKey/flags/:flagKey'
  | '/projects/:projectKey/flags/:flagKey/add-to-code'
  | '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId'
  | '/projects/:projectKey/flags/:flagKey/code-refs'
  | '/projects/:projectKey/flags/:flagKey/environment-overview'
  | '/projects/:projectKey/flags/:flagKey/environments'
  | '/projects/:projectKey/flags/:flagKey/environments/exclude-rules'
  | '/projects/:projectKey/flags/:flagKey/experiments/*'
  | '/projects/:projectKey/flags/:flagKey/history'
  | '/projects/:projectKey/flags/:flagKey/history/:auditId/*'
  | '/projects/:projectKey/flags/:flagKey/insights'
  | '/projects/:projectKey/flags/:flagKey/links'
  | '/projects/:projectKey/flags/:flagKey/monitoring'
  | '/projects/:projectKey/flags/:flagKey/settings'
  | '/projects/:projectKey/flags/:flagKey/settings/archive'
  | '/projects/:projectKey/flags/:flagKey/settings/deprecate'
  | '/projects/:projectKey/flags/:flagKey/targeting'
  | '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex'
  | '/projects/:projectKey/flags/:flagKey/targeting/exclude-rules'
  | '/projects/:projectKey/flags/:flagKey/targeting/pending-changes'
  | '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId'
  | '/projects/:projectKey/flags/:flagKey/variations'
  | '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex'
  | '/projects/:projectKey/flags/:flagKey/variations/pending-changes'
  | '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId'
  | '/projects/:projectKey/flags/:flagKey/workflows'
  | '/projects/:projectKey/flags/:flagKey/workflows/:workflowId'
  | '/projects/:projectKey/flags/:flagKey/workflows/new'
  | '/projects/:projectKey/flags/connect'
  | '/projects/:projectKey/flags/health'
  | '/projects/:projectKey/flags/new'
  | '/projects/:projectKey/get-started'
  | '/projects/:projectKey/get-started/invite-members'
  | '/projects/:projectKey/get-started/quickstart'
  | '/projects/:projectKey/get-started/setup-cli'
  | '/projects/:projectKey/guarded-releases'
  | '/projects/:projectKey/holdouts'
  | '/projects/:projectKey/holdouts/:holdoutKey'
  | '/projects/:projectKey/holdouts/:holdoutKey/details'
  | '/projects/:projectKey/holdouts/create'
  | '/projects/:projectKey/install-sdk'
  | '/projects/:projectKey/live'
  | '/projects/:projectKey/live/flags'
  | '/projects/:projectKey/live/flags/:flagKey'
  | '/projects/:projectKey/metrics'
  | '/projects/:projectKey/metrics/:metricKey/details'
  | '/projects/:projectKey/metrics/:metricKey/impact'
  | '/projects/:projectKey/metrics/analytics'
  | '/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId'
  | '/projects/:projectKey/metrics/discover'
  | '/projects/:projectKey/metrics/groups/:metricGroupKey/details'
  | '/projects/:projectKey/metrics/new'
  | '/projects/:projectKey/observe'
  | '/projects/:projectKey/observe/expand/:eventKind/:eventKey'
  | '/projects/:projectKey/observe/new-metric'
  | '/projects/:projectKey/onboarding'
  | '/projects/:projectKey/releases/:releasePipelineKey'
  | '/projects/:projectKey/segments'
  | '/projects/:projectKey/segments/:segmentKey'
  | '/projects/:projectKey/segments/:segmentKey/flags'
  | '/projects/:projectKey/segments/:segmentKey/history/*'
  | '/projects/:projectKey/segments/:segmentKey/history/:auditId/*'
  | '/projects/:projectKey/segments/:segmentKey/overview'
  | '/projects/:projectKey/segments/:segmentKey/settings'
  | '/projects/:projectKey/segments/:segmentKey/targeting'
  | '/projects/:projectKey/segments/:segmentKey/targeting/exports/:exportKey'
  | '/projects/:projectKey/segments/new'
  | '/projects/:projectKey/sessions'
  | '/projects/:projectKey/sessions/:sessionId/*'
  | '/projects/:projectKey/settings'
  | '/projects/:projectKey/settings/context-kinds'
  | '/projects/:projectKey/settings/environments'
  | '/projects/:projectKey/settings/environments/:envKey/approval-settings'
  | '/projects/:projectKey/settings/environments/:envKey/edit'
  | '/projects/:projectKey/settings/environments/:envKey/keys'
  | '/projects/:projectKey/settings/environments/new'
  | '/projects/:projectKey/settings/flags'
  | '/projects/:projectKey/settings/model-configs'
  | '/projects/:projectKey/settings/model-configs/:configKey'
  | '/projects/:projectKey/settings/payload-filters'
  | '/projects/:projectKey/settings/payload-filters/:filterKey/edit'
  | '/projects/:projectKey/settings/release-pipelines'
  | '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey'
  | '/projects/:projectKey/settings/release-pipelines/new'
  | '/settings'
  | '/settings/applications'
  | '/settings/applications/:applicationKey'
  | '/settings/applications/:applicationKey/edit'
  | '/settings/applications/:applicationKey/history/*'
  | '/settings/applications/:applicationKey/versions'
  | '/settings/applications/:applicationKey/versions/:versionKey/support-status'
  | '/settings/applications/:applicationKey/versions/new'
  | '/settings/applications/new'
  | '/settings/authorization'
  | '/settings/authorization/oauth/:appId/review'
  | '/settings/authorization/roles/:key'
  | '/settings/authorization/tokens/:tokenId/clone'
  | '/settings/authorization/tokens/:tokenId/edit'
  | '/settings/authorization/tokens/:tokenId/reset'
  | '/settings/authorization/tokens/new'
  | '/settings/billing'
  | '/settings/billing/plans'
  | '/settings/billing/plans/review'
  | '/settings/escalate'
  | '/settings/general'
  | '/settings/history/*'
  | '/settings/integrations'
  | '/settings/integrations/:kind/:subscriptionId/edit'
  | '/settings/integrations/:kind/:subscriptionId/errors/view'
  | '/settings/integrations/:kind/new'
  | '/settings/integrations/:kind/show'
  | '/settings/integrations/coderefs/:id/edit'
  | '/settings/integrations/destinations/:destinationId/edit'
  | '/settings/integrations/destinations/new'
  | '/settings/integrations/sentry/show'
  | '/settings/integrations/slack/:slackHookId/edit'
  | '/settings/integrations/slack/new'
  | '/settings/integrations/webhooks/:webhookId/edit'
  | '/settings/integrations/webhooks/new'
  | '/settings/members'
  | '/settings/members/:memberId'
  | '/settings/members/:memberId/history/*'
  | '/settings/members/:memberId/permissions'
  | '/settings/members/:memberId/settings'
  | '/settings/members/:memberId/teams'
  | '/settings/members/invite/:inviteEmail?'
  | '/settings/profile'
  | '/settings/projects'
  | '/settings/relay'
  | '/settings/relay/:id/edit'
  | '/settings/relay/new'
  | '/settings/roles'
  | '/settings/roles/:key/edit'
  | '/settings/roles/:key/view'
  | '/settings/roles/new'
  | '/settings/roles/organization'
  | '/settings/roles/project'
  | '/settings/security'
  | '/settings/security/saml/config'
  | '/settings/teams'
  | '/settings/teams/:teamKey'
  | '/settings/teams/:teamKey/history/*'
  | '/settings/teams/:teamKey/members'
  | '/settings/teams/:teamKey/permissions'
  | '/settings/teams/:teamKey/settings'
  | '/settings/tokens/*'
  | '/settings/usage'
  | '/settings/usage/details/client'
  | '/settings/usage/details/data-export'
  | '/settings/usage/details/experiments'
  | '/settings/usage/details/host'
  | '/settings/usage/details/overview'
  | '/settings/usage/details/server'
  | '/verify-email';

/**
 * Declares a mapping of routes to path params for all the routes defined in ../../../static/ld/routes/appRoutes.tsx.
 *
 */
export type AppPathParams = {
  '/': {};
  '/*': { '*'?: string };
  '/confirm-auth/:deviceCode': { deviceCode: string };
  '/insights': {};
  '/internal/new-hires': {};
  '/profile': {};
  '/projects/:projectKey': { projectKey: string };
  '/projects/:projectKey/ai-configs': { projectKey: string };
  '/projects/:projectKey/ai-configs/:configKey': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/monitoring': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/playground': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/targeting': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/variations': { projectKey: string; configKey: string };
  '/projects/:projectKey/ai-configs/:configKey/variations/:variationKey': {
    projectKey: string;
    configKey: string;
    variationKey: string;
  };
  '/projects/:projectKey/ai-configs/new': { projectKey: string };
  '/projects/:projectKey/approvals': { projectKey: string };
  '/projects/:projectKey/approvals/:approvalRequestId': { projectKey: string; approvalRequestId: string };
  '/projects/:projectKey/audit': { projectKey: string };
  '/projects/:projectKey/audit/:auditId/*': { projectKey: string; auditId: string; '*'?: string };
  '/projects/:projectKey/compare': { projectKey: string };
  '/projects/:projectKey/compare/:environmentPair': { projectKey: string; environmentPair: string };
  '/projects/:projectKey/compare/:environmentPair/flags/:flagKey': {
    projectKey: string;
    environmentPair: string;
    flagKey: string;
  };
  '/projects/:projectKey/contexts': { projectKey: string };
  '/projects/:projectKey/contexts/instances/:instanceId': { projectKey: string; instanceId: string };
  '/projects/:projectKey/contexts/kinds': { projectKey: string };
  '/projects/:projectKey/contexts/kinds/:kind/instances/:key': { projectKey: string; kind: string; key: string };
  '/projects/:projectKey/errors': { projectKey: string };
  '/projects/:projectKey/errors/:issueId': { projectKey: string; issueId: string };
  '/projects/:projectKey/errors/:issueId/json': { projectKey: string; issueId: string };
  '/projects/:projectKey/experiments': { projectKey: string };
  '/projects/:projectKey/experiments/:experimentId/details': { projectKey: string; experimentId: string };
  '/projects/:projectKey/experiments/:experimentKey': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/:experimentKey/design': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/:experimentKey/events': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/:experimentKey/iterations': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId': {
    projectKey: string;
    experimentKey: string;
    iterationId: string;
  };
  '/projects/:projectKey/experiments/:experimentKey/results': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/:experimentKey/traffic': { projectKey: string; experimentKey: string };
  '/projects/:projectKey/experiments/create': { projectKey: string };
  '/projects/:projectKey/flags': { projectKey: string };
  '/projects/:projectKey/flags/:flagKey': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/add-to-code': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId': {
    projectKey: string;
    flagKey: string;
    approvalRequestId: string;
  };
  '/projects/:projectKey/flags/:flagKey/code-refs': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/environment-overview': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/environments': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/environments/exclude-rules': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/experiments/*': { projectKey: string; flagKey: string; '*'?: string };
  '/projects/:projectKey/flags/:flagKey/history': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/history/:auditId/*': {
    projectKey: string;
    flagKey: string;
    auditId: string;
    '*'?: string;
  };
  '/projects/:projectKey/flags/:flagKey/insights': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/links': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/monitoring': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/settings': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/settings/archive': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/settings/deprecate': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/targeting': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex': {
    projectKey: string;
    flagKey: string;
    conflictIndex: string;
  };
  '/projects/:projectKey/flags/:flagKey/targeting/exclude-rules': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId': {
    projectKey: string;
    flagKey: string;
    pendingId: string;
  };
  '/projects/:projectKey/flags/:flagKey/variations': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex': {
    projectKey: string;
    flagKey: string;
    conflictIndex: string;
  };
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId': {
    projectKey: string;
    flagKey: string;
    pendingId: string;
  };
  '/projects/:projectKey/flags/:flagKey/workflows': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/:flagKey/workflows/:workflowId': {
    projectKey: string;
    flagKey: string;
    workflowId: string;
  };
  '/projects/:projectKey/flags/:flagKey/workflows/new': { projectKey: string; flagKey: string };
  '/projects/:projectKey/flags/connect': { projectKey: string };
  '/projects/:projectKey/flags/health': { projectKey: string };
  '/projects/:projectKey/flags/new': { projectKey: string };
  '/projects/:projectKey/get-started': { projectKey: string };
  '/projects/:projectKey/get-started/invite-members': { projectKey: string };
  '/projects/:projectKey/get-started/quickstart': { projectKey: string };
  '/projects/:projectKey/get-started/setup-cli': { projectKey: string };
  '/projects/:projectKey/guarded-releases': { projectKey: string };
  '/projects/:projectKey/holdouts': { projectKey: string };
  '/projects/:projectKey/holdouts/:holdoutKey': { projectKey: string; holdoutKey: string };
  '/projects/:projectKey/holdouts/:holdoutKey/details': { projectKey: string; holdoutKey: string };
  '/projects/:projectKey/holdouts/create': { projectKey: string };
  '/projects/:projectKey/install-sdk': { projectKey: string };
  '/projects/:projectKey/live': { projectKey: string };
  '/projects/:projectKey/live/flags': { projectKey: string };
  '/projects/:projectKey/live/flags/:flagKey': { projectKey: string; flagKey: string };
  '/projects/:projectKey/metrics': { projectKey: string };
  '/projects/:projectKey/metrics/:metricKey/details': { projectKey: string; metricKey: string };
  '/projects/:projectKey/metrics/:metricKey/impact': { projectKey: string; metricKey: string };
  '/projects/:projectKey/metrics/analytics': { projectKey: string };
  '/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId': {
    projectKey: string;
    analyticsQueryResultId: string;
  };
  '/projects/:projectKey/metrics/discover': { projectKey: string };
  '/projects/:projectKey/metrics/groups/:metricGroupKey/details': { projectKey: string; metricGroupKey: string };
  '/projects/:projectKey/metrics/new': { projectKey: string };
  '/projects/:projectKey/observe': { projectKey: string };
  '/projects/:projectKey/observe/expand/:eventKind/:eventKey': {
    projectKey: string;
    eventKind: string;
    eventKey: string;
  };
  '/projects/:projectKey/observe/new-metric': { projectKey: string };
  '/projects/:projectKey/onboarding': { projectKey: string };
  '/projects/:projectKey/releases/:releasePipelineKey': { projectKey: string; releasePipelineKey: string };
  '/projects/:projectKey/segments': { projectKey: string };
  '/projects/:projectKey/segments/:segmentKey': { projectKey: string; segmentKey: string };
  '/projects/:projectKey/segments/:segmentKey/flags': { projectKey: string; segmentKey: string };
  '/projects/:projectKey/segments/:segmentKey/history/*': { projectKey: string; segmentKey: string; '*'?: string };
  '/projects/:projectKey/segments/:segmentKey/history/:auditId/*': {
    projectKey: string;
    segmentKey: string;
    auditId: string;
    '*'?: string;
  };
  '/projects/:projectKey/segments/:segmentKey/overview': { projectKey: string; segmentKey: string };
  '/projects/:projectKey/segments/:segmentKey/settings': { projectKey: string; segmentKey: string };
  '/projects/:projectKey/segments/:segmentKey/targeting': { projectKey: string; segmentKey: string };
  '/projects/:projectKey/segments/:segmentKey/targeting/exports/:exportKey': {
    projectKey: string;
    segmentKey: string;
    exportKey: string;
  };
  '/projects/:projectKey/segments/new': { projectKey: string };
  '/projects/:projectKey/sessions': { projectKey: string };
  '/projects/:projectKey/sessions/:sessionId/*': { projectKey: string; sessionId: string; '*'?: string };
  '/projects/:projectKey/settings': { projectKey: string };
  '/projects/:projectKey/settings/context-kinds': { projectKey: string };
  '/projects/:projectKey/settings/environments': { projectKey: string };
  '/projects/:projectKey/settings/environments/:envKey/approval-settings': { projectKey: string; envKey: string };
  '/projects/:projectKey/settings/environments/:envKey/edit': { projectKey: string; envKey: string };
  '/projects/:projectKey/settings/environments/:envKey/keys': { projectKey: string; envKey: string };
  '/projects/:projectKey/settings/environments/new': { projectKey: string };
  '/projects/:projectKey/settings/flags': { projectKey: string };
  '/projects/:projectKey/settings/model-configs': { projectKey: string };
  '/projects/:projectKey/settings/model-configs/:configKey': { projectKey: string; configKey: string };
  '/projects/:projectKey/settings/payload-filters': { projectKey: string };
  '/projects/:projectKey/settings/payload-filters/:filterKey/edit': { projectKey: string; filterKey: string };
  '/projects/:projectKey/settings/release-pipelines': { projectKey: string };
  '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey': {
    projectKey: string;
    releasePipelineKey: string;
  };
  '/projects/:projectKey/settings/release-pipelines/new': { projectKey: string };
  '/settings': {};
  '/settings/applications': {};
  '/settings/applications/:applicationKey': { applicationKey: string };
  '/settings/applications/:applicationKey/edit': { applicationKey: string };
  '/settings/applications/:applicationKey/history/*': { applicationKey: string; '*'?: string };
  '/settings/applications/:applicationKey/versions': { applicationKey: string };
  '/settings/applications/:applicationKey/versions/:versionKey/support-status': {
    applicationKey: string;
    versionKey: string;
  };
  '/settings/applications/:applicationKey/versions/new': { applicationKey: string };
  '/settings/applications/new': {};
  '/settings/authorization': {};
  '/settings/authorization/oauth/:appId/review': { appId: string };
  '/settings/authorization/roles/:key': { key: string };
  '/settings/authorization/tokens/:tokenId/clone': { tokenId: string };
  '/settings/authorization/tokens/:tokenId/edit': { tokenId: string };
  '/settings/authorization/tokens/:tokenId/reset': { tokenId: string };
  '/settings/authorization/tokens/new': {};
  '/settings/billing': {};
  '/settings/billing/plans': {};
  '/settings/billing/plans/review': {};
  '/settings/escalate': {};
  '/settings/general': {};
  '/settings/history/*': { '*'?: string };
  '/settings/integrations': {};
  '/settings/integrations/:kind/:subscriptionId/edit': { kind: string; subscriptionId: string };
  '/settings/integrations/:kind/:subscriptionId/errors/view': { kind: string; subscriptionId: string };
  '/settings/integrations/:kind/new': { kind: string };
  '/settings/integrations/:kind/show': { kind: string };
  '/settings/integrations/coderefs/:id/edit': { id: string };
  '/settings/integrations/destinations/:destinationId/edit': { destinationId: string };
  '/settings/integrations/destinations/new': {};
  '/settings/integrations/sentry/show': {};
  '/settings/integrations/slack/:slackHookId/edit': { slackHookId: string };
  '/settings/integrations/slack/new': {};
  '/settings/integrations/webhooks/:webhookId/edit': { webhookId: string };
  '/settings/integrations/webhooks/new': {};
  '/settings/members': {};
  '/settings/members/:memberId': { memberId: string };
  '/settings/members/:memberId/history/*': { memberId: string; '*'?: string };
  '/settings/members/:memberId/permissions': { memberId: string };
  '/settings/members/:memberId/settings': { memberId: string };
  '/settings/members/:memberId/teams': { memberId: string };
  '/settings/members/invite/:inviteEmail?': { inviteEmail: string };
  '/settings/profile': {};
  '/settings/projects': {};
  '/settings/relay': {};
  '/settings/relay/:id/edit': { id: string };
  '/settings/relay/new': {};
  '/settings/roles': {};
  '/settings/roles/:key/edit': { key: string };
  '/settings/roles/:key/view': { key: string };
  '/settings/roles/new': {};
  '/settings/roles/organization': {};
  '/settings/roles/project': {};
  '/settings/security': {};
  '/settings/security/saml/config': {};
  '/settings/teams': {};
  '/settings/teams/:teamKey': { teamKey: string };
  '/settings/teams/:teamKey/history/*': { teamKey: string; '*'?: string };
  '/settings/teams/:teamKey/members': { teamKey: string };
  '/settings/teams/:teamKey/permissions': { teamKey: string };
  '/settings/teams/:teamKey/settings': { teamKey: string };
  '/settings/tokens/*': { '*'?: string };
  '/settings/usage': {};
  '/settings/usage/details/client': {};
  '/settings/usage/details/data-export': {};
  '/settings/usage/details/experiments': {};
  '/settings/usage/details/host': {};
  '/settings/usage/details/overview': {};
  '/settings/usage/details/server': {};
  '/verify-email': {};
};

/**
 * Declares an overloaded function for each client-side route.
 *
 */
export function appRoute(path: '/'): string;
export function appRoute(path: '/*', params: { '*'?: string }): string;
export function appRoute(path: '/confirm-auth/:deviceCode', params: { deviceCode: string }): string;
export function appRoute(path: '/insights'): string;
export function appRoute(path: '/internal/new-hires'): string;
export function appRoute(path: '/profile'): string;
export function appRoute(path: '/projects/:projectKey', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/ai-configs', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/monitoring',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/playground',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/targeting',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/variations',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/ai-configs/:configKey/variations/:variationKey',
  params: { projectKey: string; configKey: string; variationKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/ai-configs/new', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/approvals', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/approvals/:approvalRequestId',
  params: { projectKey: string; approvalRequestId: string },
): string;
export function appRoute(path: '/projects/:projectKey/audit', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/audit/:auditId/*',
  params: { projectKey: string; auditId: string; '*'?: string },
): string;
export function appRoute(path: '/projects/:projectKey/compare', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/compare/:environmentPair',
  params: { projectKey: string; environmentPair: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/compare/:environmentPair/flags/:flagKey',
  params: { projectKey: string; environmentPair: string; flagKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/contexts', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/contexts/instances/:instanceId',
  params: { projectKey: string; instanceId: string },
): string;
export function appRoute(path: '/projects/:projectKey/contexts/kinds', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/contexts/kinds/:kind/instances/:key',
  params: { projectKey: string; kind: string; key: string },
): string;
export function appRoute(path: '/projects/:projectKey/errors', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/errors/:issueId',
  params: { projectKey: string; issueId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/errors/:issueId/json',
  params: { projectKey: string; issueId: string },
): string;
export function appRoute(path: '/projects/:projectKey/experiments', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentId/details',
  params: { projectKey: string; experimentId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/design',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/events',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/iterations',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId',
  params: { projectKey: string; experimentKey: string; iterationId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/results',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/experiments/:experimentKey/traffic',
  params: { projectKey: string; experimentKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/experiments/create', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/flags', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/add-to-code',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId',
  params: { projectKey: string; flagKey: string; approvalRequestId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/code-refs',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/environment-overview',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/environments',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/environments/exclude-rules',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/experiments/*',
  params: { projectKey: string; flagKey: string; '*'?: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/history',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/history/:auditId/*',
  params: { projectKey: string; flagKey: string; auditId: string; '*'?: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/insights',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/links',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/monitoring',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/settings',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/settings/archive',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/settings/deprecate',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/targeting',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex',
  params: { projectKey: string; flagKey: string; conflictIndex: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/targeting/exclude-rules',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/targeting/pending-changes',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId',
  params: { projectKey: string; flagKey: string; pendingId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/variations',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex',
  params: { projectKey: string; flagKey: string; conflictIndex: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/variations/pending-changes',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId',
  params: { projectKey: string; flagKey: string; pendingId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/workflows',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/workflows/:workflowId',
  params: { projectKey: string; flagKey: string; workflowId: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/flags/:flagKey/workflows/new',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/flags/connect', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/flags/health', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/flags/new', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/get-started', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/get-started/invite-members',
  params: { projectKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/get-started/quickstart', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/get-started/setup-cli', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/guarded-releases', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/holdouts', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/holdouts/:holdoutKey',
  params: { projectKey: string; holdoutKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/holdouts/:holdoutKey/details',
  params: { projectKey: string; holdoutKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/holdouts/create', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/install-sdk', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/live', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/live/flags', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/live/flags/:flagKey',
  params: { projectKey: string; flagKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/metrics', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/metrics/:metricKey/details',
  params: { projectKey: string; metricKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/metrics/:metricKey/impact',
  params: { projectKey: string; metricKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/metrics/analytics', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId',
  params: { projectKey: string; analyticsQueryResultId: string },
): string;
export function appRoute(path: '/projects/:projectKey/metrics/discover', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/metrics/groups/:metricGroupKey/details',
  params: { projectKey: string; metricGroupKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/metrics/new', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/observe', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/observe/expand/:eventKind/:eventKey',
  params: { projectKey: string; eventKind: string; eventKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/observe/new-metric', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/onboarding', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/releases/:releasePipelineKey',
  params: { projectKey: string; releasePipelineKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/segments', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey',
  params: { projectKey: string; segmentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/flags',
  params: { projectKey: string; segmentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/history/*',
  params: { projectKey: string; segmentKey: string; '*'?: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/history/:auditId/*',
  params: { projectKey: string; segmentKey: string; auditId: string; '*'?: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/overview',
  params: { projectKey: string; segmentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/settings',
  params: { projectKey: string; segmentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/targeting',
  params: { projectKey: string; segmentKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/segments/:segmentKey/targeting/exports/:exportKey',
  params: { projectKey: string; segmentKey: string; exportKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/segments/new', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/sessions', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/sessions/:sessionId/*',
  params: { projectKey: string; sessionId: string; '*'?: string },
): string;
export function appRoute(path: '/projects/:projectKey/settings', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/settings/context-kinds', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/settings/environments', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/settings/environments/:envKey/approval-settings',
  params: { projectKey: string; envKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/environments/:envKey/edit',
  params: { projectKey: string; envKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/environments/:envKey/keys',
  params: { projectKey: string; envKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/environments/new',
  params: { projectKey: string },
): string;
export function appRoute(path: '/projects/:projectKey/settings/flags', params: { projectKey: string }): string;
export function appRoute(path: '/projects/:projectKey/settings/model-configs', params: { projectKey: string }): string;
export function appRoute(
  path: '/projects/:projectKey/settings/model-configs/:configKey',
  params: { projectKey: string; configKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/payload-filters',
  params: { projectKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/payload-filters/:filterKey/edit',
  params: { projectKey: string; filterKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/release-pipelines',
  params: { projectKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey',
  params: { projectKey: string; releasePipelineKey: string },
): string;
export function appRoute(
  path: '/projects/:projectKey/settings/release-pipelines/new',
  params: { projectKey: string },
): string;
export function appRoute(path: '/settings'): string;
export function appRoute(path: '/settings/applications'): string;
export function appRoute(path: '/settings/applications/:applicationKey', params: { applicationKey: string }): string;
export function appRoute(
  path: '/settings/applications/:applicationKey/edit',
  params: { applicationKey: string },
): string;
export function appRoute(
  path: '/settings/applications/:applicationKey/history/*',
  params: { applicationKey: string; '*'?: string },
): string;
export function appRoute(
  path: '/settings/applications/:applicationKey/versions',
  params: { applicationKey: string },
): string;
export function appRoute(
  path: '/settings/applications/:applicationKey/versions/:versionKey/support-status',
  params: { applicationKey: string; versionKey: string },
): string;
export function appRoute(
  path: '/settings/applications/:applicationKey/versions/new',
  params: { applicationKey: string },
): string;
export function appRoute(path: '/settings/applications/new'): string;
export function appRoute(path: '/settings/authorization'): string;
export function appRoute(path: '/settings/authorization/oauth/:appId/review', params: { appId: string }): string;
export function appRoute(path: '/settings/authorization/roles/:key', params: { key: string }): string;
export function appRoute(path: '/settings/authorization/tokens/:tokenId/clone', params: { tokenId: string }): string;
export function appRoute(path: '/settings/authorization/tokens/:tokenId/edit', params: { tokenId: string }): string;
export function appRoute(path: '/settings/authorization/tokens/:tokenId/reset', params: { tokenId: string }): string;
export function appRoute(path: '/settings/authorization/tokens/new'): string;
export function appRoute(path: '/settings/billing'): string;
export function appRoute(path: '/settings/billing/plans'): string;
export function appRoute(path: '/settings/billing/plans/review'): string;
export function appRoute(path: '/settings/escalate'): string;
export function appRoute(path: '/settings/general'): string;
export function appRoute(path: '/settings/history/*', params: { '*'?: string }): string;
export function appRoute(path: '/settings/integrations'): string;
export function appRoute(
  path: '/settings/integrations/:kind/:subscriptionId/edit',
  params: { kind: string; subscriptionId: string },
): string;
export function appRoute(
  path: '/settings/integrations/:kind/:subscriptionId/errors/view',
  params: { kind: string; subscriptionId: string },
): string;
export function appRoute(path: '/settings/integrations/:kind/new', params: { kind: string }): string;
export function appRoute(path: '/settings/integrations/:kind/show', params: { kind: string }): string;
export function appRoute(path: '/settings/integrations/coderefs/:id/edit', params: { id: string }): string;
export function appRoute(
  path: '/settings/integrations/destinations/:destinationId/edit',
  params: { destinationId: string },
): string;
export function appRoute(path: '/settings/integrations/destinations/new'): string;
export function appRoute(path: '/settings/integrations/sentry/show'): string;
export function appRoute(
  path: '/settings/integrations/slack/:slackHookId/edit',
  params: { slackHookId: string },
): string;
export function appRoute(path: '/settings/integrations/slack/new'): string;
export function appRoute(
  path: '/settings/integrations/webhooks/:webhookId/edit',
  params: { webhookId: string },
): string;
export function appRoute(path: '/settings/integrations/webhooks/new'): string;
export function appRoute(path: '/settings/members'): string;
export function appRoute(path: '/settings/members/:memberId', params: { memberId: string }): string;
export function appRoute(
  path: '/settings/members/:memberId/history/*',
  params: { memberId: string; '*'?: string },
): string;
export function appRoute(path: '/settings/members/:memberId/permissions', params: { memberId: string }): string;
export function appRoute(path: '/settings/members/:memberId/settings', params: { memberId: string }): string;
export function appRoute(path: '/settings/members/:memberId/teams', params: { memberId: string }): string;
export function appRoute(path: '/settings/members/invite/:inviteEmail?', params: { inviteEmail: string }): string;
export function appRoute(path: '/settings/profile'): string;
export function appRoute(path: '/settings/projects'): string;
export function appRoute(path: '/settings/relay'): string;
export function appRoute(path: '/settings/relay/:id/edit', params: { id: string }): string;
export function appRoute(path: '/settings/relay/new'): string;
export function appRoute(path: '/settings/roles'): string;
export function appRoute(path: '/settings/roles/:key/edit', params: { key: string }): string;
export function appRoute(path: '/settings/roles/:key/view', params: { key: string }): string;
export function appRoute(path: '/settings/roles/new'): string;
export function appRoute(path: '/settings/roles/organization'): string;
export function appRoute(path: '/settings/roles/project'): string;
export function appRoute(path: '/settings/security'): string;
export function appRoute(path: '/settings/security/saml/config'): string;
export function appRoute(path: '/settings/teams'): string;
export function appRoute(path: '/settings/teams/:teamKey', params: { teamKey: string }): string;
export function appRoute(path: '/settings/teams/:teamKey/history/*', params: { teamKey: string; '*'?: string }): string;
export function appRoute(path: '/settings/teams/:teamKey/members', params: { teamKey: string }): string;
export function appRoute(path: '/settings/teams/:teamKey/permissions', params: { teamKey: string }): string;
export function appRoute(path: '/settings/teams/:teamKey/settings', params: { teamKey: string }): string;
export function appRoute(path: '/settings/tokens/*', params: { '*'?: string }): string;
export function appRoute(path: '/settings/usage'): string;
export function appRoute(path: '/settings/usage/details/client'): string;
export function appRoute(path: '/settings/usage/details/data-export'): string;
export function appRoute(path: '/settings/usage/details/experiments'): string;
export function appRoute(path: '/settings/usage/details/host'): string;
export function appRoute(path: '/settings/usage/details/overview'): string;
export function appRoute(path: '/settings/usage/details/server'): string;
export function appRoute(path: '/verify-email'): string;
export function appRoute(path: string, params?: Params<string>) {
  return generatePath(path, params);
}

/**
 * Defines the ownership for all the routes defined in ../../../static/ld/routes/appRoutes.tsx.
 *
 */
export type AppRouteOwnership = {
  '/': Squad;
  '/*': Squad;
  '/confirm-auth/:deviceCode': Squad;
  '/insights': Squad;
  '/internal/new-hires': Squad;
  '/profile': Squad;
  '/projects/:projectKey': Squad;
  '/projects/:projectKey/ai-configs': Squad;
  '/projects/:projectKey/ai-configs/:configKey': Squad;
  '/projects/:projectKey/ai-configs/:configKey/monitoring': Squad;
  '/projects/:projectKey/ai-configs/:configKey/playground': Squad;
  '/projects/:projectKey/ai-configs/:configKey/targeting': Squad;
  '/projects/:projectKey/ai-configs/:configKey/targeting/pending-changes': Squad;
  '/projects/:projectKey/ai-configs/:configKey/variations': Squad;
  '/projects/:projectKey/ai-configs/:configKey/variations/:variationKey': Squad;
  '/projects/:projectKey/ai-configs/new': Squad;
  '/projects/:projectKey/approvals': Squad;
  '/projects/:projectKey/approvals/:approvalRequestId': Squad;
  '/projects/:projectKey/audit': Squad;
  '/projects/:projectKey/audit/:auditId/*': Squad;
  '/projects/:projectKey/compare': Squad;
  '/projects/:projectKey/compare/:environmentPair': Squad;
  '/projects/:projectKey/compare/:environmentPair/flags/:flagKey': Squad;
  '/projects/:projectKey/contexts': Squad;
  '/projects/:projectKey/contexts/instances/:instanceId': Squad;
  '/projects/:projectKey/contexts/kinds': Squad;
  '/projects/:projectKey/contexts/kinds/:kind/instances/:key': Squad;
  '/projects/:projectKey/errors': Squad;
  '/projects/:projectKey/errors/:issueId': Squad;
  '/projects/:projectKey/errors/:issueId/json': Squad;
  '/projects/:projectKey/experiments': Squad;
  '/projects/:projectKey/experiments/:experimentId/details': Squad;
  '/projects/:projectKey/experiments/:experimentKey': Squad;
  '/projects/:projectKey/experiments/:experimentKey/design': Squad;
  '/projects/:projectKey/experiments/:experimentKey/events': Squad;
  '/projects/:projectKey/experiments/:experimentKey/iterations': Squad;
  '/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId': Squad;
  '/projects/:projectKey/experiments/:experimentKey/results': Squad;
  '/projects/:projectKey/experiments/:experimentKey/traffic': Squad;
  '/projects/:projectKey/experiments/create': Squad;
  '/projects/:projectKey/flags': Squad;
  '/projects/:projectKey/flags/:flagKey': Squad;
  '/projects/:projectKey/flags/:flagKey/add-to-code': Squad;
  '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId': Squad;
  '/projects/:projectKey/flags/:flagKey/code-refs': Squad;
  '/projects/:projectKey/flags/:flagKey/environment-overview': Squad;
  '/projects/:projectKey/flags/:flagKey/environments': Squad;
  '/projects/:projectKey/flags/:flagKey/environments/exclude-rules': Squad;
  '/projects/:projectKey/flags/:flagKey/experiments/*': Squad;
  '/projects/:projectKey/flags/:flagKey/history': Squad;
  '/projects/:projectKey/flags/:flagKey/history/:auditId/*': Squad;
  '/projects/:projectKey/flags/:flagKey/insights': Squad;
  '/projects/:projectKey/flags/:flagKey/links': Squad;
  '/projects/:projectKey/flags/:flagKey/monitoring': Squad;
  '/projects/:projectKey/flags/:flagKey/settings': Squad;
  '/projects/:projectKey/flags/:flagKey/settings/archive': Squad;
  '/projects/:projectKey/flags/:flagKey/settings/deprecate': Squad;
  '/projects/:projectKey/flags/:flagKey/targeting': Squad;
  '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex': Squad;
  '/projects/:projectKey/flags/:flagKey/targeting/exclude-rules': Squad;
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes': Squad;
  '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId': Squad;
  '/projects/:projectKey/flags/:flagKey/variations': Squad;
  '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex': Squad;
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes': Squad;
  '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId': Squad;
  '/projects/:projectKey/flags/:flagKey/workflows': Squad;
  '/projects/:projectKey/flags/:flagKey/workflows/:workflowId': Squad;
  '/projects/:projectKey/flags/:flagKey/workflows/new': Squad;
  '/projects/:projectKey/flags/connect': Squad;
  '/projects/:projectKey/flags/health': Squad;
  '/projects/:projectKey/flags/new': Squad;
  '/projects/:projectKey/get-started': Squad;
  '/projects/:projectKey/get-started/invite-members': Squad;
  '/projects/:projectKey/get-started/quickstart': Squad;
  '/projects/:projectKey/get-started/setup-cli': Squad;
  '/projects/:projectKey/guarded-releases': Squad;
  '/projects/:projectKey/holdouts': Squad;
  '/projects/:projectKey/holdouts/:holdoutKey': Squad;
  '/projects/:projectKey/holdouts/:holdoutKey/details': Squad;
  '/projects/:projectKey/holdouts/create': Squad;
  '/projects/:projectKey/install-sdk': Squad;
  '/projects/:projectKey/live': Squad;
  '/projects/:projectKey/live/flags': Squad;
  '/projects/:projectKey/live/flags/:flagKey': Squad;
  '/projects/:projectKey/metrics': Squad;
  '/projects/:projectKey/metrics/:metricKey/details': Squad;
  '/projects/:projectKey/metrics/:metricKey/impact': Squad;
  '/projects/:projectKey/metrics/analytics': Squad;
  '/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId': Squad;
  '/projects/:projectKey/metrics/discover': Squad;
  '/projects/:projectKey/metrics/groups/:metricGroupKey/details': Squad;
  '/projects/:projectKey/metrics/new': Squad;
  '/projects/:projectKey/observe': Squad;
  '/projects/:projectKey/observe/expand/:eventKind/:eventKey': Squad;
  '/projects/:projectKey/observe/new-metric': Squad;
  '/projects/:projectKey/onboarding': Squad;
  '/projects/:projectKey/releases/:releasePipelineKey': Squad;
  '/projects/:projectKey/segments': Squad;
  '/projects/:projectKey/segments/:segmentKey': Squad;
  '/projects/:projectKey/segments/:segmentKey/flags': Squad;
  '/projects/:projectKey/segments/:segmentKey/history/*': Squad;
  '/projects/:projectKey/segments/:segmentKey/history/:auditId/*': Squad;
  '/projects/:projectKey/segments/:segmentKey/overview': Squad;
  '/projects/:projectKey/segments/:segmentKey/settings': Squad;
  '/projects/:projectKey/segments/:segmentKey/targeting': Squad;
  '/projects/:projectKey/segments/:segmentKey/targeting/exports/:exportKey': Squad;
  '/projects/:projectKey/segments/new': Squad;
  '/projects/:projectKey/sessions': Squad;
  '/projects/:projectKey/sessions/:sessionId/*': Squad;
  '/projects/:projectKey/settings': Squad;
  '/projects/:projectKey/settings/context-kinds': Squad;
  '/projects/:projectKey/settings/environments': Squad;
  '/projects/:projectKey/settings/environments/:envKey/approval-settings': Squad;
  '/projects/:projectKey/settings/environments/:envKey/edit': Squad;
  '/projects/:projectKey/settings/environments/:envKey/keys': Squad;
  '/projects/:projectKey/settings/environments/new': Squad;
  '/projects/:projectKey/settings/flags': Squad;
  '/projects/:projectKey/settings/model-configs': Squad;
  '/projects/:projectKey/settings/model-configs/:configKey': Squad;
  '/projects/:projectKey/settings/payload-filters': Squad;
  '/projects/:projectKey/settings/payload-filters/:filterKey/edit': Squad;
  '/projects/:projectKey/settings/release-pipelines': Squad;
  '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey': Squad;
  '/projects/:projectKey/settings/release-pipelines/new': Squad;
  '/settings': Squad;
  '/settings/applications': Squad;
  '/settings/applications/:applicationKey': Squad;
  '/settings/applications/:applicationKey/edit': Squad;
  '/settings/applications/:applicationKey/history/*': Squad;
  '/settings/applications/:applicationKey/versions': Squad;
  '/settings/applications/:applicationKey/versions/:versionKey/support-status': Squad;
  '/settings/applications/:applicationKey/versions/new': Squad;
  '/settings/applications/new': Squad;
  '/settings/authorization': Squad;
  '/settings/authorization/oauth/:appId/review': Squad;
  '/settings/authorization/roles/:key': Squad;
  '/settings/authorization/tokens/:tokenId/clone': Squad;
  '/settings/authorization/tokens/:tokenId/edit': Squad;
  '/settings/authorization/tokens/:tokenId/reset': Squad;
  '/settings/authorization/tokens/new': Squad;
  '/settings/billing': Squad;
  '/settings/billing/plans': Squad;
  '/settings/billing/plans/review': Squad;
  '/settings/escalate': Squad;
  '/settings/general': Squad;
  '/settings/history/*': Squad;
  '/settings/integrations': Squad;
  '/settings/integrations/:kind/:subscriptionId/edit': Squad;
  '/settings/integrations/:kind/:subscriptionId/errors/view': Squad;
  '/settings/integrations/:kind/new': Squad;
  '/settings/integrations/:kind/show': Squad;
  '/settings/integrations/coderefs/:id/edit': Squad;
  '/settings/integrations/destinations/:destinationId/edit': Squad;
  '/settings/integrations/destinations/new': Squad;
  '/settings/integrations/sentry/show': Squad;
  '/settings/integrations/slack/:slackHookId/edit': Squad;
  '/settings/integrations/slack/new': Squad;
  '/settings/integrations/webhooks/:webhookId/edit': Squad;
  '/settings/integrations/webhooks/new': Squad;
  '/settings/members': Squad;
  '/settings/members/:memberId': Squad;
  '/settings/members/:memberId/history/*': Squad;
  '/settings/members/:memberId/permissions': Squad;
  '/settings/members/:memberId/settings': Squad;
  '/settings/members/:memberId/teams': Squad;
  '/settings/members/invite/:inviteEmail?': Squad;
  '/settings/profile': Squad;
  '/settings/projects': Squad;
  '/settings/relay': Squad;
  '/settings/relay/:id/edit': Squad;
  '/settings/relay/new': Squad;
  '/settings/roles': Squad;
  '/settings/roles/:key/edit': Squad;
  '/settings/roles/:key/view': Squad;
  '/settings/roles/new': Squad;
  '/settings/roles/organization': Squad;
  '/settings/roles/project': Squad;
  '/settings/security': Squad;
  '/settings/security/saml/config': Squad;
  '/settings/teams': Squad;
  '/settings/teams/:teamKey': Squad;
  '/settings/teams/:teamKey/history/*': Squad;
  '/settings/teams/:teamKey/members': Squad;
  '/settings/teams/:teamKey/permissions': Squad;
  '/settings/teams/:teamKey/settings': Squad;
  '/settings/tokens/*': Squad;
  '/settings/usage': Squad;
  '/settings/usage/details/client': Squad;
  '/settings/usage/details/data-export': Squad;
  '/settings/usage/details/experiments': Squad;
  '/settings/usage/details/host': Squad;
  '/settings/usage/details/overview': Squad;
  '/settings/usage/details/server': Squad;
  '/verify-email': Squad;
};

/**
 * This type defines any possible route, as a union type of all routes defined in:
 *   - ../../../static/ld/routes/unauthenticatedRoutes.tsx
 *   - ../../../static/ld/routes/appRoutes.tsx
 *
 */
export type AnyRoute = UnauthenticatedRoute | AppRoute;
