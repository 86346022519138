import { approvalsNotificationListMaxLimit } from '@gonfalon/dogfood-flags';
import { isEqual } from '@gonfalon/es6-utils';
import { ResourceSpecifier, resourceSpecifierFromString } from '@gonfalon/resource-specifiers';
import { capitalize, pluralize } from '@gonfalon/strings';
import { isPlainJSONObject } from '@gonfalon/types';
// eslint-disable-next-line no-restricted-imports
import { fromJS, get, isImmutable, List, Map, OrderedMap, Record } from 'immutable';

import { ContextTargetingExpirationUpdates } from 'reducers/expiringContextTargets';
import { CheckAccessFunction, combineAccessDecisions, createAccessDecision } from 'utils/accessUtils';
import {
  ApprovalServiceKind,
  ApprovalSettings,
  createApprovalRequestSettings,
  createEnvironment,
  createResourceApprovalRequestSettings,
  Environment,
  ResourceApprovalSettings,
} from 'utils/environmentUtils';
import { FlagCopyResourceType } from 'utils/flagComparisonUtils';
import { CreateFunctionInput, ImmutableMap, toJS } from 'utils/immutableUtils';
import { FlagVariationsInstructionKind } from 'utils/instructions/variations/types';
import { isLength, isNotEmpty, isNotZero, isValidNotifyMembersList, validateRecord } from 'utils/validationUtils';

import { makeSemanticInstruction } from './instructions/shared/helpers';
import { FlagConflictKind, InstructionIndexToConflictsInfo, SemanticInstruction } from './instructions/shared/types';
import { TargetsInstructionKind, UpdateTargetsSemanticInstruction } from './instructions/targets/types';
import { Member } from './accountUtils';
import { getContextTargetingExpirationInstructionsForFlag } from './expiringContextTargetsUtils';
import { createFlag, Flag } from './flagUtils';
import { WorkflowNotificationKind } from './pendingChangesUtils';
import { createProject, Project } from './projectUtils';
import { createSegment, Segment } from './segmentUtils';
import { Team } from './teamsUtils';
import { WorkflowSemanticInstruction } from './workflowUtils';

export enum ApprovalsReviewKind {
  APPROVE = 'approve',
  DECLINE = 'decline',
  COMMENT = 'comment',
  DEFAULT = '',
}

export enum ApprovalsReviewStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  DECLINED = 'declined',
  COMMENTED = 'commented',
  DEFAULT = 'notified',
}

export enum ApprovalWorkflowExecutionStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  FAILED = 'failed',
}

export type ApprovalProgressStatusKey = 'PENDING' | 'APPROVED' | 'COMPLETED' | 'DECLINED' | 'FAILED' | '';

export enum ApprovalProgressStatus {
  PENDING = 'Needs review',
  APPROVED = 'Approved with unapplied changes',
  COMPLETED = 'Completed',
  DECLINED = 'Declined',
  FAILED = 'Failed',
}

export enum ApprovalRequestKind {
  DELETE_SCHEDULED_CHANGE = 'Delete scheduled change',
  SCHEDULE_CHANGES = 'Schedule changes',
  UPDATE_FLAG = 'Update flag',
}

export type ApprovalRequestConflict = ImmutableMap<{
  instruction: SemanticInstruction;
  reason: string;
}>;

export type IntegrationMetadata = ImmutableMap<{
  externalId: string;
  externalStatus: ImmutableMap<{
    display: string;
    value: string;
  }>;
  externalUrl: string;
  lastChecked: number;
}>;

export type ApprovalRequestResourceKind = 'Flag' | 'FlagConfiguration' | 'Segment';
export type ResourceTypeName = ResourceSpecifier['type'] | 'resource';

export class ApprovalCustomWorkflowMetadataStage extends Record<{
  index: number;
  name: string;
}>({
  index: 0,
  name: '',
}) {}
export class ApprovalCustomWorkflowMetadata extends Record<{
  name: string;
  stage: ApprovalCustomWorkflowMetadataStage;
}>({
  name: '',
  stage: new ApprovalCustomWorkflowMetadataStage(),
}) {}

export const createApprovalCustomWorkflowMetadata = (
  props: CreateFunctionInput<ApprovalCustomWorkflowMetadata> = {},
) => {
  if (props instanceof ApprovalCustomWorkflowMetadata) {
    return props;
  }
  let customWorkflowMetadata = new ApprovalCustomWorkflowMetadata(fromJS(props));
  customWorkflowMetadata = customWorkflowMetadata.withMutations((cwMetadata) => {
    cwMetadata.update('stage', (stage) => new ApprovalCustomWorkflowMetadataStage(stage));
  });
  return customWorkflowMetadata;
};

export type ApprovalRequestProps = {
  _id: string;
  _links: ImmutableMap<{
    self: string;
    review: string;
  }>;
  reviewStatus: ApprovalsReviewStatus;
  creationDate: number;
  requestorId: string;
  description: string;
  allReviews: List<ApprovalRequestReview>;
  notifyMemberIds: List<string>;
  executionDate: number;
  appliedDate: number;
  instructions: List<WorkflowSemanticInstruction>;
  conflicts: List<ApprovalRequestConflict>;
  status: string;
  appliedByMemberId?: string;
  appliedByServiceTokenId?: string;
  serviceKind: string;
  integrationMetadata: IntegrationMetadata;
  operatingOnId?: string;
  source?: ImmutableMap<FlagCopyResourceType>;
  customWorkflowMetadata?: ApprovalCustomWorkflowMetadata;
  project?: Project;
  environment?: Environment;
  environments?: List<Environment>;
  flag?: Flag;
  approvalSettings?: ApprovalSettings;
  resourceApprovalSettings?: ResourceApprovalSettings;
  resourceId?: string;
  resource?: ImmutableMap<{
    kind: string;
    flag?: Flag | null;
    segment?: Segment | null;
  }>;
};

export const DESCRIPTION_MAX_LENGTH = 5000;

export type ReviewerType = 'member' | 'serviceToken';

export class ApprovalRequest extends Record<ApprovalRequestProps>({
  _id: '',
  _links: Map(),
  reviewStatus: ApprovalsReviewStatus.DEFAULT,
  creationDate: 0,
  requestorId: '',
  description: '',
  status: '',
  appliedByMemberId: '',
  appliedByServiceTokenId: undefined,
  operatingOnId: undefined,
  allReviews: List(),
  notifyMemberIds: List(),
  executionDate: 0,
  appliedDate: 0,
  instructions: List(),
  conflicts: List(),
  serviceKind: ApprovalServiceKind.LaunchDarklyApprovalServiceKind,
  integrationMetadata: Map(),
  source: undefined,
  customWorkflowMetadata: undefined,
  project: undefined,
  environment: undefined,
  environments: undefined,
  flag: undefined,
  approvalSettings: undefined,
  resourceApprovalSettings: undefined,
  resourceId: undefined,
  resource: undefined,
}) {
  getCreationDate() {
    return this.creationDate;
  }
  getId() {
    return this._id;
  }

  getInstructions() {
    return this.instructions;
  }

  getConflicts() {
    return this.conflicts || List();
  }

  getReviews() {
    return this.allReviews.sort((a, b) => {
      const dateA = a.creationDate;
      const dateB = b.creationDate;
      return dateA - dateB; // oldest to newest
    });
  }

  getNotifiedMemberIds() {
    return this.notifyMemberIds;
  }

  getResourceKind(): ApprovalRequestResourceKind {
    if (!this.resourceId) {
      /*
      The "resourceId" property is
      only supplied when the approval request is fetched via /approval-requests API endpoints.
      The flag config approval request endpoint does not supply this property. Therefore,
      if the property is falsey, we assume the approval request is acting upon a flag configuration.
      */
      return 'FlagConfiguration';
    }

    const result = resourceSpecifierFromString(this.resourceId);

    if (result.err) {
      throw new Error(result.val.message);
    }

    switch (result.val.type) {
      case 'flag':
        return result.val.environment.name === '*' ? 'Flag' : 'FlagConfiguration';
      case 'segment':
        return 'Segment';
      default:
        return 'FlagConfiguration';
    }
  }

  getResourceKindName(): ResourceTypeName {
    if (!this.resourceId) {
      return 'flag';
    }

    const result = resourceSpecifierFromString(this.resourceId);

    if (result.err) {
      return 'resource';
    }

    return result.val.type;
  }

  isIntegrationApproval() {
    return this.serviceKind !== '' && this.serviceKind !== ApprovalServiceKind.LaunchDarklyApprovalServiceKind;
  }

  isFlagCopyApproval() {
    return this.source !== undefined;
  }

  isApproved() {
    return this.reviewStatus === ApprovalsReviewStatus.APPROVED;
  }

  isApplied() {
    return !!this.appliedDate;
  }

  isDeclined() {
    return this.reviewStatus === ApprovalsReviewStatus.DECLINED;
  }

  validate({
    approvalServiceKind = ApprovalServiceKind.LaunchDarklyApprovalServiceKind,
    isScheduled = false,
  }: { approvalServiceKind?: ApprovalServiceKind | string; isScheduled?: boolean } = {}) {
    const predicates = [
      isNotEmpty('description'),
      isLength(0, DESCRIPTION_MAX_LENGTH)('description'),
      isNotEmpty('instructions'),
    ];
    if (approvalServiceKind === ApprovalServiceKind.LaunchDarklyApprovalServiceKind) {
      predicates.push(isNotEmpty('notifyMemberIds'));
      predicates.push(isValidNotifyMembersList(approvalsNotificationListMaxLimit())('notifyMemberIds'));
    }
    if (isScheduled) {
      predicates.push(isNotZero('executionDate'));
    }
    return validateRecord(this, ...predicates);
  }

  /**
   * Returns a map keyed by memberId indicating the review status of that member
   * Review status will be determined by the latest review
   * unless that review is a comment type and is preceded by an approve or decline type
   */
  makeReviewerToReviewStatus() {
    const reviewerToReviewStatus = this.getReviews().reduce(
      (accum, curr) => {
        const memberId = curr.memberId;
        const serviceTokenId = curr.serviceTokenId;
        const reviewerType = memberId ? ('member' as ReviewerType) : ('serviceToken' as ReviewerType);
        const reviewerId = Boolean(memberId) ? memberId : serviceTokenId;
        const reviewStatus = curr.kind;
        if (!reviewerId) {
          return accum;
        }
        return reviewStatus === ApprovalsReviewKind.COMMENT && accum.hasOwnProperty(reviewerId)
          ? accum
          : { ...accum, [reviewerId]: { reviewStatus: reviewKindToApprovalReviewStatus[reviewStatus], reviewerType } };
      },
      {} as { [reviewer: string]: { reviewStatus: ApprovalsReviewStatus; reviewerType: ReviewerType } },
    );

    this.getNotifiedMemberIds().forEach((memberId) => {
      reviewerToReviewStatus[memberId] = reviewerToReviewStatus[memberId] ?? ApprovalsReviewStatus.DEFAULT;
    });

    return reviewerToReviewStatus;
  }

  /**
   * Returns the most recent approve or decline review for a given member id
   * If none found, returns undefined
   */
  getLatestApproveOrDeclineForMemberId(memberId?: string) {
    if (!memberId) {
      return undefined;
    }
    let latestReview: ApprovalRequestReview | undefined;
    const allReviews = this.getReviews();
    for (let i = allReviews.size - 1; i >= 0; i--) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const review = allReviews.get(i)!; /* eslint-enable @typescript-eslint/no-non-null-assertion */
      if (review.get('kind') !== ApprovalsReviewKind.COMMENT && review.get('memberId') === memberId && !latestReview) {
        latestReview = review;
        break;
      }
    }
    return latestReview;
  }

  getHasMemberReviewed(memberId: string) {
    return !!this.getLatestApproveOrDeclineForMemberId(memberId);
  }

  getHasMemberApproved(memberId: string) {
    return this.getLatestApproveOrDeclineForMemberId(memberId)?.kind === ApprovalsReviewKind.APPROVE;
  }

  getHasMemberDeclined(memberId: string) {
    return this.getLatestApproveOrDeclineForMemberId(memberId)?.kind === ApprovalsReviewKind.DECLINE;
  }

  getResourceCheckAccess({
    resource,
    environment,
    profile,
  }: {
    resource: Flag | Segment;
    environment?: Environment;
    profile: Member;
  }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let checkAccess = (_action?: string) => createAccessDecision({ isAllowed: false });

    if (resource instanceof Flag) {
      checkAccess = environment
        ? resource.checkAccess({ envKey: environment.key, profile })
        : resource.checkAccessForAllEnvironments({ profile });
    }

    if (resource instanceof Segment) {
      checkAccess = resource.checkAccess(profile);
    }

    return checkAccess;
  }

  getProfileCommentAccess({
    profile,
    environment,
    approvalSettings,
    resource,
  }: {
    profile: Member;
    environment?: Environment;
    approvalSettings: ApprovalSettings; // TODO add support for ResourceApprovalSettings
    resource?: Flag | Segment;
  }) {
    let checkAccess = () => createAccessDecision({ isAllowed: false });

    if (resource) {
      checkAccess = this.getResourceCheckAccess({ resource, environment, profile });
    }

    return this.createCommentAccess({
      checkAccess,
      memberId: profile._id,
      canReviewOwnRequest: approvalSettings.canReviewOwnRequest,
      isIntegrationApproval: this.isIntegrationApproval(),
    });
  }

  getProfileReviewAccess({
    profile,
    environment,
    approvalSettings,
    resource,
  }: {
    profile: Member;
    environment?: Environment;
    approvalSettings: ApprovalSettings; // TODO add support for ResourceApprovalSettings
    resource?: Flag | Segment;
  }) {
    let checkAccess = () => createAccessDecision({ isAllowed: false });

    if (resource) {
      checkAccess = this.getResourceCheckAccess({ resource, environment, profile });
    }

    return this.createReviewAccess({
      checkAccess,
      memberId: profile._id,
      canReviewOwnRequest: approvalSettings.canReviewOwnRequest,
      isIntegrationApproval: this.isIntegrationApproval(),
    });
  }

  getWasMemberNotified(memberId: string) {
    return this.getNotifiedMemberIds().includes(memberId);
  }

  getProfileUpdateAccess({
    profile,
    environment,
    resource,
  }: {
    profile: Member;
    environment?: Environment;
    resource: Flag | Segment;
  }) {
    if (resource) {
      const checkAccess = this.getResourceCheckAccess({ resource, environment, profile });
      return checkAccess('updateApprovalRequest');
    }

    return createAccessDecision({ isAllowed: false });
  }

  getProfileApplyAccess({
    profile,
    environment,
    approvalSettings,
    resource,
  }: {
    profile: Member;
    environment?: Environment;
    approvalSettings: ApprovalSettings; // TODO add support for ResourceApprovalSettings
    resource: Flag | Segment;
  }) {
    let checkAccess = () => createAccessDecision({ isAllowed: false });

    if (resource) {
      checkAccess = this.getResourceCheckAccess({ resource, environment, profile });
    }

    return this.createApplyAccess({
      checkAccess,
      minNumberOfApprovals: approvalSettings.minNumApprovals || 1,
      canApplyDeclinedChanges: approvalSettings.canApplyDeclinedChanges,
      isIntegrationApproval: this.isIntegrationApproval(),
    });
  }

  getProfileDeleteAccess({
    profile,
    environment,
    resource,
  }: {
    profile: Member;
    environment?: Environment;
    resource: Flag | Segment;
  }) {
    let checkAccess = () => createAccessDecision({ isAllowed: false });

    if (resource) {
      checkAccess = this.getResourceCheckAccess({ resource, environment, profile });
    }

    return this.createDeleteAccess({ checkAccess });
  }

  getProgressStatus() {
    if (this.isApplied()) {
      return this.status === ApprovalWorkflowExecutionStatus.FAILED
        ? ApprovalProgressStatus.FAILED
        : ApprovalProgressStatus.COMPLETED;
    }

    if (this.reviewStatus === ApprovalsReviewStatus.APPROVED) {
      return ApprovalProgressStatus.APPROVED;
    }

    if (this.reviewStatus === ApprovalsReviewStatus.DECLINED) {
      return ApprovalProgressStatus.DECLINED;
    }

    return ApprovalProgressStatus.PENDING;
  }

  /**
   * Returns a count of how many members have a given review status
   * This indicates how many reviews of a given kind are factoring into
   * whether the approval request is considered "approved" or "declined"
   */
  getReviewStatusCount(reviewStatus: ApprovalsReviewStatus) {
    const reviewerToReviewStatus = this.makeReviewerToReviewStatus();
    return Object.values(reviewerToReviewStatus).filter((review) => review.reviewStatus === reviewStatus).length;
  }

  /**
   * Returns the kind of approval request based on what resources will be impacted after applying
   */
  getKind() {
    if (this.operatingOnId) {
      // at the moment only workflows for deleting scheduled changes have an `operatingOnId` property
      return ApprovalRequestKind.DELETE_SCHEDULED_CHANGE;
    }
    if (this.executionDate) {
      return ApprovalRequestKind.SCHEDULE_CHANGES;
    }
    return ApprovalRequestKind.UPDATE_FLAG;
  }

  /**
   * Returns an access decision determining whether or not a member can review an approval request by declining or approving
   */
  createReviewAccess({
    checkAccess,
    memberId,
    canReviewOwnRequest,
    isIntegrationApproval,
  }: {
    checkAccess: CheckAccessFunction;
    memberId?: string;
    canReviewOwnRequest: boolean;
    isIntegrationApproval: boolean;
  }) {
    const isOwnerOfRequest = memberId && memberId === this.requestorId;
    return combineAccessDecisions(
      checkAccess('reviewApprovalRequest'),
      createAccessDecision({
        isAllowed: !isOwnerOfRequest || canReviewOwnRequest,
        customMessage: 'Comment only. You do not have permission to approve or decline your own request.',
      }),
      createAccessDecision({
        isAllowed: !isIntegrationApproval,
      }),
    );
  }

  /**
   * Returns an access decision determining whether or not a member can comment on an approval request
   */
  createCommentAccess({
    checkAccess,
    memberId,
    canReviewOwnRequest,
    isIntegrationApproval,
  }: {
    checkAccess: CheckAccessFunction;
    memberId?: string;
    canReviewOwnRequest: boolean;
    isIntegrationApproval: boolean;
  }) {
    const reviewAccess = this.createReviewAccess({
      checkAccess,
      memberId,
      canReviewOwnRequest,
      isIntegrationApproval,
    });
    const isOwnerOfRequest = memberId && memberId === this.requestorId;
    return combineAccessDecisions(
      createAccessDecision({
        isAllowed: reviewAccess.isAllowed || isOwnerOfRequest,
        customMessage: reviewAccess.getActionReason(),
      }),
      createAccessDecision({
        isAllowed: !isIntegrationApproval,
      }),
    );
  }

  /**
   * Returns an access decision determining whether or not a member can apply an approval request
   */
  createApplyAccess({
    checkAccess,
    minNumberOfApprovals,
    canApplyDeclinedChanges,
    isIntegrationApproval,
  }: {
    checkAccess: CheckAccessFunction;
    minNumberOfApprovals: number;
    canApplyDeclinedChanges: boolean;
    isIntegrationApproval: boolean;
  }) {
    const isDeclined = this.reviewStatus === ApprovalsReviewStatus.DECLINED;
    const isApproved = this.reviewStatus === ApprovalsReviewStatus.APPROVED;
    const numApprovalsLeft = minNumberOfApprovals - this.getReviewStatusCount(ApprovalsReviewStatus.APPROVED);
    const hasConflicts = this.getConflicts().size > 0;

    return combineAccessDecisions(
      checkAccess('applyApprovalRequest'),
      createAccessDecision({
        isAllowed: !isDeclined,
        customMessage:
          isIntegrationApproval || canApplyDeclinedChanges
            ? 'The request has been declined.'
            : 'You cannot apply declined changes in this environment.',
      }),
      createAccessDecision({
        isAllowed: isApproved,
        customMessage: `Requires ${numApprovalsLeft} more ${pluralize('approval', numApprovalsLeft)}.`,
      }),
      createAccessDecision({
        isAllowed: !hasConflicts,
        customMessage: isApproved
          ? 'You cannot apply changes because there are unresolved conflicts on this request.'
          : 'You will not be able to apply the changes if the conflicts are left unresolved.',
      }),
    );
  }

  /**
   * Returns an access decision determining whether or not an approval request can be deleted
   */
  createDeleteAccess({ checkAccess }: { checkAccess: CheckAccessFunction }) {
    return combineAccessDecisions(
      checkAccess('deleteApprovalRequest'),
      createAccessDecision({
        isAllowed: !this.isApplied(),
        customMessage: 'Approval requests cannot be deleted once applied.',
      }),
    );
  }
}

function preserveInstructionValueOrder(props: CreateFunctionInput<ApprovalRequest>) {
  const instructions = isImmutable(props) ? props.get('instructions') : props.instructions;
  return Array.isArray(instructions)
    ? instructions.map((i) => {
        if (
          i.kind === FlagVariationsInstructionKind.ADD_VARIATION ||
          i.kind === FlagVariationsInstructionKind.UPDATE_VARIATION
        ) {
          let value = i.value;
          if (isPlainJSONObject(i.value)) {
            value = OrderedMap(i.value);
          }

          return { ...i, value };
        }

        return i;
      })
    : instructions;
}

export const createApprovalRequest = (props: CreateFunctionInput<ApprovalRequest> = {}) => {
  if (props instanceof ApprovalRequest) {
    return props;
  }

  let approvalRequest = new ApprovalRequest(fromJS(props));

  approvalRequest = approvalRequest.set(
    'allReviews',
    approvalRequest.get('allReviews').map(createApprovalRequestReview),
  );

  const instructions = preserveInstructionValueOrder(props);
  approvalRequest = approvalRequest.set('instructions', fromJS(instructions));
  approvalRequest = approvalRequest.update('instructions', (vs) =>
    vs.size > 0 ? vs.map((v) => makeSemanticInstruction(get(v, 'kind', ''), v)) : List(),
  );

  // transform the conflicts the same way so we can compare them with instructions
  approvalRequest = approvalRequest.update('conflicts', (values) =>
    values?.size > 0
      ? values.map((conflict) =>
          conflict.get('instruction')
            ? conflict.update('instruction', (i) => makeSemanticInstruction(get(i, 'kind', ''), i))
            : conflict,
        )
      : List(),
  );

  if (approvalRequest.get('customWorkflowMetadata')) {
    approvalRequest = approvalRequest.update('customWorkflowMetadata', (cwMetadata) =>
      createApprovalCustomWorkflowMetadata(cwMetadata),
    );
  }

  if (approvalRequest.get('project')) {
    approvalRequest = approvalRequest.update('project', (p) => createProject(p));
  }

  if (approvalRequest.get('environment')) {
    approvalRequest = approvalRequest.update('environment', (e) => createEnvironment(e));
  }

  if (approvalRequest.get('environments')) {
    approvalRequest = approvalRequest.update('environments', (envs) => envs?.map(createEnvironment));
  }

  if (approvalRequest.get('approvalSettings')) {
    approvalRequest = approvalRequest.update('approvalSettings', (approvalSettings) =>
      createApprovalRequestSettings(approvalSettings),
    );
  }

  if (approvalRequest.get('resourceApprovalSettings')) {
    approvalRequest = approvalRequest.update('resourceApprovalSettings', (approvalSettings) =>
      createResourceApprovalRequestSettings(approvalSettings),
    );
  }

  if (approvalRequest.get('flag')) {
    approvalRequest = approvalRequest.update('flag', (f) => createFlag(f));
  }

  if (approvalRequest.get('resource')) {
    if (approvalRequest.getIn(['resource', 'kind']) === 'flag') {
      approvalRequest = approvalRequest.updateIn(['resource', 'flag'], (f) => createFlag(f));
    }

    if (approvalRequest.getIn(['resource', 'kind']) === 'segment') {
      approvalRequest = approvalRequest.updateIn(['resource', 'segment'], (s) => createSegment(s));
    }
  }

  return approvalRequest;
};

export const handleApprovalRequestWithExpiringTargets = ({
  targetingExpirationUpdates,
  flagKey,
  instructionList,
}: {
  targetingExpirationUpdates: ContextTargetingExpirationUpdates;
  flagKey: string;
  instructionList: SemanticInstruction[];
}) => {
  const patches = getContextTargetingExpirationInstructionsForFlag(targetingExpirationUpdates, flagKey);
  const addTargetInstructions = instructionList.filter((d) => d.kind === TargetsInstructionKind.ADD_TARGETS);
  let updatedInstructions = instructionList;
  let expiringTargetInstructions = patches;

  // if adding targets, we want to filter out add target instructions that have an expiration date,
  // so that those instructions can be added to a separate approval request
  if (addTargetInstructions.length) {
    const allAddedTargetsByContextKind = (addTargetInstructions as UpdateTargetsSemanticInstruction[]).reduce(
      (acc: { [contextKind: string]: Set<string> | undefined }, d) => {
        const targetsByKind = acc;
        targetsByKind[d.contextKind] = d.values.reduce(
          (targetsForKind, contextKey) => targetsForKind.add(contextKey),
          targetsByKind[d.contextKind] || new Set<string>(),
        );
        return targetsByKind;
      },
      {},
    );
    // this clues the backend that this instruction should add both a target AND an expiration date for that target
    expiringTargetInstructions = patches.map((d) =>
      allAddedTargetsByContextKind[d.contextKind]?.has(d.contextKey) ? { addTarget: true, ...d } : d,
    );

    const expiringTargetsByContextKind = patches.reduce(
      (acc: { [contextKind: string]: Set<string> | undefined }, d) => {
        const targetsByKind = acc;
        if (!targetsByKind[d.contextKind]) {
          targetsByKind[d.contextKind] = new Set();
        }

        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        targetsByKind[d.contextKind]!.add(d.contextKey); /* eslint-enable @typescript-eslint/no-non-null-assertion */
        return targetsByKind;
      },
      {},
    );

    // for add target instructions, only include those that DO NOT have expiration dates
    // we include instructions with expiration dates separately in the return object of this function
    updatedInstructions = instructionList.reduce((acc, ins) => {
      if (ins.kind === TargetsInstructionKind.ADD_TARGETS) {
        const nonExpiringValues = ins.values.filter(
          (contextKey) => !expiringTargetsByContextKind[ins.contextKind]?.has(contextKey),
        );
        if (nonExpiringValues.length > 0) {
          acc.push({ ...ins, values: nonExpiringValues });
        }
      } else {
        acc.push(ins);
      }
      return acc;
    }, [] as SemanticInstruction[]);
  }

  return { updatedInstructions, expiringTargetInstructions };
};

export type ApprovalRequestReviewProps = {
  _id: string;
  creationDate: number;
  kind: ApprovalsReviewKind;
  memberId?: string;
  serviceTokenId?: string;
  comment: string;
  workflowVersion: number;
};
export class ApprovalRequestReview extends Record<ApprovalRequestReviewProps>({
  _id: '',
  creationDate: 0,
  kind: ApprovalsReviewKind.DEFAULT,
  memberId: '',
  serviceTokenId: undefined,
  comment: '',
  workflowVersion: -1,
}) {
  getId() {
    return this._id;
  }
}

export const createApprovalRequestReview = (props: Partial<ApprovalRequestReviewProps> | ApprovalRequestReview = {}) =>
  props instanceof ApprovalRequestReview ? props : new ApprovalRequestReview(fromJS(props));

export const reviewKindToApprovalReviewStatus = {
  [ApprovalsReviewKind.DEFAULT]: ApprovalsReviewStatus.DEFAULT,
  [ApprovalsReviewKind.APPROVE]: ApprovalsReviewStatus.APPROVED,
  [ApprovalsReviewKind.DECLINE]: ApprovalsReviewStatus.DECLINED,
  [ApprovalsReviewKind.COMMENT]: ApprovalsReviewStatus.COMMENTED,
};

export type ApprovalRequestOptions = { shouldNotify?: boolean; notificationKind?: WorkflowNotificationKind };

/**
 * Returns a map keyed by instruction index
 * of instructions that will cause the proposed change to fail
 * against the current flag state
 * and the reason they will fail
 */
export const getInstructionIndexToConflictInfoForApprovalRequests = (
  conflicts: List<ApprovalRequestConflict>,
  instructions: SemanticInstruction[],
) => {
  const instructionIndexToConflictInfo: InstructionIndexToConflictsInfo = {};

  instructions.forEach((instruction, index) => {
    conflicts.forEach((conflict) => {
      const conflictInstruction = conflict.get('instruction');

      if (conflictInstruction && isEqual(toJS(instruction), toJS(conflictInstruction))) {
        const conflictReason = conflict.get('reason');
        instructionIndexToConflictInfo[index] = {
          conflicts: [{ conflictReason, conflictKind: FlagConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL }],
          summaryConflictKind: FlagConflictKind.PROPOSED_APPROVED_CHANGES_WILL_FAIL,
        };
      }
    });
  });

  return instructionIndexToConflictInfo;
};

export type ReviewersCount = {
  numberOfTeams: number;
  numberOfMembers: number;
  totalMembersCount: number;
};

export const getReviewersCount = (members: string[], teams?: Team[]) => {
  const numberOfMembers = members.length;
  const numberOfTeams = teams?.length || 0;
  let teamMemberCount = 0;
  if (teams) {
    for (const team of teams) {
      if (team.members) {
        teamMemberCount += team.members.totalCount;
      }
    }
  }

  const totalMembersCount = numberOfMembers + teamMemberCount;
  return { numberOfTeams, numberOfMembers, totalMembersCount } as ReviewersCount;
};

export const getApprovalNotificationMessage = (reviewKind: ApprovalsReviewKind) => {
  if (reviewKind === ApprovalsReviewKind.COMMENT) {
    return 'Comment added successfully';
  }
  const reviewStatus = reviewKindToApprovalReviewStatus[reviewKind];
  return `Request ${reviewStatus}${reviewKind !== ApprovalsReviewKind.DECLINE ? ' successfully' : ''}`;
};

export const getApprovalErrorNotificationMessage = (reviewKind: ApprovalsReviewKind) => {
  if (reviewKind === ApprovalsReviewKind.COMMENT) {
    return 'Failed to add comment. Try again later.';
  }

  return `Failed to ${reviewKind} request. Try again later.`;
};

export const getApprovalAppliedNotificationMessage = (updatedApproval: ApprovalRequest) => {
  const resourceKind = updatedApproval.getResourceKindName();

  if (updatedApproval.status === 'failed') {
    return `Changes applied. ${capitalize(resourceKind)} update failed.`;
  }
  if (updatedApproval.instructions.isEmpty()) {
    return 'Scheduled changes deleted';
  }
  if (updatedApproval.executionDate) {
    return 'Changes scheduled';
  }
  return `Changes applied. ${capitalize(resourceKind)} updated.`;
};

export const getApprovalApplyFailedNotificationMessage = () => 'Failed to apply changes. Try again later.';

export const getAddReviewersSemanticPatchInstructions = (notifyMemberIds?: string[], notifyTeamKeys?: string[]) => [
  {
    kind: ApprovalInstructionKindForMultipleValueResources.ADD_REVIEWERS,
    notifyMemberIds,
    notifyTeamKeys,
  },
];

export enum ApprovalInstructionKindForMultipleValueResources {
  ADD_REVIEWERS = 'addReviewers',
}

export type ApprovalsSemanticPatchInstructions = { kind: ApprovalInstructionKindForMultipleValueResources } & {
  notifyMemberIds?: string[];
  notifyTeamKeys?: string[];
};

export type ApprovalRequestSummaryProps = ApprovalRequestProps &
  (
    | {
        project: Project;
        environment: Environment;
        flag: Flag;
      }
    | { project: Project; environments: List<Environment>; flag: Flag }
  );

export type ApprovalRequestSummary = ReturnType<Record.Factory<ApprovalRequestSummaryProps>> &
  InstanceType<typeof ApprovalRequest>;

export type ApprovalRequestsResponse<T extends ApprovalRequest = ApprovalRequest> = Readonly<{
  entities: Readonly<{ approvalRequests?: Readonly<{ [_id: string]: T }> }>;
  result: Readonly<{ items?: readonly string[]; totalCount: number; links: { self: { href: string; type: string } } }>;
}>;

export type ApprovalRequestSummariesResponse = ApprovalRequestsResponse<ApprovalRequestSummary>;
export type ApprovalRequestSummariesListResponse = Readonly<
  Omit<ApprovalRequestSummariesResponse['result'], 'items'> & {
    items: readonly ApprovalRequestSummary[];
  }
>;
