import { ReactElement, useCallback, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { memberFilterPageSize } from '@gonfalon/dogfood-flags';
import { debounce, noop } from '@gonfalon/es6-utils';
import { OptionProps } from '@gonfalon/launchpad-experimental';
import { OrderedMap, Set } from 'immutable';
import { Label } from 'launchpad';

import { loadSelectedMembersAction, saveSelectedMembersAction, selectMemberSearchAction } from 'actions/selectMember';
import SelectMember, { SelectMemberProps } from 'components/SelectMember';
import { GlobalState } from 'reducers';
import { profileSelector } from 'reducers/profile';
import {
  filteredMemberListSelector,
  selectedMembersSelector,
  selectMemberIsSearching,
  selectMemberSearchValueSelector,
} from 'reducers/selectMemberReducer';
import { getMembers } from 'sources/AccountAPI';
import { Member } from 'utils/accountUtils';
import Logger from 'utils/logUtils';
import { createMemberFilters } from 'utils/memberUtils';

const logger = Logger.get('SelectMemberContainer');

export type MemberOptionType = OptionProps & {
  email: string;
  name?: string;
  isAdminOrOwner?: boolean;
  isCurrentMember?: boolean;
};

function isMember(t: unknown): t is Member {
  return t instanceof Member;
}
const membersToOrderedMembers = (members: Member[]) => OrderedMap(members.map((m) => [m.get('_id'), m]));

export type SelectMemberContainerProps = {
  accessCheck?: string;
  className?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  isMulti?: boolean;
  value?: Member[] | string | null;
  onChange(member?: Member | null | Member[]): void;
  filterPendingInvites?: boolean;
  isClearable?: boolean;
  valueContainerStyles?: object;
  menuPortalStyles?: object;
  menuStyles?: object;
  onBlur?(): void;
  closeMenuOnSelect?: boolean;
  filterOutCurrentMember?: boolean;
  filterMembers?: (m: Member) => boolean;
  // filter members from the list of members available to select by their ID
  filterByMemberId?: Set<string>;
  suggestedMemberIds?: string[];
  isInPortal?: boolean;
  disabled?: boolean;
  profile?: Member;
  isTeamsMemberContainer?: boolean;
  customMultiValueRemoveTooltip?: string;
  customNoOptionsMessage?: ReactElement | string;
  ariaLabel?: string;
  showLabel?: boolean;
} & Pick<SelectMemberProps, 'menuPosition'>;

export const useRedux = () => {
  const dispatch = useDispatch();
  const isSearching = useSelector(selectMemberIsSearching);
  const searchValue = useSelector(selectMemberSearchValueSelector);
  const filteredMembersList: OrderedMap<string, Member> = useSelector(filteredMemberListSelector);
  const profile = useSelector((state: GlobalState) => profileSelector(state));

  const saveSelectedMembers: (members: Member[]) => void = (members) =>
    dispatch(saveSelectedMembersAction(membersToOrderedMembers(members)));
  const selectedMembers: OrderedMap<string, Member> = useSelector(selectedMembersSelector);
  const selectMemberLoad = (loadedMembers: OrderedMap<string, Member>) =>
    dispatch(loadSelectedMembersAction(loadedMembers));
  const selectMemberSearch = useCallback((filterValue: string) => dispatch(selectMemberSearchAction(filterValue)), []);
  return {
    filteredMembersList,
    isSearching,
    profile: profile?.get('entity'),
    saveSelectedMembers,
    searchValue,
    selectedMembers,
    selectMemberLoad,
    selectMemberSearch,
  };
};

/* eslint-disable import/no-default-export */
export default function SelectMemberContainer({
  accessCheck,
  isMulti,
  value,
  disabled,
  closeMenuOnSelect,
  ariaLabel,
  showLabel = false,
  onChange,
  ...props
}: SelectMemberContainerProps) {
  const {
    filteredMembersList,
    isSearching,
    profile,
    saveSelectedMembers,
    searchValue,
    selectedMembers,
    selectMemberLoad,
    selectMemberSearch,
  } = useRedux();

  useEffect(() => {
    if (value && Array.isArray(value)) {
      saveSelectedMembers(value);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    getMembers(createMemberFilters({ query: searchValue, limit: memberFilterPageSize(), accessCheck }), {
      signal: controller.signal,
    }).then(
      (data) =>
        !controller.signal.aborted &&
        selectMemberLoad(
          data
            ?.get('entities')
            ?.get('members')
            ?.merge({ [profile._id]: profile }),
        ),
      noop,
    );

    return () => controller.abort();
  }, [searchValue]);

  let finalValue: Member | Member[] | null = null;

  if (process.env.NODE_ENV !== 'production') {
    // We want multiple values, but something is not matching up
    if (isMulti && !Array.isArray(value)) {
      logger.warn('you must provide an array value if isMulti=true');
    }

    if (!isMulti && Array.isArray(value)) {
      logger.warn('you must set isMulti to true if value is an array');
    }

    if (isMulti && Array.isArray(value) && !value.every(isMember)) {
      logger.warn('you must pass an array of Member records if isMulti=true');
    }
  }

  if (typeof value === 'string') {
    finalValue = filteredMembersList?.get(value) || selectedMembers?.get(value) || null; // null is for react-select…
  }

  if (Array.isArray(value) && value.every(isMember)) {
    finalValue = value;
  }

  function handleSearch(newSearchValue: string) {
    selectMemberSearch(newSearchValue);
  }

  const debounceSearch = useMemo(() => debounce(handleSearch, 300), [selectMemberSearch]);

  function handleChange(nextValue: string | string[] | null) {
    if (nextValue === null) {
      handleChange(null);
      return;
    }

    const memberIds = Array.isArray(nextValue) ? nextValue : [nextValue];
    const members: Member[] = [];

    for (const id of memberIds) {
      const member = filteredMembersList?.get(id) || selectedMembers?.get(id);
      if (member) {
        members.push(member);
      }
    }

    // cache the selected member(s) if we already have them
    saveSelectedMembers(members);

    // update the parent (which will update the URL, etc…)
    onChange(Array.isArray(nextValue) ? members : members[0]);
  }

  return (
    <>
      {showLabel && ariaLabel && props.id && <Label htmlFor={props.id}>{ariaLabel}</Label>}
      <SelectMember
        {...props}
        onChange={handleChange}
        onInputChange={debounceSearch}
        isLoading={isSearching}
        isMulti={isMulti}
        disabled={disabled}
        profile={profile}
        ariaLabel={!showLabel ? ariaLabel : ''}
        members={filteredMembersList}
        value={finalValue}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </>
  );
}
