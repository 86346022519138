import { useCallback } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from 'react-error-boundary';
import { isRESTAPIError } from '@gonfalon/rest-api';
import { type RUMCustomErrorContext, ErrorSeverity, trackComponentError } from '@gonfalon/telemetry';

export type ErrorBoundaryProps = ReactErrorBoundaryProps & {
  severity: ErrorSeverity;
  ignoredStatuses?: number[];
};

type RequiredOnError = NonNullable<ErrorBoundaryProps['onError']>;

/*
 * This is a wrapper around the `react-error-boundary` component, with the addition of built-in error tracking with a specified severity.
 * You will need to provide a fallback to render in case of an error.
 *
 * Severity levels are as follows;
 * - critical: Only used for the root boundary, when the entire app crashes.
 * - high: A route-level crash, where an entire view is broken (but the app chrome is still available).
 * - medium: A component-level crash, where the view still renders but an important feature is broken.
 * - low: Infrequently used, for minor issues that don't significantly affect the user experience.
 */
export function ErrorBoundary({ severity, ignoredStatuses = [], children, ...props }: ErrorBoundaryProps) {
  const handleError = useCallback<RequiredOnError>(
    (error, info) => {
      const context: RUMCustomErrorContext = { severity };

      if (isRESTAPIError(error)) {
        if (ignoredStatuses.includes(error.status)) {
          return;
        }

        context.url = error.url;
      }

      trackComponentError(error, info, context);
      props.onError?.(error, info);
    },
    [ignoredStatuses, props.onError],
  );

  return (
    <ReactErrorBoundary onError={handleError} {...props}>
      {children}
    </ReactErrorBoundary>
  );
}
