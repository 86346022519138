import { and, anyOf, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetAIConfigsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetAIConfigsQueryParams = Omit<GetAIConfigsOpenAPIQueryParams, 'filter' | 'sort'> & {
  sort?: 'name' | '-name' | 'creationDate' | '-creationDate' | 'lastModified' | '-lastModified';
  filter?: {
    query?: string;
    tags?: string[];
  };
};

export function querySerializer(queryParams: GetAIConfigsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    const serializedFilter = serializeFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  return searchParams.toString();
}

function serializeFilterParam(value: NonNullable<GetAIConfigsQueryParams['filter']>) {
  const filter: QueryFilter[] = [];

  if (value.query) {
    filter.push(equals('query', value.query));
  }

  if (value.tags?.length) {
    filter.push(anyOf('tags', value.tags));
  }

  return queryfilterToString(and(filter));
}
