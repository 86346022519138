export type AccountResource = {
  type: 'acct';
};

export type ApplicationResource = {
  type: 'application';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type ContextKindResource = {
  type: 'context-kind';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type ReleasePipelineResource = {
  type: 'release-pipeline';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type CodeReferencesRepositoryResource = {
  type: 'code-reference-repository';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type DestinationResource = {
  type: 'destination';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type DomainVerificationResource = {
  type: 'domain-verification';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type EnvironmentResource = {
  type: 'env';
  project: ProjectResource;
  name: string;
  tags: string[];
  critical?: boolean;
  roleAttribute?: string;
};

export type PropertySelectors = {
  critical?: boolean;
};

export type ExperimentResource = {
  type: 'experiment';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type FlagResource = {
  // feature is a deprecated type. Equates to flag.
  type: 'flag' | 'feature';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type IntegrationResource = { type: 'integration'; name: string; tags: string[]; roleAttribute?: string };

export type LayerResource = {
  type: 'layer';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type HoldoutResource = {
  type: 'holdout';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type MemberResource = { type: 'member'; name: string; tags: string[]; roleAttribute?: string };

export type MetricResource = {
  type: 'metric';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type MetricGroup = {
  type: 'metric-group';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type GoalResource = {
  type: 'goal';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type ProjectResource = { type: 'proj'; name: string; tags: string[]; roleAttribute?: string };

export type RelayProxyConfigResource = {
  type: 'relay-proxy-config';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type RoleResource = { type: 'role'; name: string; tags: string[]; roleAttribute?: string };

export type SegmentResource = {
  type: 'segment';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type ServiceTokenResource = { type: 'service-token'; name: string; tags: string[]; roleAttribute?: string };

export type PendingRequestResource = { type: 'pending-request'; name: string; tags: string[]; roleAttribute?: string };

export type TeamResource = { type: 'team'; name: string; tags: string[]; roleAttribute?: string };

export type TemplateResource = { type: 'template'; name: string; tags: string[]; roleAttribute?: string };

export type TokenResource = {
  type: 'token';
  member: MemberResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type UserResource = {
  type: 'user';
  environment: EnvironmentResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type PayloadFilterResource = {
  type: 'payload-filter';
  project: ProjectResource;
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type WebhookResource = { type: 'webhook'; name: string; tags: string[]; roleAttribute?: string };

export type AiConfigResource = {
  project: ProjectResource;
  type: 'aiconfig';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type AiModelConfigResource = {
  project: ProjectResource;
  type: 'ai-model-config';
  name: string;
  tags: string[];
  roleAttribute?: string;
};

export type ResourceSpecifier =
  | AiConfigResource
  | AiModelConfigResource
  | AccountResource
  | CodeReferencesRepositoryResource
  | DestinationResource
  | EnvironmentResource
  | ExperimentResource
  | FlagResource
  | GoalResource
  | HoldoutResource
  | IntegrationResource
  | LayerResource
  | MemberResource
  | MetricResource
  | MetricGroup
  | PayloadFilterResource
  | PendingRequestResource
  | ProjectResource
  | RelayProxyConfigResource
  | RoleResource
  | SegmentResource
  | ServiceTokenResource
  | TeamResource
  | TemplateResource
  | TokenResource
  | UserResource
  | WebhookResource
  | ApplicationResource
  | ReleasePipelineResource
  | ContextKindResource
  | DomainVerificationResource;

export type ResourceType = ResourceSpecifier['type'];

export function isResourceType(v: unknown): v is ResourceType {
  if (typeof v !== 'string') {
    return false;
  }

  switch (v) {
    case 'acct':
    case 'application':
    case 'code-reference-repository':
    case 'destination':
    case 'domain-verification':
    case 'env':
    case 'experiment':
    case 'feature':
    case 'flag':
    case 'goal':
    case 'holdout':
    case 'integration':
    case 'layer':
    case 'member':
    case 'metric':
    case 'metric-group':
    case 'payload-filter':
    case 'pending-request':
    case 'proj':
    case 'relay-proxy-config':
    case 'release-pipeline':
    case 'role':
    case 'segment':
    case 'service-token':
    case 'team':
    case 'template':
    case 'token':
    case 'user':
    case 'webhook':
    case 'context-kind':
    case 'aiconfig':
    case 'ai-model-config':
      return true;
  }

  return false;
}

export function account(): AccountResource {
  return { type: 'acct' };
}

export function application(name: string, tags: string[] = [], roleAttribute?: string): ApplicationResource {
  return { type: 'application', name, tags, roleAttribute };
}

export function domainVerification(
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): DomainVerificationResource {
  return { type: 'domain-verification', name, tags, roleAttribute };
}

export function releasePipeline(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): ReleasePipelineResource {
  return { type: 'release-pipeline', project: _project, name, tags, roleAttribute };
}

export function repository(
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): CodeReferencesRepositoryResource {
  return { type: 'code-reference-repository', name, tags, roleAttribute };
}

export function destination(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): DestinationResource {
  return {
    type: 'destination',
    environment: _environment,
    name,
    tags,
    roleAttribute,
  };
}

export function environment(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  critical?: boolean,
  roleAttribute?: string,
): EnvironmentResource {
  return { type: 'env', project: _project, name, tags, critical, roleAttribute };
}

export function experiment(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): ExperimentResource {
  return { type: 'experiment', environment: _environment, name, tags, roleAttribute };
}

export function flag(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): FlagResource {
  return { type: 'flag', environment: _environment, name, tags, roleAttribute };
}

export function goal(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): GoalResource {
  return { type: 'goal', project: _project, name, tags, roleAttribute };
}

export function holdout(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): HoldoutResource {
  return { type: 'holdout', environment: _environment, name, tags, roleAttribute };
}

export function integration(name: string, tags: string[] = [], roleAttribute?: string): IntegrationResource {
  return { type: 'integration', name, tags, roleAttribute };
}

export function layer(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): LayerResource {
  return { type: 'layer', project: _project, name, tags, roleAttribute };
}

export function member(name: string, tags: string[] = [], roleAttribute?: string): MemberResource {
  return { type: 'member', name, tags, roleAttribute };
}

export function metric(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): MetricResource {
  return { type: 'metric', project: _project, name, tags, roleAttribute };
}

export function metricGroup(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): MetricGroup {
  return { type: 'metric-group', project: _project, name, tags, roleAttribute };
}

export function payloadFilter(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): PayloadFilterResource {
  return { type: 'payload-filter', project: _project, name, tags, roleAttribute };
}

export function project(name: string, tags: string[] = [], roleAttribute?: string): ProjectResource {
  return { type: 'proj', name, tags, roleAttribute };
}

export function pendingRequest(name: string, tags: string[] = [], roleAttribute?: string): PendingRequestResource {
  return { type: 'pending-request', name, tags, roleAttribute };
}

export function relayProxyConfig(name: string, tags: string[] = [], roleAttribute?: string): RelayProxyConfigResource {
  return { type: 'relay-proxy-config', name, tags, roleAttribute };
}

export function role(name: string, tags: string[] = [], roleAttribute?: string): RoleResource {
  return { type: 'role', name, tags, roleAttribute };
}

export function segment(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): SegmentResource {
  return { type: 'segment', environment: _environment, name, tags, roleAttribute };
}

export function serviceToken(name: string, tags: string[] = [], roleAttribute?: string): ServiceTokenResource {
  return { type: 'service-token', name, tags, roleAttribute };
}

export function team(name: string, tags: string[] = [], roleAttribute?: string): TeamResource {
  return { type: 'team', name, tags, roleAttribute };
}

export function template(name: string, tags: string[] = [], roleAttribute?: string): TemplateResource {
  return { type: 'template', name, tags, roleAttribute };
}

export function contextKind(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): ContextKindResource {
  return { type: 'context-kind', project: _project, name, tags, roleAttribute };
}

export function token(
  _member: MemberResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): TokenResource {
  return { type: 'token', member: _member, name, tags, roleAttribute };
}

export function user(
  _environment: EnvironmentResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): UserResource {
  return { type: 'user', environment: _environment, name, tags, roleAttribute };
}

export function webhook(name: string, tags: string[] = [], roleAttribute?: string): WebhookResource {
  return { type: 'webhook', name, tags, roleAttribute };
}

export function aiConfig(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): AiConfigResource {
  return { type: 'aiconfig', project: _project, name, tags, roleAttribute };
}

export function aiModelConfig(
  _project: ProjectResource,
  name: string,
  tags: string[] = [],
  roleAttribute?: string,
): AiModelConfigResource {
  return { type: 'ai-model-config', project: _project, name, tags, roleAttribute };
}

export function toReadableString(node: ResourceSpecifier): string {
  const getNameAndTag = (singular: string, plural: string, n: ResourceSpecifier) => {
    let result = '';
    if (n.type === 'acct') {
      return result;
    }
    if (n.type === 'env' && n.critical !== undefined && n.critical === true) {
      result = n.name !== '*' ? `**Critical ${singular}**: ${n.name}` : `**All Critical ${plural}**`;
    } else if (n.type === 'env' && n.critical !== undefined && n.critical === false) {
      result = n.name !== '*' ? `**Non-critical ${singular}**: ${n.name}` : `**All Non-critical ${plural}**`;
    } else {
      result = n.name !== '*' ? `**${singular}**: ${n.name}` : `**All ${plural}**`;
    }

    if (n.tags.length > 0) {
      result += ` with **Tags**: [${n.tags.join(', ')}]`;
    }
    return result;
  };

  let result = '';
  switch (node.type) {
    case 'acct':
      result = '**Account**';
      break;
    case 'application':
      result = getNameAndTag('Application', 'Applications', node);
      break;
    case 'code-reference-repository':
      result = getNameAndTag('Code Reference Repository', 'code reference repositories', node);
      break;
    case 'destination':
      result = [toReadableString(node.environment), getNameAndTag('Destination', 'destinations', node)].join(', ');
      break;
    case 'domain-verification':
      result = getNameAndTag('Domain Verification', 'Domain Verifications', node);
      break;
    case 'env':
      result = [toReadableString(node.project), getNameAndTag('Environment', 'Environments', node)].join(', ');
      break;
    case 'experiment':
      result = [toReadableString(node.environment), getNameAndTag('Experiment', 'Experiments', node)].join(', ');
      break;
    case 'flag':
      result = [toReadableString(node.environment), getNameAndTag('Flag', 'Flags', node)].join(', ');
      break;
    case 'goal':
      result = [toReadableString(node.project), getNameAndTag('Goal', 'Goals', node)].join(', ');
      break;
    case 'holdout':
      result = [toReadableString(node.environment), getNameAndTag('Holdout', 'Holdouts', node)].join(', ');
      break;
    case 'integration':
      result = getNameAndTag('Integration', 'Integrations', node);
      break;
    case 'layer':
      result = [toReadableString(node.project), getNameAndTag('Layer', 'Layers', node)].join(', ');
      break;
    case 'member':
      result = getNameAndTag('Member', 'Members', node);
      break;
    case 'metric':
      result = [toReadableString(node.project), getNameAndTag('Metric', 'Metrics', node)].join(', ');
      break;
    case 'metric-group':
      result = [toReadableString(node.project), getNameAndTag('Metric Group', 'Metric Groups', node)].join(', ');
      break;
    case 'context-kind':
      result = [toReadableString(node.project), getNameAndTag('Context Kind', 'Context Kinds', node)].join(', ');
      break;
    case 'pending-request':
      result = getNameAndTag('Pending Request', 'Pending Requests', node);
      break;
    case 'payload-filter':
      result = [toReadableString(node.project), getNameAndTag('Payload Filter', 'Payload Filters', node)].join(', ');
      break;
    case 'proj':
      result = getNameAndTag('Project', 'Projects', node);
      break;
    case 'relay-proxy-config':
      result = getNameAndTag('Relay Proxy Config', 'Relay Proxy Configs', node);
      break;
    case 'release-pipeline':
      result = [toReadableString(node.project), getNameAndTag('Release Pipeline', 'Release Pipelines', node)].join(
        ', ',
      );
      break;
    case 'role':
      result = getNameAndTag('Role', 'Roles', node);
      break;
    case 'segment':
      result = [toReadableString(node.environment), getNameAndTag('Segment', 'Segments', node)].join(', ');
      break;
    case 'service-token':
      result = getNameAndTag('Service Token', 'Service Tokens', node);
      break;
    case 'team':
      result = getNameAndTag('Team', 'Teams', node);
      break;
    case 'template':
      result = getNameAndTag('Template', 'Templates', node);
      break;
    case 'token':
      result = [getNameAndTag('Member', 'Members', node.member), getNameAndTag('Token', 'Tokens', node)].join(', ');
      break;
    case 'user':
      result = [toReadableString(node.environment), getNameAndTag('User', 'Users', node)].join(', ');
      break;
    case 'webhook':
      result = getNameAndTag('Webhook', 'Webhooks', node);
      break;
    case 'aiconfig':
      result = [toReadableString(node.project), getNameAndTag('AI Config', 'AI Configs', node)].join(', ');
      break;
    case 'ai-model-config':
      result = [toReadableString(node.project), getNameAndTag('AI Model Config', 'AI Model Configs', node)].join(', ');
      break;
  }
  return result;
}

/**
 * Convert an AST to a string.
 *
 * Note that all (expression) operators (and/or) have equal
 * precedence so we don't both with parentheses when
 * serializing. Should we add operators with higher precedence
 * that will need to change (along with the rest of the parser).
 *
 */
export function toString(node: ResourceSpecifier): string {
  function stringifyTags(tags: string[]) {
    let metaTag: string | undefined;
    if (node.type === 'env' && node.critical !== undefined) {
      metaTag = `\{critical:${node.critical}}`;
    }
    if (tags.length === 0 && metaTag === undefined) {
      return '';
    }

    const sorted = Array.from(tags).sort();
    if (metaTag) {
      return `;${[...sorted, metaTag].join(',')}`;
    }
    return `;${sorted.join(',')}`;
  }

  function getName() {
    switch (node.type) {
      case 'acct':
        return '';
      default:
        if (!!node.roleAttribute) {
          return `\${roleAttribute/${node.roleAttribute}}`;
        }
        return node.name;
    }
  }

  switch (node.type) {
    case 'acct':
      return node.type;

    // root scoped
    case 'code-reference-repository':
    case 'application':
    case 'domain-verification':
    case 'proj':
    case 'integration':
    case 'member':
    case 'relay-proxy-config':
    case 'role':
    case 'pending-request':
    case 'service-token':
    case 'team':
    case 'template':
    case 'webhook':
      return `${node.type}/${getName()}${stringifyTags(node.tags)}`;

    // project scoped
    case 'aiconfig':
    case 'ai-model-config':
    case 'env':
    case 'payload-filter':
    case 'layer':
    case 'metric':
    case 'metric-group':
    case 'goal':
    case 'release-pipeline':
    case 'context-kind':
      return `${toString(node.project)}:${node.type}/${getName()}${stringifyTags(node.tags)}`;

    // proj + environment scoped
    case 'destination':
    case 'flag':
    case 'feature':
    case 'segment':
    case 'user':
    case 'holdout':
    case 'experiment':
      return `${toString(node.environment)}:${node.type}/${getName()}${stringifyTags(node.tags)}`;

    case 'token':
      return `${toString(node.member)}:${node.type}/${getName()}${stringifyTags(node.tags)}`;
  }
}
