import { enableReleaseGuardianRefreshedUI } from '@gonfalon/dogfood-flags';
import { Map } from 'immutable';

import { MeasuredRolloutConfig } from 'utils/flagUtils';
import {
  InstructionCategory,
  InstructionKey,
  InstructionsType,
  SemanticInstruction,
} from 'utils/instructions/shared/types';

import { RuleInstructionKind, SemanticInstructionRollout } from '../rules/types';
import { getInstructionsByManyKinds } from '../shared/helpers';

import {
  OffVariationSemanticInstruction,
  OnOffInstructionKind,
  StopMeasuredRolloutOnFallthrough,
  TurnFlagOffSemanticInstruction,
  TurnFlagOnSemanticInstruction,
  UpdateFallthroughVariationOrRolloutSemanticInstruction,
  UpdateFallthroughWithMeasuredRolloutSemanticInstruction,
  UpdateFallthroughWithMeasuredRolloutV2SemanticInstruction,
  UpdateFallthroughWithProgressiveRolloutSemanticInstruction,
} from './types';

export function makeToggleFlagInstruction(isOn: boolean) {
  return isOn ? makeTurnFlagOffInstruction() : makeTurnFlagOnInstruction();
}

export function makeTurnFlagOnInstruction(): TurnFlagOnSemanticInstruction {
  return { kind: OnOffInstructionKind.TURN_FLAG_ON };
}
export function makeTurnFlagOffInstruction(): TurnFlagOffSemanticInstruction {
  return {
    kind: OnOffInstructionKind.TURN_FLAG_OFF,
  };
}

export function makeOffVariationInstruction(variationId: string | null): OffVariationSemanticInstruction {
  return { kind: OnOffInstructionKind.UPDATE_OFF_VARIATION, variationId: variationId || null };
}

const updateFallthroughVariationOrRolloutSemanticInstruction: UpdateFallthroughVariationOrRolloutSemanticInstruction = {
  kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
  contextKind: undefined,
  rolloutWeights: undefined,
  rolloutBucketBy: undefined,
  rolloutContextKind: undefined,
  variationId: undefined,
  experimentAllocation: undefined,
};

export function makeUpdateFallthroughVariationInstruction(
  variationId: string,
): UpdateFallthroughVariationOrRolloutSemanticInstruction {
  return {
    ...updateFallthroughVariationOrRolloutSemanticInstruction,
    variationId,
  };
}

export function makeStopMeasuredRolloutOnFallthroughInstruction(
  finalVariationId: string,
  comment: string,
): StopMeasuredRolloutOnFallthrough {
  return {
    ...updateFallthroughVariationOrRolloutSemanticInstruction,
    kind: OnOffInstructionKind.STOP_MEASURED_ROLLOUT_ON_FALLTHROUGH,
    finalVariationId,
    comment,
  };
}

export function makeUpdateFallthroughRolloutInstruction(
  rollout: SemanticInstructionRollout,
): UpdateFallthroughVariationOrRolloutSemanticInstruction {
  const { bucketBy, contextKind, experimentAllocation, weights } = rollout;
  return {
    ...updateFallthroughVariationOrRolloutSemanticInstruction,
    rolloutWeights: weights,
    rolloutBucketBy: bucketBy,
    rolloutContextKind: contextKind,
    experimentAllocation,
  };
}

export function makeUpdateFallthroughVariationOrRolloutInstruction(
  props: Partial<UpdateFallthroughVariationOrRolloutSemanticInstruction> = {},
): UpdateFallthroughVariationOrRolloutSemanticInstruction {
  return {
    ...updateFallthroughVariationOrRolloutSemanticInstruction,
    ...props,
    kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
  };
}

export function makeUpdateFallthroughWithMeasuredRolloutInstruction(
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): UpdateFallthroughWithMeasuredRolloutSemanticInstruction {
  return {
    kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeUpdateFallthroughWithMeasuredRolloutV2Instruction(
  testVariationId: string,
  controlVariationId: string,
  config: MeasuredRolloutConfig,
): UpdateFallthroughWithMeasuredRolloutV2SemanticInstruction {
  return {
    kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2,
    testVariationId,
    controlVariationId,
    ...config.toRep(),
  };
}

export function makeUpdateFallthroughWithProgressiveRolloutInstruction(
  props: Pick<
    UpdateFallthroughWithProgressiveRolloutSemanticInstruction,
    'progressiveRolloutConfiguration' | 'rolloutContextKind'
  >,
): UpdateFallthroughWithProgressiveRolloutSemanticInstruction {
  return {
    ...props,
    kind: OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
  };
}

export function combineOnOffInstructions(
  newInstruction: SemanticInstruction,
  pendingSemanticPatch: InstructionsType,
): InstructionsType {
  const existingOnOffInstruction = pendingSemanticPatch.get(InstructionCategory.ON_OFF);
  if (!existingOnOffInstruction) {
    // This ensures that we always put flag on/off instructions at the front of the instructions list
    const newMap = Map<InstructionKey, SemanticInstruction>([[InstructionCategory.ON_OFF, newInstruction]]);
    return newMap.merge(pendingSemanticPatch);
  }
  if (existingOnOffInstruction.kind !== newInstruction.kind) {
    return pendingSemanticPatch.delete(InstructionCategory.ON_OFF);
  }
  return pendingSemanticPatch;
}

export function filterSemanticInstructionsByOffVariationKind(semanticInstructions: SemanticInstruction[]) {
  return getInstructionsByManyKinds(semanticInstructions, [OnOffInstructionKind.UPDATE_OFF_VARIATION]);
}

export function filterSemanticInstructionsByFallthroughVariationKind(semanticInstructions: SemanticInstruction[]) {
  return getInstructionsByManyKinds(semanticInstructions, [
    OnOffInstructionKind.UPDATE_FALLTHROUGH_VARIATION_OR_ROLLOUT,
    enableReleaseGuardianRefreshedUI()
      ? OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2
      : OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT,
  ]);
}

export function findAllGuardedRolloutInstructions(semanticInstructions: SemanticInstruction[]): SemanticInstruction[] {
  return getInstructionsByManyKinds(semanticInstructions, [
    RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT,
    RuleInstructionKind.ADD_RULE_WITH_MEASURED_ROLLOUT_V2,
    RuleInstructionKind.UPDATE_RULE_WITH_MEASURED_ROLLOUT_V2,
    OnOffInstructionKind.UPDATE_FALLTHROUGH_WITH_MEASURED_ROLLOUT_V2,
  ]);
}
