import { Time } from '@gonfalon/datetime';
import { EnvironmentMarker } from '@gonfalon/environments';
import { Icon } from '@launchpad-ui/icons';
import { differenceInHours } from 'date-fns';

import styles from './MetricLastSeenInfo.module.css';
interface MetricLastSeenInfoProps {
  lastSeen?: number;
  environmentName?: string;
  environmentColor?: string;
}

export const MetricLastSeenInfo: React.FC<MetricLastSeenInfoProps> = ({
  lastSeen,
  environmentName,
  environmentColor,
}) => {
  const envSpan = environmentColor ? (
    <span className={styles.lastSeenDesc}>
      <EnvironmentMarker color={environmentColor} /> {environmentName}{' '}
    </span>
  ) : (
    <span>{environmentName}</span>
  );

  const icon =
    lastSeen === undefined
      ? 'circle-dashed'
      : differenceInHours(Date.now(), lastSeen) >= 24
        ? 'half-circle'
        : 'status-active';

  return (
    <div className={styles.lastSeenDesc}>
      <Icon name={icon} className={icon === 'status-active' ? styles.active : ''} size="small" />{' '}
      {lastSeen === undefined ? (
        'No events have been recieved for this metric'
      ) : (
        <span>
          Last seen <Time fromNow datetime={lastSeen} shortenUnits fuzzySeconds />
        </span>
      )}
      {environmentName && <span className={styles.lastSeenDesc}>in {envSpan}</span>}
    </div>
  );
};
