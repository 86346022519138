import { FormEvent, useCallback } from 'react';
import { useDebounce } from '@gonfalon/async';
import { approvalsNotificationListMaxLimit } from '@gonfalon/dogfood-flags';
import { trackReleaseStrategyBackClicked } from '@gonfalon/measured-rollouts';
import { pluralize } from '@gonfalon/strings';
import cx from 'clsx';
import { AbsoluteModalFooter, Button, Checkbox, RequiredAsterisk, TextField, Tooltip } from 'launchpad';

import CommentArea from 'components/forms/CommentArea';
import { InviteMembersOption } from 'components/inviteMembers/InviteMembersOption';
import { useLegacyReleaseStrategy } from 'components/LegacyReleaseStrategyModal';
import { ApprovalIntegrationFields, IntegrationConfig } from 'components/PendingChanges/ApprovalIntegrationFields';
import { PendingChangesActions } from 'components/PendingChanges/PendingChangesActions';
import RequiredFieldsNotice from 'components/RequiredFieldsNotice';
import { AutosizeTextArea, Form, FormField } from 'components/ui/forms';
import { useExternalState } from 'hooks/useExternalState';
import { LaunchDarklyIntegrationsManifest } from 'types/generated/integrationSchema';
import { Member } from 'utils/accountUtils';
import { trackFlagTargetingEvent } from 'utils/flagUtils';
import { FormState } from 'utils/formUtils';
import { PendingChangesFormRecord, PendingChangesFormValueType } from 'utils/pendingChangesUtils';
import { formattedRoleName, RoleName } from 'utils/roleUtils';
import { SaveOptions } from 'utils/saveButtonUtils';
import { Team } from 'utils/teamsUtils';
import { validateNotifyReviewersCount } from 'utils/validationUtils';

import SelectReviewersContainer from './FormSection/Approvals/SelectReviewersContainer';
import { usePendingChangesContext } from './PendingChangesContext';
import { PendingChangesDatePopover } from './PendingChangesDatePopover';

import './styles.css';

export type PendingChangesFormProps = {
  hideScheduleOption?: boolean;
  hideApprovalOption?: boolean;
  saveOption: SaveOptions;
  pendingChangesFormState: FormState<PendingChangesFormRecord>;
  resourceValidation: { label: string; placeholder: string; onValidate(val: string): boolean };
  suggestedApprovalMemberIds: string[];
  approvalServiceKind?: string;
  approvalServiceManifest?: LaunchDarklyIntegrationsManifest;
  flagKey?: string;
  isUserPendingChanges?: boolean;
  useBackButton?: boolean;
  absoluteModalFooterClassName?: string;
  isAIFlag?: boolean;
  onEditPendingChangesForm: (field: string, value: PendingChangesFormValueType) => void;
  onBlur(field: string): void;
  onBack?(): void;
  onCancel(): void;
  onSubmit(pendingChangesForm: PendingChangesFormRecord): void;
};

export function PendingChangesForm({
  hideScheduleOption,
  hideApprovalOption,
  saveOption,
  pendingChangesFormState,
  resourceValidation,
  suggestedApprovalMemberIds,
  approvalServiceKind,
  approvalServiceManifest,
  flagKey,
  isUserPendingChanges,
  useBackButton,
  absoluteModalFooterClassName,
  isAIFlag,
  onEditPendingChangesForm,
  onBlur,
  onBack,
  onCancel,
  onSubmit,
}: PendingChangesFormProps) {
  const { releaseStrategy } = useLegacyReleaseStrategy();
  const { environment, enableFilterByApprovers } = usePendingChangesContext();

  const pendingChangesForm = pendingChangesFormState.get('modified');
  const handleSelectReviewers = (members?: Member[], teams?: Team[]) => {
    const newMemberReviewers = !!members ? members : [];
    const newTeamReviewers = !!teams ? teams : undefined;
    onEditPendingChangesForm('reviewers', [...newMemberReviewers, ...(newTeamReviewers || [])]);
    onEditPendingChangesForm('notifyMembers', newMemberReviewers);
    newTeamReviewers && onEditPendingChangesForm('notifyTeams', newTeamReviewers);
  };
  const minNumberOfApprovals = environment.get('approvalSettings')?.get('minNumApprovals');
  const filterOutCurrentMember = !environment.get('approvalSettings')?.get('canReviewOwnRequest');
  const areReviewersRequired = pendingChangesForm.get('areReviewersRequired');
  const isConfirmationRequired = pendingChangesForm.get('isConfirmationRequired');
  const isCommentRequired = pendingChangesForm.get('isCommentRequired');
  const isAcknowledgeConflictsRequired = pendingChangesForm.get('isAcknowledgeConflictsRequired');
  const isScheduleSelected = pendingChangesForm.get('isScheduleSelected');
  const isApprovalSelected = pendingChangesForm.get('isApprovalSelected');
  const isLoadingConflicts = pendingChangesForm.get('isLoadingConflicts');
  const showCommentField = !isApprovalSelected && saveOption !== SaveOptions.DELETE;

  const hasRequiredFields =
    isApprovalSelected || isConfirmationRequired || isCommentRequired || isAcknowledgeConflictsRequired;

  const footerCTA =
    saveOption === SaveOptions.REQUEST_APPROVAL_AND_SCHEDULE ? SaveOptions.REQUEST_APPROVAL : saveOption;

  const handleSubmit = (event: FormEvent<EventTarget>) => {
    event.preventDefault();
    onSubmit(pendingChangesForm);
  };

  const handleCancel = () => {
    trackFlagTargetingEvent('Review And Save Cancel Button Clicked', { saveOption, flagKey });
    onCancel();
  };

  const handleBack = useCallback(() => {
    trackReleaseStrategyBackClicked();
    onBack?.();
  }, [onBack]);

  const onResourceConfirmationChange = useDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
    onEditPendingChangesForm('resourceConfirmation', event.target.value);
  }, 150);

  const customInviteMembersOption = (
    <InviteMembersOption
      onClick={() => onEditPendingChangesForm('isInviteMemberSelected', true)}
      component="PendingChangesForm"
      inviteMembersModalProps={{
        referrer: 'PendingChanges',
        defaultRole: RoleName.ADMIN,
        renderAlertForRoles: {
          roles: [RoleName.READER, RoleName.NO_ACCESS],
          renderAlertMessage: (selectedRole: RoleName) =>
            `Members with the ${formattedRoleName(
              selectedRole,
            ).toLowerCase()} role cannot review flag approval requests.`,
        },
        onClose: () => onEditPendingChangesForm('isInviteMemberSelected', false),
      }}
    />
  );

  return (
    <Form
      id="search-pending-changes" // adding search to id and name to prevent lastpass adding in autofill icon
      name="search-pending-changes"
      className="PendingChangesForm"
      onSubmit={handleSubmit}
    >
      {!(hideScheduleOption && hideApprovalOption) &&
        !isAIFlag &&
        (saveOption === SaveOptions.UPDATE_SCHEDULE ? (
          <PendingChangesDatePopover
            className="PendingChangesActions"
            date={pendingChangesForm.executionDate ? new Date(pendingChangesForm.executionDate) : undefined}
            onSetDate={(date: number) => {
              onEditPendingChangesForm('executionDate', date);
            }}
            useLongDate
          />
        ) : (
          <PendingChangesActions
            hideScheduleOption={hideScheduleOption}
            hideApprovalOption={hideApprovalOption}
            isScheduleSelected={isScheduleSelected}
            executionDate={pendingChangesForm.executionDate}
            isApprovalSelected={isApprovalSelected}
            onEditPendingChangesForm={onEditPendingChangesForm}
          />
        ))}
      <section
        id="approval-region"
        className="PendingChangesForm-formFields"
        aria-labelledby={isApprovalSelected && !hideApprovalOption ? 'approval-accordion' : undefined}
      >
        {hasRequiredFields && <RequiredFieldsNotice />}
        {isApprovalSelected && (
          <FormField
            isRequired
            className={cx({
              'PendingChangesForm-approvalDescription--approvalOptionallySelected':
                isApprovalSelected && !hideApprovalOption,
            })}
            label="Reason"
            name="description"
            htmlFor="description"
            hint="Add a brief description of your changes."
            formState={pendingChangesFormState}
            onBlur={onBlur}
          >
            <DescriptionField
              value={pendingChangesForm.description}
              onChange={(description) => onEditPendingChangesForm('description', description)}
            />
          </FormField>
        )}
        {isApprovalSelected && areReviewersRequired && (
          <FormField
            isRequired
            label="Reviewers"
            name="notifyMembers"
            htmlFor="notifyMembers"
            hint={
              minNumberOfApprovals
                ? `Minimum ${minNumberOfApprovals} ${pluralize(
                    'approval',
                    minNumberOfApprovals,
                  )} required to apply these changes`
                : 'Select at least 1 reviewer to be notified.'
            }
            formState={pendingChangesFormState}
            onBlur={onBlur}
          >
            <SelectReviewersContainer
              id="notifyMembers"
              resourceKind="FlagConfiguration"
              resourceKey={flagKey}
              useAccessCheck={enableFilterByApprovers && !isUserPendingChanges}
              showGroupOptions={enableFilterByApprovers && !isUserPendingChanges}
              value={[...(pendingChangesForm.notifyTeams || []), ...pendingChangesForm.notifyMembers]}
              isInPortal
              filterOutCurrentMember={filterOutCurrentMember}
              suggestedMemberIds={suggestedApprovalMemberIds}
              onChange={handleSelectReviewers}
              customNoOptionsMessage={customInviteMembersOption}
            />
          </FormField>
        )}

        {showCommentField && (
          <FormField
            isRequired={isCommentRequired}
            label="Comment"
            name="comment"
            htmlFor="comment"
            formState={pendingChangesFormState}
            onBlur={onBlur}
          >
            <CommentField
              value={pendingChangesForm.comment}
              onChange={(newComment) => {
                onEditPendingChangesForm('comment', newComment);
              }}
            />
          </FormField>
        )}
        {isApprovalSelected && approvalServiceManifest && approvalServiceKind && (
          <ApprovalIntegrationFields
            manifest={approvalServiceManifest}
            approvalServiceKind={approvalServiceKind}
            onChange={(integrationConfig: IntegrationConfig) => {
              onEditPendingChangesForm('integrationConfig', integrationConfig);
            }}
          />
        )}
        {!isApprovalSelected && isConfirmationRequired && (
          <FormField
            isRequired
            label="Confirm"
            name="resourceConfirmation"
            htmlFor="resourceConfirmation"
            hint={resourceValidation.label}
            formState={pendingChangesFormState}
            onBlur={onBlur}
          >
            <TextField
              placeholder={resourceValidation.placeholder}
              onChange={onResourceConfirmationChange}
              id="resourceConfirmation"
            />
          </FormField>
        )}
        {!isApprovalSelected && !isLoadingConflicts && isAcknowledgeConflictsRequired && (
          <FormField
            className="PendingChangesForm-acknowledgeConflicts"
            isRequired
            name="acknowledgeConflicts"
            htmlFor="acknowledgeConflicts"
            formState={pendingChangesFormState}
            onBlur={onBlur}
          >
            <Checkbox
              checked={pendingChangesForm.acknowledgeConflicts}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onEditPendingChangesForm('acknowledgeConflicts', event.target.checked);
              }}
            >
              <p>
                I acknowledge saving this change will introduce conflicts with pending changes
                <RequiredAsterisk />
              </p>
            </Checkbox>
          </FormField>
        )}
      </section>
      <AbsoluteModalFooter
        className={absoluteModalFooterClassName}
        primaryButton={
          <Tooltip
            content={
              hasRequiredFields &&
              validateNotifyReviewersCount(
                approvalsNotificationListMaxLimit(),
                pendingChangesForm.get('notifyTeams'),
                pendingChangesForm.get('notifyMembers'),
              ) &&
              !pendingChangesFormState.canSubmitForm()
                ? `You may only specify at most ${approvalsNotificationListMaxLimit()} reviewers`
                : ''
            }
          >
            <Button
              type="submit"
              kind={saveOption === SaveOptions.DELETE ? 'destructive' : 'primary'}
              data-test-id="submit-pending-changes-btn"
              onClick={() => trackFlagTargetingEvent('Review And Save Submit Button Clicked', { saveOption, flagKey })}
              disabled={
                hasRequiredFields
                  ? !pendingChangesFormState.canSubmitForm()
                  : !pendingChangesFormState.canSubmitOptionalForm()
              }
            >
              {footerCTA}
            </Button>
          </Tooltip>
        }
        secondaryButton={
          releaseStrategy || useBackButton ? (
            <Button onClick={handleBack} kind="default">
              Back
            </Button>
          ) : (
            <Button onClick={handleCancel} kind="default">
              Cancel
            </Button>
          )
        }
      />
    </Form>
  );
}

function DescriptionField({ value, onChange }: { value: string; onChange(newValue: string): void }) {
  const [inputValue, setInputValue] = useExternalState(value);

  return (
    <AutosizeTextArea
      maxRows={4}
      id="description"
      name="description"
      rows={1}
      onBlur={() => {
        onChange(inputValue);
      }}
      onChange={(event) => {
        const nextValue = event.target.value;
        setInputValue(nextValue);
        if (Boolean(inputValue) !== Boolean(nextValue)) {
          onChange(nextValue);
        }
      }}
      value={inputValue}
    />
  );
}

function CommentField({ value, onChange }: { value: string; onChange(newValue: string): void }) {
  const [inputValue, setInputValue] = useExternalState(value);

  return (
    <CommentArea
      value={inputValue}
      onBlur={() => {
        onChange(inputValue);
      }}
      onChange={(event) => {
        const nextValue = event.target.value;
        setInputValue(nextValue);
        if (Boolean(inputValue) !== Boolean(nextValue)) {
          onChange(nextValue);
        }
      }}
    />
  );
}
